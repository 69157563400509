import React, { useEffect } from "react";

import { businessLicenseSchema, trackPageViewMixpanel } from "@earlypay/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { floatingContentMotion } from "@styles/animations";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { ActionButton, PageContainer, Text } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";
import LicenseVerifyForm from "@components/templates/serviceApply/LicenseVerifyForm";

import useNavigateWithParams from "@hooks/useNavigateParams";

import { useUpdateStore } from "@apis/hooks";
import { useFetchBusinessLicense } from "@apis/hooks/document/queries";

import { applicationState } from "@/recoil/application/atom";

export const VerifyLicenseInfo: React.FC = () => {
  const { navigateWithParams } = useNavigateWithParams();
  const application = useRecoilValue(applicationState);
  const { data: businessLicense } = useFetchBusinessLicense(application.id);
  const mutation = useUpdateStore(application.store.id);
  const methods = useForm({
    resolver: yupResolver(businessLicenseSchema),
    defaultValues: {
      address: !!application.detailAddress ? application.address : "",
      detailAddress: application.detailAddress,
      postCode: application.store?.postCode,
    },
    mode: "onChange",
  });
  const address = methods.getValues("address");
  const detailAddress = methods.getValues("detailAddress");

  const onSubmit = (values: any) => {
    const data = {
      ...businessLicense,
      address: values.address,
      detailAddress: values.detailAddress,
      postCode: application.store?.postCode ?? values.postCode,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/service-agreement" });
    }
  }, [mutation.isSuccess]);

  trackPageViewMixpanel("사업자등록증 인증 | 번호 검증");

  return (
    <FormProvider {...methods}>
      <Meta title={`얼리페이 | 서비스 신청`} />
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Header
          previous={
            application.user.isCertified
              ? "/service-apply/license-verify/upload"
              : "/service-apply/auth"
          }
        ></Header>

        <PageContainer spacing={20}>
          <Text typo={"subtitle-1"} bold>
            {`${application.title}의\n현재 주소를 입력해주세요`}
          </Text>

          <LicenseVerifyForm data={businessLicense} progress={"VERIFY"} />
        </PageContainer>

        <ActionButton
          primaryButtonLabel={"완료"}
          disabledPrimaryButton={!address?.length || !detailAddress?.length}
          loading={mutation.isPending}
          primaryButtonProperty={{
            description: "사업자등록증 인증 버튼",
          }}
        />
      </Form>
    </FormProvider>
  );
};

export default VerifyLicenseInfo;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${floatingContentMotion}
`;
