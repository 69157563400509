import React, { useState } from "react";
// style
import {
  CalendarDatePickerBox,
  Background,
  MonthSelectButton,
  CalendarDatePickerGrid,
} from "./index.styled";
import theme from "@styles/theme";
// component
import { Span, FlexBox } from "@components/commons/index";
// utils
import { delayTime } from "@/utils/delayTime";
import { IconTags } from "@components/atoms/Icon/type";
import Icon from "@components/atoms/Icon";
import IconButton from "@components/atoms/IconButton";

interface CalendarDatePickerType {
  _year: number;
  _setModalState: (state: boolean) => void;
  _month: number;
  _pickMonthSales: (selectYear: number, selectMonth: number) => void;
  _todayDate: number;
}

const CalendarDatePicker = ({
  _year,
  _setModalState,
  _pickMonthSales,
  _todayDate,
}: CalendarDatePickerType) => {
  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [pickerYear, setPickerYear] = useState(_year);
  const [cssMotion, setCssMotion] = useState(true);

  // date picker를 닫습니다
  const closePicker = async () => {
    setCssMotion(false);
    await delayTime(130);
    _setModalState(false);
  };

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <CalendarDatePickerGrid>
      <CalendarDatePickerBox _cssMotion={cssMotion}>
        <ModalHeader _onClick={closePicker} />
        <YearSelctor _setPickerYear={setPickerYear} _pickerYear={pickerYear} />
        <FlexBox _row>
          {monthList.map((x, idx) => {
            const day = String(x).length === 1 ? `0${x}` : `${x}`;
            const currentDate = Number(`${pickerYear}${day}`);

            return (
              <MonthSelectButton
                key={idx}
                _select={_todayDate === currentDate}
                _disable={_todayDate < currentDate}
                _onClick={async () => {
                  if (_todayDate < currentDate) return;
                  await closePicker();
                  _pickMonthSales(pickerYear, x);
                }}
              >
                {x}월
              </MonthSelectButton>
            );
          })}
        </FlexBox>
      </CalendarDatePickerBox>
      <Background _cssMotion={cssMotion} onClick={() => closePicker()} />
    </CalendarDatePickerGrid>
  );
};
export default CalendarDatePicker;

// ---------------------------------- 추가 컴포넌트 ---------------------------------
interface ModlaType {
  _onClick?: () => void;
  _setPickerYear?: (_year: number) => void;
  _pickerYear?: number;
}

const ModalHeader = ({ _onClick }: ModlaType) => {
  return (
    <FlexBox
      _row
      _style={{
        justifyContent: "space-between",
        background: theme.color.blue_10,
        padding: "12px 14px",
      }}
    >
      <FlexBox _style={{ fontSize: "14px", color: "#fff", width: "auto" }}>
        날짜
      </FlexBox>
      <FlexBox
        _onClick={() => _onClick()}
        _style={{ fontSize: "14px", color: "#fff", width: "auto" }}
      >
        닫기
      </FlexBox>
    </FlexBox>
  );
};

const YearSelctor = ({ _setPickerYear, _pickerYear }: ModlaType) => {
  return (
    <FlexBox _justify _align _row _style={{ gap: "5px", padding: "10px 0" }}>
      <IconButton
        icon={IconTags.CHEVRON_LEFT}
        size={"XS"}
        onClick={() => _setPickerYear(_pickerYear - 1)}
      />
      <FlexBox _style={{ fontSize: "16px", width: "auto" }}>
        {_pickerYear}년
      </FlexBox>
      <IconButton
        icon={IconTags.CHEVRON_RIGHT}
        size={"XS"}
        onClick={() => _setPickerYear(_pickerYear + 1)}
      />
    </FlexBox>
  );
};
