import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  GetAdditionalDepositParams,
  GetEarlypaysEtcDetailParams,
  GetEarlypaysEtcParams,
  GetEarlypaysParams,
  GetEarlypaysSummaryParams,
  GetTotalSalesParams,
  GetUnderDepositsParams,
} from "@apis/endpoints/earlypay/types";
import { EarlypaysEtcCodeTypes } from "@earlypay/shared";

export const earlypayQueryKeys = createQueryKeys("earlypay", {
  fetchAdditionalDeposit: (params: GetAdditionalDepositParams) => [
    "fetchAdditionalDeposit",
    params,
  ],
  fetchEarlypays: (params: GetEarlypaysParams) => ["fetchEarlypays", params],
  fetchEarlypaysSummary: (params: GetEarlypaysSummaryParams) => [
    "fetchEarlypaysSummary",
    params,
  ],
  fetchEarlypaysEtc: (params: GetEarlypaysEtcParams) => [
    "fetchEarlypaysEtc",
    params,
  ],
  fetchEarlypaysEtcDetail: (
    params: GetEarlypaysEtcDetailParams,
    type: EarlypaysEtcCodeTypes,
  ) => ["fetchEarlypaysEtcDetail", params, type],
  fetchTotalSales: (params: GetTotalSalesParams) => ["fetchTotalSales", params],
  fetchUnderDepositsSummary: (params: GetUnderDepositsParams) => [
    "fetchUnderDepositsSummary",
    params,
  ],
  fetchUnderDeposits: (params: GetUnderDepositsParams) => [
    "fetchUnderDeposits",
    params,
  ],
});
