/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import {
  GetAdditionalDepositParams,
  GetEarlypaysEtcDetailParams,
  GetEarlypaysEtcParams,
  GetEarlypaysParams,
  GetTotalSalesParams,
  GetUnderDepositsParams,
} from "@apis/endpoints/earlypay/types";
import {
  getAdditionalDeposit,
  getEarlypaysSummary,
  getEarlypays,
  getEarlypaysEtc,
  getEarlypaysEtcDetail,
  getTotalSales,
  getUnderDeposits,
  getUnderDepositsSummary,
} from "@apis/endpoints/earlypay";
import {
  AdditionalDepositAmountDetailTypes,
  AdditionalDepositCode,
  AdditionalDepositItemsTypes,
  AdditionalDepositPlatformTypes,
  TotalSalesTypes,
  UnderDepositsSummaryItemsTypes,
  DepositorCode,
  EarlypaysEtcCodeTypes,
  DepositorNamesTypes,
} from "@earlypay/shared/typings";
import { earlypayQueryKeys } from "./queryKeys";

export const useFetchAdditionalDeposit = (
  params: GetAdditionalDepositParams,
) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchAdditionalDeposit(params).queryKey,
    queryFn: () => getAdditionalDeposit(params),
    retry: 0,
  });
};

export const useFetchAdditionalDepositDetail = (
  params: GetAdditionalDepositParams,
  target: string,
) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchAdditionalDeposit(params).queryKey,
    queryFn: () => getAdditionalDeposit(params),
    select: data => {
      const filteredData = data.items.find(
        (items: AdditionalDepositAmountDetailTypes) => items.date === target,
      );
      if (!filteredData)
        return { earlypayList: [], depositList: [], totalAmounts: 0 };

      const earlypayList =
        filteredData.items
          .find(
            (transaction: AdditionalDepositItemsTypes) =>
              transaction.type === AdditionalDepositCode.ADDITIONAL_EARLYPAY,
          )
          ?.items.filter(
            (item: AdditionalDepositPlatformTypes) => item.amount !== 0,
          ) || [];

      const depositList =
        filteredData.items
          .find(
            (transaction: AdditionalDepositItemsTypes) =>
              transaction.type === AdditionalDepositCode.ETC,
          )
          ?.items.filter(
            (item: AdditionalDepositPlatformTypes) => item.amount !== 0,
          ) || [];

      const totalEarlypay =
        filteredData.items.find(
          (transaction: AdditionalDepositItemsTypes) =>
            transaction.type === AdditionalDepositCode.ADDITIONAL_EARLYPAY,
        )?.totalAmount || 0;

      const totalDeposit =
        filteredData.items.find(
          (transaction: AdditionalDepositItemsTypes) =>
            transaction.type === AdditionalDepositCode.ETC,
        )?.totalAmount || 0;

      return { earlypayList, depositList, totalDeposit, totalEarlypay };
    },
    retry: 0,
  });
};

export const useFetchEarlypays = (params: GetEarlypaysParams) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchEarlypays(params).queryKey,
    queryFn: () => getEarlypays(params),
    select: data => {
      return data[0];
    },
    retry: 0,
  });
};

export const useFetchEarlypaysSummary = (params: GetEarlypaysParams) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchEarlypaysSummary(params).queryKey,
    queryFn: () => getEarlypaysSummary(params),
    retry: 0,
  });
};

export const useFetchEarlypaysEtc = (params: GetEarlypaysEtcParams) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchEarlypaysEtc(params).queryKey,
    queryFn: () => getEarlypaysEtc(params),
    retry: 0,
  });
};

export const useFetchEarlypaysEtcDetail = (
  params: GetEarlypaysEtcDetailParams,
  type: EarlypaysEtcCodeTypes,
) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchEarlypaysEtcDetail(params, type).queryKey,
    queryFn: () => getEarlypaysEtcDetail(params, type),
    retry: 0,
  });
};

export const useFetchTotalSales = (
  params: GetTotalSalesParams,
  depositor: DepositorCode,
) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchTotalSales(params).queryKey,
    queryFn: () => getTotalSales(params),
    select: data => {
      return data.filter(
        (item: TotalSalesTypes) => item.depositor === depositor,
      )[0];
    },
    retry: 0,
  });
};

export const useFetchCardSales = (params: GetTotalSalesParams) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchTotalSales(params).queryKey,
    queryFn: () => getTotalSales(params),
    select: data => {
      return data.filter(
        (item: TotalSalesTypes) => item.depositor === "CARD",
      )[0];
    },
    retry: 0,
  });
};

export const useFetchUnderDeposits = (params: GetUnderDepositsParams) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchUnderDeposits(params).queryKey,
    queryFn: () => getUnderDeposits(params),
    select: data => {
      return data[0];
    },
    retry: 0,
  });
};

export const useFetchUnderDepositsSummary = (
  params: GetUnderDepositsParams,
) => {
  return useQuery({
    queryKey: earlypayQueryKeys.fetchUnderDepositsSummary(params).queryKey,
    queryFn: () => getUnderDepositsSummary(params),
    select: data => {
      /** 차감금 정보를 가져와서 보여주기 좋은 형태로 데이터를 포맷을 변경합니다. */
      return data.reduce(
        (acc: any, current: UnderDepositsSummaryItemsTypes) => {
          const depositorKey = current.depositor;

          if (current.items?.length) {
            const items = current.items.map(item => ({
              type: item.detail ? item.detail.name : "기타 미상환금",
              amount: item.amount,
            }));

            acc[depositorKey] = items;
          } else {
            acc[depositorKey] = [];
          }

          return acc;
        },
        {} as Record<DepositorNamesTypes, { type: string; amount: number }[]>,
      );
    },
    placeholderData: [
      {
        CARD: {
          type: "",
          amount: "",
        },
      },
    ],
    retry: 0,
  });
};
