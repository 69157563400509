import React from "react";

import { Helmet } from "react-helmet";

interface MetaPropsType {
  title?: string;
}

export const Meta = ({ title }: MetaPropsType) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Meta;
