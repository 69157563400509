import { atom } from "recoil";
import {
  ImageUploadMethodCode,
  ServiceApplyCode,
} from "@earlypay/shared/typings";
import { v1 } from "uuid";

export const imageUploadModalState = atom({
  key: `ImageUploadModalState/${v1()}`,
  default: {
    isOpenImageUploadForm: false,
    isOpenImageMethodSelector: false,
    method: ImageUploadMethodCode.GALLERY,
  },
});

export const serviceApplyProgressState = atom({
  key: `ServiceApplyProgressState/${v1()}`,
  default: ServiceApplyCode.INTRO,
});
