import React from "react";
import { ContentStack, ReviewWrapper } from "./index.styled";
import Text from "@components/atoms/Text";
import ReactPlayer from "react-player";
import { ReviewCardCarousel } from "../ReviewCardCarousel";
import { EARLYPAY_REVIEW_YOUTUBE } from "@/commons/constants";
import { ColorsTypes, FontTypes, FontWeightTypes } from "@/@types/ThemesType";
import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

const ReviewSection = () => {
  return (
    <ReviewWrapper>
      <Stack center>
        <Text
          className="earlypay-landing-title"
          as="h2"
          center
          tag={FontTypes.TITLE_1}
          weight={FontWeightTypes.SEMI_BOLD}
        >
          이미 많은 사장님들께서
        </Text>
        <Stack direction={StackDirectionsTypes.ROW}>
          <Text
            className="earlypay-landing-title"
            tag={FontTypes.TITLE_1}
            weight={FontWeightTypes.SEMI_BOLD}
            color={ColorsTypes.CONTENT_HIGHLIGHT}
            as="h2"
          >
            얼리페이
          </Text>
          <Text
            className="earlypay-landing-title"
            as="h2"
            center
            tag={FontTypes.TITLE_1}
            weight={FontWeightTypes.SEMI_BOLD}
          >
            와 함께하고 있어요
          </Text>
        </Stack>
      </Stack>
      <Text
        className="earlypay-landing-subtitle"
        center
        tag={FontTypes.BODY_2}
        color={ColorsTypes.CONTENT_SECONDARY}
        as="h4"
      >
        2022 서비스 만족도 조사 결과 평점 4.5점
        <br />
        얼리페이와 함께하는 사장님의 생생한 후기
      </Text>
      <ContentStack>
        <ReviewCardCarousel />
        <ReactPlayer
          className="earlypay-landing-youtube"
          width={560}
          height={315}
          url={EARLYPAY_REVIEW_YOUTUBE}
        />
      </ContentStack>
    </ReviewWrapper>
  );
};

export default ReviewSection;
