import React from "react";
import BillingInfoMessage from "@components/templates/settlement/BillingInfoMessage";
import { BillingInfoMessageType } from "../../BillingInfoMessage/types";
import { EtcAmountList } from "../../SettlementAmountList";
import BottomSheet from "@components/molecules/BottomSheet";
import useSettlement from "@hooks/useSettlement";
import { FinancialInterestAmountTypes } from "@earlypay/shared/typings";

export interface EtcFinancialInterestBottomSheetProps {
  data: FinancialInterestAmountTypes[];
  isLoading: boolean;
}

/** 그 외 선정산 이자 항목을 담은 바텀시트를 보여줍니다. */
export const EtcFinancialInterestBottomSheet: React.FC<
  EtcFinancialInterestBottomSheetProps
> = ({ data, isLoading }) => {
  const { handleCloseBillingBottomSheet } = useSettlement();

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={"선정산 이자"}
      primaryButtonText={"확인"}
    >
      <BillingInfoMessage type={BillingInfoMessageType.FINANCIAL_FEE} />
      <EtcAmountList list={data} isLoading={isLoading} />
    </BottomSheet>
  );
};

export default EtcFinancialInterestBottomSheet;
