import React from "react";
import {
  CarouselItem,
  CarouselTrack,
  ReviewCardCarouselWrapper,
} from "./index.styles";
import { messageDummyData } from "./messageDummyData.mock";
import Text from "@components/atoms/Text";
import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

export const ReviewCardCarousel = () => {
  return (
    <ReviewCardCarouselWrapper>
      <CarouselTrack>
        {[
          ...Object.keys(messageDummyData),
          ...Object.keys(messageDummyData),
        ].map((detailKey, index) => {
          const name = messageDummyData[detailKey].name;
          const region = messageDummyData[detailKey].region;

          return (
            <CarouselItem key={index}>
              <Text tag="BODY_2">{messageDummyData[detailKey].content}</Text>
              <Stack direction={StackDirectionsTypes.ROW}>
                <Text tag="BODY_2" weight="SEMI_BOLD">
                  {`- ${region}` + " "}
                </Text>
                <Text tag="BODY_2" weight="SEMI_BOLD" color="CONTENT_HIGHLIGHT">
                  {name}
                </Text>
                <Text tag="BODY_2" weight="SEMI_BOLD">
                  {" " + `사장님 -`}
                </Text>
              </Stack>
            </CarouselItem>
          );
        })}
      </CarouselTrack>
    </ReviewCardCarouselWrapper>
  );
};
