import React from "react";

import {
  TERMS_PRIVACY_POLICY,
  modalCode,
  openChannelIOChat,
} from "@earlypay/shared";

import {
  AccordionMenu,
  PageHeader,
  PageModal,
  VStack,
  useConfirmModal,
} from "@earlybird/ui";

import { useLogin } from "@hooks/useLogin";

interface SettingModalProps {
  visible: boolean;
  onClose: () => void;
}

export const SettingModal = ({ onClose, visible }: SettingModalProps) => {
  const { handleLogout } = useLogin();
  const { openConfirmModal } = useConfirmModal();

  const handleClickLogout = () => {
    openConfirmModal({
      id: modalCode.ERROR_LOGOUT,
      onClickPrimaryButton: handleLogout,
    });
  };

  return (
    <PageModal visible={visible}>
      <PageHeader
        handleClickLeftButton={onClose}
        hasRightButton={false}
        hasChat={false}
      >
        설정
      </PageHeader>
      <VStack>
        <AccordionMenu title={"고객센터"} onClick={openChannelIOChat} />
        <AccordionMenu title={"로그아웃"} onClick={handleClickLogout} />
      </VStack>
      <VStack>
        <AccordionMenu title={"서비스 이용약관"} link={"/terms/EP02"} />
        <AccordionMenu
          bold
          underline
          title={"개인정보처리방침"}
          link={TERMS_PRIVACY_POLICY}
        />
      </VStack>
    </PageModal>
  );
};

export default SettingModal;
