// style
import styled from "styled-components";
// component
import { Span } from "@components/commons/index";

type CalendarStyle = { [key: string]: string };

export const MonthCell = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  position: relative;
  margin: 0.5px;
  padding: 0.5px 1px;
  max-height: 100%;
  overflow: hidden;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  opacity: ${(props: CalendarStyle) => props._disable && "0.4"};
  opacity: ${(props: CalendarStyle) => props._noneDate && "0.1"};
  font-size: 12px;
  color: ${(props: CalendarStyle) => props._color};
  border: ${(props: CalendarStyle) => props._isToday && "1px solid red"};
`;

export const Sale = styled(Span)`
  margin-top: 4px;
  display: block;
  font-size: 9px;
  font-weight: 400;
  text-align: right;
  padding: 2px;
  color: ${(props: CalendarStyle) => (props._pricr ? "#f54a5b" : "#fff")};
  background-color: ${(props: CalendarStyle) =>
    props._pricr ? "#ffeef0" : "#fff"};
`;

export const Deposit = styled(Span)`
  display: block;
  font-size: 9px;
  font-weight: 400;
  padding: 2px;
  text-align: right;
  color: ${(props: CalendarStyle) => (props._pricr ? "#3556ff" : "#fff")};
  background-color: ${(props: CalendarStyle) =>
    props._pricr ? "#e9edff" : "#fff"};
`;

export const CalendarSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  min-height: 100%;
`;

export const SpinerPosition = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  width: auto;
  margin-left: -45px;
`;

export const Calendar = styled.div`
  min-height: 300px;
  margin-bottom: 120px;
`;
