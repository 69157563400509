// style
import styled from "styled-components";

// component
import { FlexBox, Button } from "@components/commons/index";
// import theme from '@designs/theme';

interface StylesType {
  _modalAction: boolean;
}

export const ModalBox = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 40px;
  display: flex;
  box-sizing: border-box;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ConfirmModalInnerGrid = styled(FlexBox)`
  background: #fff;
  height: auto;
  border-radius: 10px;
  max-width: 500px;
  max-height: 220px;
  overflow: hidden;
  width: 100%;
  animation: ${(props: StylesType) =>
    props._modalAction ? "upPicker 200ms" : "downPicker 200ms"};
  @keyframes upPicker {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes downPicker {
    0% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(20%);
    }
  }
`;

export const ConfirmButton = styled(Button)`
  border-radius: 0;
  width: ${(props: { [key: string]: string }) =>
    props._width ? "50%" : "100%"};
  &:first-of-type {
    background: ${(props: { [key: string]: string }) =>
      props._width ? "#aaa" : "#5165CC"};
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  animation-duration: 0.3s;
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  animation: ${(props: StylesType) =>
    props._modalAction ? "hideBackground 250ms" : "showBackground 250ms"};
  @keyframes hideBackground {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes showBackground {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
