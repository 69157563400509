import { ForwardedRef, forwardRef, useState } from "react";
import classNames from "classnames";
import styles from "./BankSelector.module.scss";
import { BankSelectorProps } from "./BankSelector.types";
import "@ui/styles/index.scss";
import { Icon, BottomSheet, Text, HStack, Image } from "@ui/components/atoms";
import { ChevronDownIcon } from "@earlybird/icons";
import { FieldError, useFormContext } from "react-hook-form";
import { BankCode, BankCodeTypes, BankNames } from "@earlypay/shared/typings";
import { getBankImgName } from "@ui/components/organisms/BankSelector/utils";
import { noop } from "@ui/utils/types";

/**
 * `BankSelector` 는 은행을 선택하는 데에 사용하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <BankSelector
 *   name={"depositBank"}
 *   title={"은행 선택"}
 *   onChange={onChange}
 * />
 * ```
 */
export const BankSelector = forwardRef<HTMLElement, BankSelectorProps>(
  function BankSelector(
    {
      className,
      as,
      title = "은행 선택",
      name,
      value,
      onChange,
      errorMessage,
      ...rest
    }: BankSelectorProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BankCard = "button";
    const BaseComponent = as ?? "div";
    const banks = Object.values(BankCode);

    const [visible, setVisible] = useState(false);

    const handleClickBankCard = (code: BankCodeTypes) => {
      onChange(code);
      setVisible(false);
    };

    return (
      <>
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.BankSelector,
            errorMessage && styles[`error`],
            "earlybird-bank-selector",
            className,
          )}
          onClick={() => setVisible(true)}
        >
          {value ? (
            <Text>{BankNames[value]}</Text>
          ) : (
            <Text color={"content-tertiary"}>은행 선택</Text>
          )}
          <Icon icon={ChevronDownIcon} size={"sm"} />
        </BaseComponent>
        <BottomSheet
          headerType={"headline"}
          title={title}
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <HStack wrap spacing={4}>
            {banks.map((code, index) => {
              const isSelectedItem = code === value;

              return (
                <BankCard
                  className={classNames(
                    styles.BankCard,
                    isSelectedItem && styles["selected"],
                    "earlybird-bank-card",
                  )}
                  type={"button"}
                  key={index}
                  onClick={() => handleClickBankCard(code)}
                >
                  <Image src={getBankImgName(code)} width={24} height={24} />
                  <Text>{BankNames[code]}</Text>
                </BankCard>
              );
            })}
          </HStack>
        </BottomSheet>
      </>
    );
  },
);

export default BankSelector;
