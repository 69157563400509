import styled from "styled-components";

import { FlexBox } from "@components/commons/index";

const FullImg = styled.img`
  width: 100% !important;
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
`;

const SliderBox = styled(FlexBox)`
  margin-top: 24px;

  .slick-list {
    border-radius: 11px;
  }
`;

export { FullImg, SliderBox };
