import React from "react";
import styled from "styled-components";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { ImageTypes } from "@components/atoms/Image/type";
import Text from "@components/atoms/Text";
import Image from "@components/atoms/Image";

interface EmotionProps {
  isSelected: boolean;
  onClick: () => void;
  imageTag: ImageTypes;
  imageAlt: string;
  textColor: ColorsTypes;
  text: string;
}

const EmotionButton: React.FC<EmotionProps> = ({
  isSelected,
  onClick,
  imageTag,
  imageAlt,
  textColor,
  text,
}) => (
  <Emotion isSelected={isSelected} onClick={onClick}>
    <Image tag={imageTag} width={40} height={40} alt={imageAlt} />
    <Text tag={FontTypes.BODY_3} color={textColor} center>
      {text}
    </Text>
  </Emotion>
);

export default EmotionButton;

const Emotion = styled.div<EmotionProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  border: ${(props: EmotionProps) =>
    props.isSelected ? "2px solid" : "1px solid #C6C6C6"};
  padding: 12px 20px 8px;
  box-shadow: ${(props: EmotionProps) =>
    props.isSelected ? "0px 2px 8px 0px #0000003d" : "none"};
  cursor: pointer;
`;
