import React, { useState } from "react";

import { screenModalMotion } from "@styles/animations";
import styled from "styled-components";

import { DiagonalArrowsIcon, XIcon } from "@earlybird/icons";
import { Icon, IconButton } from "@earlybird/ui";

interface ImageFullScreenModalProps {
  imageUrl: string;
}

const ImageFullScreenModal: React.FC<ImageFullScreenModalProps> = ({
  imageUrl,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseImageFullScreenModal = () => {
    setIsOpen(false);
  };

  const handleOpenImageFullScreenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ImageFullScreenButton
        type={"button"}
        onClick={handleOpenImageFullScreenModal}
      >
        <Icon icon={DiagonalArrowsIcon} color={"content-on-color"} />
      </ImageFullScreenButton>

      <Wrapper cssMotion={isOpen}>
        <CloseButtonWrapper>
          <IconButton
            icon={XIcon}
            color={"content-on-color"}
            onClick={handleCloseImageFullScreenModal}
          />
        </CloseButtonWrapper>
        <ImageWrapper cssMotion={isOpen}>
          <CustomImage
            src={imageUrl}
            width={500}
            height={"100%"}
            cssMotion={isOpen}
          />
        </ImageWrapper>
      </Wrapper>
    </>
  );
};

export default ImageFullScreenModal;

const Wrapper = styled.div`
  width: 100%;
  max-width: 498px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #010101;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;

  ${screenModalMotion}
`;

const ImageFullScreenButton = styled.button`
  border-radius: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 1;
  right: 12px;
  bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 6px;
  z-index: 200;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomImage = styled.img``;
