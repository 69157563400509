import React from "react";
import styled from "styled-components";
import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import IconButton from "@components/atoms/IconButton";
import Text from "@components/atoms/Text";
import { FontTypes } from "@/@types/ThemesType";
import { useFetchApplication } from "@apis/hooks";
import { openChannelIOChat } from "@earlypay/shared/configs";

export const SettlementHeader: React.FC = () => {
  const { data: application, isLoading } = useFetchApplication();
  const title = application.store?.title ?? "";

  return (
    <Wrapper>
      <TitleWrapper>
        <IconWrapper>
          <Icon tag={IconTags.EARLYPAY_SYMBOL} />
        </IconWrapper>
        {isLoading && (
          <Icon tag={IconTags.EARLYPAY_WORDMARK} height={18} width={100} />
        )}
        <Title tag={FontTypes.SUBTITLE_2} bold>
          {title}
        </Title>
      </TitleWrapper>
      <IconButton
        icon={IconTags.HEADPHONES}
        onClick={openChannelIOChat}
        size={IconSizes.SM}
      />
    </Wrapper>
  );
};

export default SettlementHeader;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 498px;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  padding: 6px 20px;
`;

const TitleWrapper = styled.div`
  width: calc(100% - 36px);
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  width: calc(100% - 36px);
`;
