import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "@utils/cookie";
import { MainLayout } from "@routes/layouts/MainLayout";
import { useFetchApplication } from "@apis/hooks/applications/queries";
import { useLogin } from "@hooks/useLogin";
import ProtectedMainRoute from "@routes/utils/ProtectedMainRoute";
import { ApplicationStatusCode } from "@earlypay/shared";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";

const MainRouteGuard: React.FC = () => {
  const location = useLocation();
  const currentQuery = new URLSearchParams(location.search);
  const { getLoginUrlWithNextUrl } = useLogin();
  const accessToken = getCookie("earlypay-token");
  const { data: application } = useFetchApplication();

  /** accessToken 이 없는 경우, 로그인 또는 랜딩 페이지로 이동합니다. */
  if (!accessToken) {
    if (location.pathname === "/") {
      return (
        <Navigate
          to={`/landing?${currentQuery.toString()}`}
          replace
          state={{ from: location }}
        />
      );
    }

    return (
      <Navigate
        to={getLoginUrlWithNextUrl()}
        replace
        state={{ from: location }}
      />
    );
  }

  /** accessToken 이 존재하지만 신청서 작성이 끝나지 않은 경우, 신청서 작성 페이지로 이동합니다. */
  if (
    application.status !== ApplicationStatusCode.APPROVED &&
    location.pathname === "/"
  ) {
    return (
      <Navigate
        to={`/service-apply?${currentQuery.toString()}`}
        replace
        state={{ from: location }}
      />
    );
  }

  if (!application.id) {
    return null;
  }

  return (
    <InitializeServiceRoute>
      <ProtectedMainRoute>
        <MainLayout />
      </ProtectedMainRoute>
    </InitializeServiceRoute>
  );
};

export default MainRouteGuard;
