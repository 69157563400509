/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import theme from "@styles/theme";
import { useRecoilValue } from "recoil";

import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";
import {
  Accordion,
  FlexBox,
  Meta,
  Span,
  Text,
} from "@components/commons/index";
import { Header, Spinner } from "@components/modules/common/index";

import { useFetchCheckedEarlypaid } from "@apis/hooks";
import { useFetchRecommendationInfo } from "@apis/hooks/user/queries";

import { writeClipboardText } from "@utils/clipboard";

import { IMG_URL } from "@/@types/commonType";
import { errorToast, successToast } from "@/components/atoms/Toast";
import { applicationState } from "@/recoil/application/atom";
import { checkCouponState } from "@/utils/checkCouponState";
import { sendKakaoLink } from "@/utils/sendKakaoLink";

import {
  ButtuonGridBox,
  ImageGrid,
  KakaoButton,
  LinkButton,
  TitleText,
} from "./index.styled";

// TODO: 컴포넌트 분리 필요
const InviteRecent = () => {
  // users info
  const application = useRecoilValue(applicationState);
  const { data: earlypaid } = useFetchCheckedEarlypaid(application.store.id);
  const { data: recommendation } = useFetchRecommendationInfo(
    application.user.id,
  );
  const userName = application.user.name;
  const recommenderCode = application.user.recommenderCode;

  // invite info
  const totalFreeAmount = (
    recommendation?.promotion_info?.total_coupon * 66000
  ).toLocaleString();
  const totalFriend = recommendation?.recommenders?.length;
  const totalCoupon = recommendation?.promotion_info?.promotion_terms?.length;

  // state
  const [isLoading, setIsLoading] = useState(false);

  // copy link
  const copyLink = (recommenderCode: string, earlypaid: boolean) => {
    if (!earlypaid) return errorToast("최초 선정산 이후 가능한 서비스입니다");

    writeClipboardText(
      `https://earlypay.kr/landing?utm_source=invite&utm_medium=${recommenderCode}`,
    ).then(() => {
      successToast("지금 친구초대링크를 보내 추천해보세요!");
    });
  };

  trackPageViewMixpanel("친구 추천 현황");

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} />
      <Header _white _backHistory="/my">
        친구 추천 현황
      </Header>
      <MainOldLayout _padding>
        <ImageGrid>
          <img
            src={IMG_URL.etc.invite}
            alt="친구초대 이미지"
            width="100%"
            decoding="async"
          />
        </ImageGrid>
        <Text _style={{ marginTop: "16px", fontSize: "15px" }} _center>
          {userName} 사장님은
        </Text>
        <Text _style={{ fontSize: "24px" }} _bold _center>
          총{" "}
          <span style={{ color: theme.color.blue_00 }}>
            {totalFreeAmount ?? 0}
          </span>{" "}
          원의
        </Text>
        <Text _style={{ marginBottom: "16px", fontSize: "15px" }} _center>
          무료혜택을 받으셨습니다
        </Text>
        <div style={{ paddingBottom: "20px" }}>
          <Accordion _custom={true}>
            <AccordionTitle _type="friend" _count={totalFriend}>
              총 가입 친구
            </AccordionTitle>
            {totalFriend !== 0 ? (
              <FriendBody recommendation={recommendation} />
            ) : (
              <></>
            )}
          </Accordion>
          <Accordion _custom={true}>
            <AccordionTitle _type="coupon" _count={totalCoupon}>
              총 무료 쿠폰
            </AccordionTitle>
            <CouponBody recommendation={recommendation} />
          </Accordion>
          <ButtuonGridBox>
            <LinkButton _onClick={() => copyLink(recommenderCode, earlypaid)}>
              링크복사
            </LinkButton>
            <KakaoButton
              _full
              _disabled={isLoading}
              _onClick={() =>
                sendKakaoLink(
                  earlypaid,
                  userName,
                  recommenderCode,
                  setIsLoading,
                )
              }
            >
              <Span>
                <img
                  src={IMG_URL.icon.kakao}
                  alt="카카오 버튼 아이콘"
                  style={{ marginRight: "6px", width: "16px" }}
                />
              </Span>
              카카오톡 공유
            </KakaoButton>
          </ButtuonGridBox>
        </div>
      </MainOldLayout>
      {isLoading && <Spinner />}
    </>
  );
};

export default InviteRecent;

// ---------------------------------- 추가 컴포넌트 ---------------------------------

type TitleType = {
  children: string;
  _type: string;
  _count: number;
};

// 아코디언 title
const AccordionTitle = ({ children, _type, _count }: TitleType) => {
  const [arrow, setArrow] = useState(false);

  return (
    <TitleText onClick={() => setArrow(!arrow)}>
      <h4>{children}</h4>
      <div style={{ display: "flex", alignItems: "center" }}>
        {_count} {_type === "friend" ? "명" : "개"}
        {_count !== 0 && (
          <Icon tag={!arrow ? IconTags.CHEVRON_UP : IconTags.CHEVRON_DOWN} />
        )}
      </div>
    </TitleText>
  );
};

// 가입친구 body
const FriendBody = ({ recommendation }: { recommendation: any }) => {
  const totalRecommenders: any = recommendation?.recommenders ?? [];

  return (
    <FlexBox _style={{ padding: "0px 18px 22px 18px" }}>
      {totalRecommenders?.map((x: any, idx: any) => (
        <FlexBox
          _row
          key={idx}
          _style={{
            justifyContent: "space-between",
            marginTop: idx !== 0 && "10px",
          }}
        >
          <Text _style={{ fontSize: "15px" }}>{x.name}</Text>
          <Text _style={{ fontSize: "15px" }}>{x.title}</Text>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

// 무료쿠폰 body
const CouponBody = ({ recommendation }: { recommendation: any }) => {
  // users info
  const totalUseCoupon: any =
    recommendation?.promotion_info?.promotion_terms ?? [];

  return (
    <>
      {totalUseCoupon?.map((date: any, idx: any) => {
        const couponState = checkCouponState(
          date.split(" ")[0].replace(/-/g, "/"),
          date.split(" ")[2].replace(/-/g, "/"),
        );
        return (
          <FlexBox
            _row
            key={idx}
            _style={{
              justifyContent: "space-between",
              padding: "18px 22px",
              backgroundColor: couponState.bgColor,
            }}
          >
            <Text
              _style={{
                width: "auto",
                backgroundColor: couponState.color,
                color: couponState.textColor,
                fontSize: "12px",
                borderRadius: "30px",
                padding: "4px 8px",
              }}
            >
              {couponState.text}
            </Text>
            <FlexBox
              _style={{
                width: "auto",
                fontSize: "14px",
                textDecoration: couponState.line,
              }}
            >
              {date}
            </FlexBox>
          </FlexBox>
        );
      })}
    </>
  );
};
