import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import {
  bootChannelIO,
  initKakao,
  initMixpanel,
  initSentry,
  logWelcomeMessage,
} from "@earlypay/shared";

const PublicRouteGuard: React.FC = () => {
  useEffect(() => {
    // initialize service
    initKakao();
    initMixpanel();
    initSentry();
    bootChannelIO();

    logWelcomeMessage();
  }, []);

  return <Outlet />;
};

export default PublicRouteGuard;
