import { useEffect, useState } from "react";
// apis
import axios from "axios";

export const useHoliday = (_currentMonth: Date) => {
  const [holiday, setHoliday] = useState([{ date: "", name: "" }]);

  useEffect(() => {
    (async function getHoliday() {
      const { data } = await axios({
        url: "https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo",
        method: "get",
        params: {
          Servicekey:
            "oHfYa7Qq0KmWjccNnNH/DGa57lYYxQBcvoliZEjUk54B0Z6PAk39qJ8mUdP+ZnGF1NRewaR3xQD4KtsLXZL7JA==",
          solYear: _currentMonth.getFullYear(),
          numOfRows: 100,
        },
      });
      const holiday = data?.response?.body?.items.item ?? [];
      const changeDay = holiday.map(
        (x: {
          dateKind: string;
          dateName: string;
          isHoliday: string;
          seq: number;
          locdate: number;
        }) => {
          const day = String(x.locdate).replace(
            String(_currentMonth.getFullYear()),
            "",
          );
          return {
            date: `${Number(day.slice(0, 2))}-${Number(day.slice(2, 4))}`,
            name: x?.dateName,
          };
        },
      );
      setHoliday([...changeDay]);
    })();
  }, [_currentMonth.getFullYear()]);

  return { holiday };
};
