import React from "react";

// style
import styled from "styled-components";
import theme from "@styles/theme";

// component
import { Text, FlexBox } from "@components/commons/index";
import { IMG_URL } from "@/@types/commonType";

interface PwaBannerType {
  _onClick: (e: React.FormEvent<HTMLInputElement>) => void;
  _onClose: (cookieName: string, value: string, days: number) => void;
  _setPwa: (state: boolean) => void;
}

const PwaBanner = ({ _onClick, _onClose, _setPwa }: PwaBannerType) => {
  return (
    <PwaBox>
      <PwaBannerCard>
        <FlexBox _row>
          <img
            src={IMG_URL.icon.PWA}
            alt="PWA 버튼 아이콘"
            style={{ width: "50px", height: "100%", marginRight: "10px" }}
          />
          <div>
            <Text _medium>EarlyPay</Text>
            <Text
              _style={{ fontSize: "13px", marginTop: "4px", color: "#333" }}
            >
              APP 설치하고, 더 쉽게 이용하세요!
            </Text>
          </div>
        </FlexBox>
        <FlexBox _style={{ width: "100%", marginTop: "26px" }} _row>
          <AddButton onClick={_onClick}>바로가기 설치하기</AddButton>
        </FlexBox>
        <CloseButton
          onClick={() => {
            _onClose("PWA", "true", 1);
            _setPwa(false);
          }}
        >
          X
        </CloseButton>
      </PwaBannerCard>
    </PwaBox>
  );
};

export default PwaBanner;

const PwaBox = styled.div`
  position: fixed;
  top: -200px;
  width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-name: PwaDown;
  animation-fill-mode: forwards;

  @keyframes PwaDown {
    from {
      top: -200px;
    }
    to {
      top: 0;
    }
  }
`;

const PwaBannerCard = styled.div`
  box-shadow: 0px 2px 3px 0.01px rgba(174, 174, 174, 0.5);
  background: #fff;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px 20px;
  position: relative;
`;

const AddButton = styled.button`
  background: ${theme.color.red_00};
  color: #fff;
  padding: 10px 14px;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
  /* margin-left: auto; */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  font-weight: bold;
`;
