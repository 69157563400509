// style
import styled from "styled-components";
import theme from "@styles/theme";
import { fadeBackgroundMotion, scaleInnerBoxMotion } from "@styles/animation";
// component(base)
import { FlexBox } from "@components/commons/index";

export const CalendarDatePickerGrid = styled(FlexBox)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #fff;
`;

export const CalendarDatePickerBox = styled.div`
  width: calc(100% - 40px);
  max-width: 500px;
  position: fixed;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: -110px;
  z-index: 9999;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  ${scaleInnerBoxMotion};
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;

  ${fadeBackgroundMotion}
`;

export const MonthSelectButton = styled(FlexBox)`
  width: 25%;
  text-align: center;
  padding: 14px 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  background: ${(props: { [key: string]: string }) =>
    props._select ? theme.color.blue_10 : "#fff"};
  color: ${(props: { [key: string]: string }) =>
    props._select ? "#fff" : "#111"};
  opacity: ${(props: { [key: string]: boolean }) => (props._disable ? 0.4 : 1)};
`;
