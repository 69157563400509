import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SliderBox, FullImg } from "./index.styled";

import { errorToast } from "@/components/atoms/Toast";
import { IMG_URL } from "@/@types/commonType";
import { useFetchApplication } from "@apis/hooks";

const EventBanner = () => {
  const navigate = useNavigate();
  const { data: application } = useFetchApplication();
  const progress = application.progress;

  const goEventPage = () => {
    if (progress !== "APPROVED")
      return errorToast("승인 후 이용하실수 있습니다");
    navigate("/my/invite-event");
  };

  const bannerList: ("inviteBanner1" | "inviteBanner2")[] = [
    "inviteBanner1",
    "inviteBanner2",
  ];

  const reactSliderSettings = {
    dots: false,
    infinite: true,
    navigation: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
  };

  return (
    <SliderBox _hidden>
      <Slider {...reactSliderSettings}>
        {bannerList.map(x => (
          <FullImg
            key={x}
            onClick={goEventPage}
            className="rightSlide"
            src={IMG_URL.home[x]}
            alt={x}
          />
        ))}
      </Slider>
    </SliderBox>
  );
};

export default EventBanner;
