import React from "react";

import {
  PlatformSalesFilterNames,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import { depositorsList } from "@pages/settlement/History/depositorsDataSet";
import {
  changePathToPlatform,
  changeTypeToCode,
} from "@pages/settlement/utils";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Tab from "@components/atoms/Tab";
import { Header, Meta } from "@components/layouts";
import { HistoryAmountList } from "@components/templates/settlement/SettlementAmountList";
import SettlementHistoryInfoBoard from "@components/templates/settlement/SettlementHistoryInfoBoard";

import useSettlement from "@hooks/useSettlement";

import {
  useFetchPlatformSales,
  useFetchPlatformSalesSummary,
} from "@apis/hooks";

import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";

export const History: React.FC = () => {
  const navigate = useNavigate();
  const {
    prevPath,
    startDateParams,
    endDateParams,
    typeParams,
    searchParams,
    deliveryParams,
    cardParams,
  } = useSettlement();
  const location = useLocation();
  const depositors =
    typeParams === "card"
      ? changePathToPlatform(cardParams)
      : changePathToPlatform(deliveryParams);
  const depositorParams =
    typeParams === "card" || typeParams === "delivery" ? depositors : null;

  /** 탭을 클릭하였을 경우 호출하는 함수입니다. */
  const handleClickTab = (value: string) => {
    searchParams.set("type", value);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      state: { from: prevPath },
    });
  };

  /** 건별 매출 요약 정보 API 를 호출합니다. */
  const {
    data: platformSalesSummaryData,
    isLoading: isPlatformSalesSummaryLoading,
  } = useFetchPlatformSalesSummary({
    startDate: startDateParams,
    endDate: endDateParams,
    filterType: changeTypeToCode(typeParams),
    depositor: depositorParams?.toUpperCase() ?? null,
  });

  /** Infinite Scroll 구현을 통해 건별 매출 데이터를 일정 개수씩 가져옵니다. */
  const {
    data: platformSalesData,
    isLoading: isPlatformSalesLoading,
    fetchNextPage,
    hasNextPage,
    totalCount,
  } = useFetchPlatformSales({
    startDate: startDateParams,
    endDate: endDateParams,
    filterType: changeTypeToCode(typeParams),
    depositor: depositorParams?.toUpperCase() ?? null,
  });
  /** target observer 요소를 감지하여 storeList 의 다음 페이지 업데이트 */
  const { setTarget } = useInfiniteScroll({
    hasNextPage,
    fetchNextPage,
  });

  const getBackUrl = () => {
    if (!prevPath) {
      return `/settlement?startDate=${startDateParams}&endDate=${endDateParams}`;
    }

    return prevPath;
  };

  trackPageViewMixpanel(
    `건별 상세내역 (${PlatformSalesFilterNames[changeTypeToCode(typeParams)]})`,
  );

  return (
    <Container>
      <Meta title={`얼리페이 | 정산내역`} />
      <Header
        title={"건별 상세내역"}
        item={"icon-text"}
        previous={getBackUrl()}
      />
      <Tab
        data={depositorsList}
        onClick={handleClickTab}
        selectedValue={typeParams}
      />

      {/** 선정산 건별 매출 요약 내역 */}
      <SettlementHistoryInfoBoard
        list={platformSalesSummaryData}
        isLoading={isPlatformSalesSummaryLoading}
      />
      <Divider />

      {/** 선정산 건별 매출 상세 내역 */}
      <Wrapper>
        <HistoryAmountList
          list={platformSalesData}
          isLoading={isPlatformSalesLoading}
          totalCount={totalCount}
        />
        {/** 스크롤이 닿는 영역 */}
        <div ref={setTarget} style={{ height: 1, width: "100%" }} />
      </Wrapper>
    </Container>
  );
};

export default History;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow-y: auto;
  cursor: default;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`;

const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 16px;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  flex-shrink: 0;
`;
