// style
import styled from "styled-components";
import theme from "@styles/theme";

const FaqStyle = styled.article`
  padding: ${(props: { [key: string]: string }) =>
    props._landing ? "100px 20px" : "0"};
`;

const FaqTitle = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  display: ${(props: { [key: string]: string }) =>
    props._landing ? "flex" : "none"};
`;

const FaqInner = styled.section`
  border-bottom: 1px solid #f3f4f7;
`;

const FaqButton = styled.button`
  width: 100%;
  display: flex;
  background: none;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border: none;
  padding: 18px 0;
`;

const FaqTextBox = styled.div`
  padding: 0 20px;
  overflow: hidden;
`;

const FaqNavButton = styled.button`
  width: 50%;
  padding: 10px 0;
  background: #fff;
  transition: all 0.15s;
  border-bottom: ${(props: { [key: string]: string }) =>
    props._on ? "3px solid #0070FA" : "3px solid #eee"};
  color: ${(props: { [key: string]: string }) => (props._on ? "#333" : "#aaa")};
  font-weight: 500;
  margin: 10px 0;
`;

const ContentTitle = styled.h3`
  font-weight: 700;
  font-size: ${theme.px[17]};
  line-height: 34px;
  letter-spacing: -1.25px;
  margin-left: 24px;
`;

export {
  FaqStyle,
  FaqTitle,
  FaqInner,
  FaqButton,
  FaqTextBox,
  FaqNavButton,
  ContentTitle,
};
