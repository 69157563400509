import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface ToggleButtonInterface {
  _textList: string[];
  _callBackFn?: (buttonList: { text: string; isActive: boolean }[]) => void;
  _fristSelect?: number;
  _reset: boolean;
}

const ToggleButtonWrapper = styled.div`
  background: #dbdbdb;
  padding: 4px;
  border-radius: 11px;
  display: grid;
  grid-template-columns: ${(props: { _buttonListLength: number }) =>
    `repeat(${props._buttonListLength}, 1fr)`};
  transition: color 0.3s;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: ${(props: { _isMoved: number }) => `calc(${props._isMoved}% + 4px)`};
    width: ${(props: { _buttonListLength: number }) =>
      `calc(${(100 / props._buttonListLength).toFixed(4)}% - 8px)`};
    height: calc(100% - 8px);
    border-radius: 10px;
    background: white;
    transition: all 0.3s;
    will-change: transform;
  }
`;

const ToggleInnerButton = styled.button`
  width: 100%;
  font-size: 15px;
  padding: 8px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  z-index: 1;
  color: ${(props: { _isActive: boolean }) =>
    props._isActive ? "#343434" : "#7e7e7e"};
  font-weight: 400;
  transition: all 0.3s;
  will-change: transform;
`;

const ToggleButton = ({
  _textList = ["버튼1", "버튼2"],
  _callBackFn = () => console.log(),
  _fristSelect = 0,
  _reset,
}: ToggleButtonInterface) => {
  const [isLoading, setIsLoading] = useState(false);
  const buttonTextList = _textList.map((text, index) => ({
    text,
    isActive: index === _fristSelect,
  }));
  const [buttonList, setButtonList] = useState([...buttonTextList]);
  const buttonListLength: number = buttonList.length;
  const isActive: number = buttonList.findIndex(
    button => button.isActive === true,
  );
  const isMoved: number =
    Number((100 / buttonListLength).toFixed(4)) * isActive;

  const toggleButtonHandler = (selectIndex: number) => {
    setIsLoading(true);
    const selectButtonList = buttonList.map((button, index) => {
      if (selectIndex === index) return { text: button.text, isActive: true };
      return { text: button.text, isActive: false };
    });
    setButtonList([...selectButtonList]);
    _callBackFn(selectButtonList);

    setIsLoading(false);
  };

  useEffect(() => {
    if (_reset) {
      const selectButtonList = buttonList.map((button, index) => {
        if (0 === index) return { text: button.text, isActive: true };
        return { text: button.text, isActive: false };
      });
      setButtonList([...selectButtonList]);
    }
  }, [_reset]);

  return (
    <ToggleButtonWrapper
      _isMoved={isMoved}
      _buttonListLength={buttonListLength}
    >
      {buttonList.map((button, index: number) => (
        <ToggleInnerButton
          key={button.text}
          disabled={isLoading}
          onClick={() => toggleButtonHandler(index)}
          _isActive={button.isActive}
        >
          {button.text}
        </ToggleInnerButton>
      ))}
    </ToggleButtonWrapper>
  );
};

export default ToggleButton;
