import Button from "src/components/atoms/Button";
import theme from "@styles/theme";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 498px;
  overflow: hidden;
  height: 52px;
  flex-shrink: 0;
`;

const HeaderStyle = styled.header`
  position: fixed;
  top: 0;
  height: 52px;
  flex: 1;
  width: 100%;
  max-width: 498px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${({ hasBackButton }: { hasBackButton: boolean }) =>
    hasBackButton ? "space-between" : "end"};
  background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor ? theme.color.white_00 : theme.color.white_10};
  z-index: 15;

  h2 {
    font-size: ${theme.px[17]};
    color: #333436;
    font-weight: 400;
  }
  padding: 10px 16px;
  border-bottom: 1px solid #e0e0e0;
`;

const BackRouteButton = styled.button`
  color: #333436;
  font-weight: 500;
  border: none;
  background: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 48px;
  width: 83px;

  span {
    font-size: 30px;
  }
`;

const ChannelIOButton = styled(Button)`
  visibility: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "hidden" : "visible"};
`;

export { BackRouteButton, ChannelIOButton, HeaderStyle, Wrapper };
