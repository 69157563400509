import React from "react";

import { openChannelIOChat } from "@earlypay/shared/configs";

import { FlexBox, Span } from "@components/commons/index";

import { useFetchApplication } from "@apis/hooks";

import { IMG_URL } from "@/@types/commonType";

import { StoreBadge, StoreName, UserTitle } from "./index.styled";

const UserName = ({ _userName }: { _userName: string }) => {
  return (
    <UserTitle>
      <img src={IMG_URL.icon.logo} alt="홈 로고" />
      <p>{_userName} 사장님</p>
    </UserTitle>
  );
};

const ConsultButton = () => {
  return (
    <FlexBox
      _row
      _style={{ width: "auto", alignItems: "center" }}
      _onClick={openChannelIOChat}
    >
      <Span _style={{ fontSize: "14px", marginRight: "2px" }}>상담하기</Span>
      <img src={IMG_URL.icon.contact} alt="상담하기 버튼" width="20px" />
    </FlexBox>
  );
};

const HomeHeader = () => {
  const { data: application } = useFetchApplication();

  const serviceType: { [key: string]: string } = {
    DE: "배달",
    CA: "카드",
    AL: "카드+배달",
  };

  return (
    <>
      <FlexBox _row _style={{ justifyContent: "space-between" }}>
        <UserName _userName={application.user.name} />
        <ConsultButton />
      </FlexBox>
      <FlexBox _row _style={{ width: "auto" }}>
        <StoreBadge>
          {serviceType[application.serviceType] ?? "미연동"}
        </StoreBadge>
        <StoreName>{application.title ?? "미연동"}</StoreName>
      </FlexBox>
    </>
  );
};

export default HomeHeader;
