export enum CardCode {
  KB = "01",
  SHINHAN = "03",
  BC = "04",
  LOTTE = "11",
  HYUNDAI = "12",
  SAMSUNG = "13",
  WOORI = "15",
  NH = "19",
  HANA = "21",
}
export type CardCodeTypes = (typeof CardCode)[keyof typeof CardCode];

export const CardNames: { [key in CardCode]: string } = {
  [CardCode.BC]: "비씨",
  [CardCode.KB]: "국민",
  [CardCode.HANA]: "하나",
  [CardCode.SAMSUNG]: "삼성",
  [CardCode.SHINHAN]: "신한",
  [CardCode.HYUNDAI]: "현대",
  [CardCode.LOTTE]: "롯데",
  [CardCode.NH]: "농협",
  [CardCode.WOORI]: "우리",
};
export type CardNamesTypes = (typeof CardNames)[keyof typeof CardNames];

export const DeliveryCode = {
  BAEMIN: "BAEMIN",
  YOGIYO: "YOGIYO",
  COUPANG: "COUPANG",
};
export type DeliveryCodeTypes =
  (typeof DeliveryCode)[keyof typeof DeliveryCode];

export const DeliveryNames: { [key in DeliveryCodeTypes]: string } = {
  [DeliveryCode.BAEMIN]: "배달의민족",
  [DeliveryCode.YOGIYO]: "요기요",
  [DeliveryCode.COUPANG]: "쿠팡이츠",
};

export enum DepositorCode {
  CARD = "CARD",
  BAEMIN = "BAEMIN",
  YOGIYO = "YOGIYO",
  COUPANG = "COUPANG",
  ETC = "ETC",
}
export const DepositorNames: { [key in DepositorCode]: string } = {
  [DepositorCode.CARD]: "카드",
  [DepositorCode.BAEMIN]: "배달의민족",
  [DepositorCode.YOGIYO]: "요기요",
  [DepositorCode.COUPANG]: "쿠팡이츠",
  [DepositorCode.ETC]: "기타",
};
export type DepositorNamesTypes =
  (typeof DepositorNames)[keyof typeof DepositorNames];

export type PlatformCodeType = CardCode | DeliveryCodeTypes | "UNKNOWN" | "ETC";

export const PlatformNames: { [key in CardCode | DeliveryCodeTypes]: string } =
  {
    ...CardNames,
    ...DeliveryNames,
  };
export type PlatformNamesTypes =
  (typeof PlatformNames)[keyof typeof PlatformNames];

export const DeliveryAdCode = {
  BAEMIN_ONE: "BAEMIN_ONE",
  BAEMIN: "BAEMIN",
  YOGIYO: "YOGIYO",
  COUPANG: "COUPANG",
};
export type DeliveryAdCodeTypes =
  (typeof DeliveryAdCode)[keyof typeof DeliveryAdCode];
