import React, { useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import theme from "@styles/theme";
import styled from "styled-components";

import {
  Accordion,
  Button,
  FlexBox,
  Meta,
  Span,
  Text,
} from "@components/commons/index";
import { Header, Spinner } from "@components/modules/common/index";

import { useFetchApplication, useFetchCheckedEarlypaid } from "@apis/hooks";

import { IMG_URL } from "@/@types/commonType";
import { sendKakaoLink } from "@/utils/sendKakaoLink";

const InviteEvent = () => {
  const inviteFaq = [
    {
      title: "몇명까지 초대가 가능한가요?",
      content:
        '<div style="padding:0px 10px"><p style="font-size:15px;color:#767b83!important;font-weight:bold;line-height:1.5">친구 추천은 무한 가능합니다!</p><p style="line-height:1.5;font-size:15px;color:#767b83!important">좋은 서비스를 친구에게 소개 시켜 주시고,얼리페이도 무제한 무료로 사용하세요!</p></div>',
    },
    {
      title: "무료기간은 언제 지급되나요?",
      content:
        '<div style="padding:0px 10px"><li style="font-size:15px;color:#767b83!important;line-height:1.5;margin-bottom:8px"><span style="font-weight:bold;">추천하신 사장님</span>은 가입한 친구가 선정산이 시작되면 <span style="font-weight:bold;">익일 부터</span> 무료 기간이 <span style="font-weight:bold;">자동 적용 됩니다.</span></li><li style="font-size:15px;color:#767b83!important;line-height:1.5"><span style="font-weight:bold;">추천받은 친구</span>는 서비스 신청 즉시 자동 적용 됩니다.</li></div>',
    },
    {
      title: "친구추천 현황을 보고싶어요!",
      content:
        '<div style="padding:0px 10px"><p style="font-size:15px;color:#767b83!important;line-height:1.5"><span style="font-weight:bold;">마이 페이지 > 친구 추천 현황</span> 에서 확인 하실수 있어요!</div>',
    },
  ];

  // state
  const [isLoading, setIsLoading] = useState(false);

  // users info
  const { data: application } = useFetchApplication();
  const { data: earlypaid } = useFetchCheckedEarlypaid(application.store.id);

  const userName = application.user.name;
  const recommenderCode = application.user.recommenderCode;

  trackPageViewMixpanel("친구 초대 이벤트");

  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} />
      <Header _white _backHistory="/my/event">
        친구초대 이벤트
      </Header>
      <MainOldLayout _padding>
        <Text
          _center
          _style={{
            fontSize: "13px",
            marginTop: "10px",
            color: theme.color.blue_00,
          }}
        >
          초대 링크를 통해 친구 가입시
        </Text>
        <Text
          _medium
          _center
          _style={{ fontSize: "38px", marginBottom: "4px" }}
        >
          친구 <Span _style={{ color: theme.color.blue_00 }}>한달 +</Span> 나{" "}
          <Span _style={{ color: theme.color.blue_00 }}>한달</Span>
        </Text>
        <Text _center _style={{ fontSize: "20px" }}>
          무료기간 무제한 지급
        </Text>
        <FlexBox _align _style={{ margin: "20px 0", padding: "0 40px" }}>
          <img
            width={"40%"}
            src={IMG_URL.event.inviteEvent2}
            alt="아이콘"
            style={{ maxWidth: "500px", width: "100%" }}
          />
        </FlexBox>
        <div
          style={{
            marginLeft: "-20px",
            marginRight: "-20px",
            padding: "42px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <CardBox>
            공유한 링크를 통해 친구가 얼리페이 가입을 완료하면 친구와 나
            모두에게
            <Span _medium _style={{ color: theme.color.blue_00 }}>
              {" "}
              1개월 무료 구독권 지급!
            </Span>
            <div>(무료기간 자동적용)</div>
          </CardBox>
          <CardBox>
            가입한 친구 수 만큼 무료 개월 수{" "}
            <Span _medium _style={{ color: theme.color.blue_00 }}>
              무제한 지급
            </Span>
            <div>ex) 3명 가입 = 3개월 서비스이용료 무료</div>
          </CardBox>
        </div>
        <div
          style={{
            marginLeft: "-20px",
            marginRight: "-20px",
          }}
        >
          {inviteFaq.map(
            (x: { active: boolean; title: string; content: string }) => {
              return (
                <Accordion
                  key={x.title}
                  _style={{ border: "none", padding: "20px 0px", margin: "0" }}
                >
                  <FlexBox
                    _align
                    _row
                    _style={{ justifyContent: "space-between" }}
                  >
                    <Text _style={{ fontSize: "15px" }}>{x.title}</Text>
                  </FlexBox>
                  <div
                    dangerouslySetInnerHTML={{ __html: x?.content }}
                    style={{
                      padding: "20px 20px 10px 20px",
                    }}
                  ></div>
                </Accordion>
              );
            },
          )}
        </div>
        <div style={{ marginBottom: "100px" }}></div>
      </MainOldLayout>
      <FlexBox
        _style={{
          position: "fixed",
          bottom: "80px",
          padding: "0 20px",
          maxWidth: "500px",
        }}
      >
        <Button
          _disabled={isLoading}
          _onClick={() =>
            sendKakaoLink(earlypaid, userName, recommenderCode, setIsLoading)
          }
          _style={{
            background: "#ffed00",
            padding: "18px 0",
            borderRadius: "10px",
          }}
        >
          <Span _buttonInner _style={{ color: "#381e1e", fontWeight: "bold" }}>
            <img
              src={IMG_URL.icon.kakao}
              alt="카카오 버튼 아이콘"
              style={{ marginRight: "6px" }}
            />
            친구 초대하고 한 달 무료 받기
          </Span>
        </Button>
      </FlexBox>
      {isLoading && <Spinner />}
    </>
  );
};

export default InviteEvent;

const CardBox = styled.div`
  width: 100%;
  padding: 16px 20px;
  background: #fff;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  border: 1px solid #ddd;

  ul {
    margin-left: 20px;
  }
  li {
    line-height: 1.4;
  }
  div {
    color: #767b83;
    margin-top: 10px;
    font-weight: 300;
    font-size: 14px;
  }
`;
