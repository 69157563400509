/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import styled from "styled-components";
import { instance } from "@apis/instance";

// react-entryRouter
import { useNavigate } from "react-router-dom";
import { deleteCookie } from "@/utils/cookie";

const Presenter = ({
  _userToken,
  _setUserToken,
  _tokenLogin,
}: {
  _userToken: any;
  _setUserToken: any;
  _tokenLogin: any;
}) => {
  const navigate = useNavigate();

  return (
    <AdminBox>
      <div className="input-box">
        <h3>토큰을 입력해주세요</h3>
        <input
          value={_userToken}
          onChange={e => _setUserToken(e.target.value)}
        />
        <div>
          <button
            onClick={() => {
              deleteCookie("earlypay-token");
              Object.assign(instance.defaults, {
                headers: { authorization: `` },
              });
              navigate("/");
              return;
            }}
          >
            닫기
          </button>
          <button
            onClick={() => {
              _tokenLogin(_userToken);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </AdminBox>
  );
};

export default Presenter;

const AdminBox = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input-box {
    width: 80%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    h3 {
      margin-bottom: 10px;
      font-size: 14px;
    }
    input {
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid red;
    }
    button {
      width: 50%;
      background: transparent;
      padding-top: 10px;
      cursor: pointer;
    }
  }
`;
