import { createQueryKeys } from "@lukemorales/query-key-factory";
import { GetPlatformSalesParams } from "@apis/endpoints/sales/types";

export const salesQueryKeys = createQueryKeys("sales", {
  fetchPlatformSales: (params: GetPlatformSalesParams) => [
    "fetchPlatformSales",
    params,
  ],
  fetchPlatformSalesSummary: (params: GetPlatformSalesParams) => [
    "fetchPlatformSalesSummary",
    params,
  ],
});
