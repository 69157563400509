import styled from "styled-components";

import { FlexBox, Button } from "@components/commons/index";

export const DeopositsBox = styled.article`
  width: 100%;
  background: #fff;
  border-radius: 13px;
  border: 1px solid #efefef;
  margin-top: 24px;
  box-shadow: 0px 0px 8px 0px #00000020;
  padding: 20px 24px;
`;

export const DepositRoutingButton = styled(Button)`
  background: linear-gradient(92.05deg, #0070fa 30%, #0070fa 100%);
  padding: 12px 0;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const DepositTextBox = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 6px;
`;

export const AmountBox = styled(FlexBox)`
  justify-content: space-between;
  align-items: end;

  &:last-child {
    margin-top: 5px;
  }
`;
