/* eslint-disable @typescript-eslint/no-explicit-any */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import deposit from "./modules/deposit";
import sales from "./modules/sales";

const middleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: {
    sales,
    deposit,
  },
  middleware: () => middleware,
  devTools: import.meta.env.NODE_ENV !== "production",
});

const reducers = combineReducers({
  sales,
  deposit,
});

const persistConfig = {
  key: "root",
  storage, // localStorage
  whitelist: ["sales", "deposit"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => middleware,
  devTools: import.meta.env.NODE_ENV !== "production",
});
export const persistor = persistStore(store);

export type RootState = ReturnType<any>;
export type AppDispatch = typeof store.dispatch;
