/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
// apis
// component(styled)
import { ChartCard } from "./index.styled";
// chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartInterface } from "../SalesTitle";
// dateFns
import { format, lastDayOfMonth } from "date-fns";
// styled
import theme from "@styles/theme";
import { getSalesStatistics } from "@apis/endpoints";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const TimeChart = ({ _currentMonth, _storeId }: ChartInterface) => {
  const [chartData, setChartData] = useState([
    { time: "", count: 0, total: 0 },
  ]);

  const lastDay = new Date(
    _currentMonth.getFullYear(),
    _currentMonth.getMonth() + 1,
    0,
  ).getDate();

  useEffect(() => {
    if (!_storeId) return;
    (async function getTimeChart() {
      const startDate = format(_currentMonth, "yyyy-MM-01");
      const endDate = format(lastDayOfMonth(_currentMonth), "yyyy-MM-dd");

      const data = await getSalesStatistics({
        start_date: startDate,
        end_date: endDate,
        store_id: _storeId,
      });
      setChartData([...data]);
    })();
  }, [_currentMonth, _storeId]);

  // ---------------------------------- chart option ---------------------------------
  const options: any = {
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        callbacks: {
          label: (context: any) => {
            return (
              "매출 : " +
              Number(context.parsed.y.toFixed(0)).toLocaleString() +
              "원"
            );
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        afterDataLimits: (scale: { max: number }) => {
          scale.max = scale.max * 1.1;
        },
        afterTickToLabelConversion: function (q: any) {
          for (const tick in q.ticks) {
            const label = Math.floor(q.ticks[tick].value / 10000);
            q.ticks[tick].label = label + "만";
          }
        },
      },
    },
  };

  // set chart data
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "총 매출 데이터",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  });

  // ---------------------------------- get chart ---------------------------------
  useEffect(() => {
    const labels = chartData.map(x => `${Number(x.time.split(":")[0])}시`);
    const data = chartData.map(x => x.total / lastDay);

    const montData = {
      labels: [...labels],
      datasets: [
        {
          label: "총 매출 데이터",
          data: [...data],
          backgroundColor: [theme.color.blue_00],
        },
      ],
    };
    setData(montData);
    return;
  }, [chartData]);

  const amountList = chartData.map(x => x.total);
  const filterAmount = chartData.filter(x => {
    if (x.total === Math.max(...amountList)) {
      return x;
    }
  })[0];

  const time = filterAmount.time.split(":")[0];
  const amount = Number(
    (filterAmount.total / lastDay).toFixed(0),
  ).toLocaleString();

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <ChartCard>
      <h3>{_currentMonth.getMonth() + 1}월 시간대별 평균매출</h3>
      <p>
        가장 높은 시간대 평균 매출은{" "}
        <span style={{ color: theme.color.blue_00 }}>
          {time}시 {amount}원
        </span>{" "}
        입니다.
      </p>
      <Line options={options} data={data} />
    </ChartCard>
  );
};

export default TimeChart;
