import React from "react";
import {
  ButtonWrapper,
  ContentWrapper,
  StyledContainer,
  Title,
} from "./index.styled";
import Stack from "@components/atoms/Stack";
import {
  StackAlignTypes,
  StackJustifyTypes,
} from "@components/atoms/Stack/type";
import { ButtonTypes } from "@components/atoms/Button/type";
import Button from "@components/atoms/Button";
import { FontTypes } from "@/@types/ThemesType";

export interface ServiceApplyContainerProps {
  title: string;
  children: React.ReactNode;
  className?: string | Array<string>;
  buttonType?: ButtonTypes;
  isLoading?: boolean;
  isDisabled?: boolean;
  buttonLabel?: string;
  height?: string;
  styles?: React.CSSProperties;
  button?: React.ReactNode;
  onClickButton?: () => void;
  spacing?: number;
  align?: "START" | "CENTER" | "END";
  hasHeader?: boolean;
}

export const ServiceApplyContainer: React.FC<ServiceApplyContainerProps> = ({
  title,
  children,
  className,
  styles,
  height,
  button,
  buttonLabel = "확인",
  buttonType = ButtonTypes.PRIMARY,
  isLoading,
  isDisabled,
  onClickButton,
  spacing,
  hasHeader = false,
  align = StackAlignTypes.CENTER,
}) => {
  return (
    <StyledContainer hasHeader={hasHeader} className={className} style={styles}>
      <Stack
        height="100%"
        justify={StackJustifyTypes.BETWEEN}
        align={align}
        width={"100%"}
      >
        <ContentWrapper
          center={align === "CENTER"}
          spacing={spacing ? spacing : 64}
          width={"100%"}
          height={height}
        >
          <Title tag={FontTypes.SUBTITLE_1} bold center={align === "CENTER"}>
            {title}
          </Title>
          {children}
        </ContentWrapper>

        {/** 하단 버튼 **/}
        <ButtonWrapper>
          {button ? (
            button
          ) : (
            <Button
              tag={buttonType}
              isLoading={isLoading}
              isDisabled={isDisabled || isLoading}
              onClick={onClickButton}
            >
              {buttonLabel}
            </Button>
          )}
        </ButtonWrapper>
      </Stack>
    </StyledContainer>
  );
};

export default ServiceApplyContainer;
