import styled from "styled-components";
// component(base)
import { FlexBox, Text, Button } from "@components/commons/index";
import { fadeBackgroundMotion, upModalMotion } from "@styles/animation";

export const LandingPopupFixGrid = styled(FlexBox)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  align-items: center;
  justify-content: center;
  padding: 0 20px 20px 20px;

  ${fadeBackgroundMotion}
`;

export const LandingPopupBox = styled(FlexBox)`
  width: 100%;
  max-width: 500px;
  background: #fff;
  border-radius: 20px;
  padding: 20px 24px 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${upModalMotion}

  img {
    width: 60%;
    margin-bottom: 20px;
  }
`;

export const TitleText = styled(Text)`
  font-size: 17px;
  font-weight: 500;
  color: #333;
  line-height: 22px;
  letter-spacing: 0.5px;
`;

export const ExitButton = styled(Button)`
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 0;
  background: none;
`;

export const ConfirmButton = styled(Button)`
  padding: 10px 20px;
  background: #0000f5;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  margin-top: 20px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
