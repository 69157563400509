import React, { useEffect, useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Meta } from "@components/layouts";

export const Terms = () => {
  const location = useLocation();

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const termsCode = pathSegments[pathSegments.length - 1];

  const [htmlContent, setHtmlContent] = useState("");

  /** S3 버킷에서 약관 .html 파일을 가져옵니다. */
  useEffect(() => {
    const htmlUrl = `${
      import.meta.env.VITE_S3_URI
    }/terms/current/${termsCode}.html`;
    const fetchHtml = async () => {
      try {
        const response = await fetch(htmlUrl);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error("Error fetching HTML:", error);
      }
    };

    fetchHtml();
  }, [termsCode]);

  trackPageViewMixpanel(`약관 | ${termsCode}`);

  return (
    <Wrapper>
      <Meta title={`얼리페이 | 약관`} />
      <TermsContainer>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </TermsContainer>
    </Wrapper>
  );
};

export default Terms;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--earlybird-color-bg-primary);
`;

const TermsContainer = styled.div`
  width: 40%;
  min-width: 820px;
  display: flex;

  @media (max-width: 860px) {
    min-width: 400px;
  }

  @media (max-width: 560px) {
    min-width: 300px;
  }
`;
