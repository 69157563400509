/* eslint-disable @typescript-eslint/no-explicit-any */
const legendColors = ["#5165cc", "#aaa"];

export const options: any = {
  plugins: {
    legend: {
      align: "end",
      labels: {
        color: "#333",
        usePointStyle: true,
        padding: 10,
        font: {
          family:
            "'-apple-system,BlinkMacSystemFont','Helvetica Neue','Apple SD Gothic Neo','Arial,sans-serif'",
          lineHeight: 1,
          size: 10,
        },
        pointStyleWidth: 10,
        generateLabels: (chart: any) => {
          const datasets = chart.data.datasets;
          const {
            labels: { usePointStyle },
          } = chart.legend.options;
          return chart._getSortedDatasetMetas().map((meta: any, i: any) => {
            const style = meta.controller.getStyle(
              usePointStyle ? 0 : undefined,
            );
            return {
              fontColor: legendColors[i],
              datasetIndex: meta.index,
              pointStyle: "rect",
              text: datasets[meta.index].label,
              fillStyle: style.backgroundColor,
              lineWidth: 0,
            };
          }, this);
        },
      },
    },

    tooltip: {
      mode: "index",
      intersect: false,
      padding: 10,
      bodySpacing: 5,
      caretPadding: 10,
      caretSize: 0,
      displayColors: true,
      backgroundColor: "rgba(54, 54, 54, 0.8)",
      bodyFontColor: "rgb(255,255,255)",
      bodyFont: {
        font: {
          family: "'Noto Sans KR', sans-serif",
        },
      },
      usePointStyle: true,
      filter: (item: any) => item.parsed.y !== null,
      callbacks: {
        title: (context: any) => context[0].label,
        label: (context: any) => {
          const label = context.dataset.label + "" || "";

          return context.parsed.y !== null
            ? label + ": " + context.parsed.y.toLocaleString() + "원"
            : null;
        },
      },
    },
  },

  scales: {
    x: {
      grid: { display: false },
      axis: "x",
      position: "bottom",
      ticks: {
        font: { size: 14 },
        color: ["#f75364", "#555", "#555", "#555", "#555", "#555", "#253aa9"],
        padding: 6,
      },
    },
    y: {
      type: "linear",
      afterDataLimits: (scale: { max: number }) =>
        (scale.max = scale.max * 1.1),
      axis: "y",
      display: true,
      afterTickToLabelConversion: function (q: {
        ticks: [{ value: number; label: string }];
      }) {
        for (const tick in q.ticks) {
          const label = Math.floor(q.ticks[tick].value / 10000);
          if (label !== 0) {
            q.ticks[tick].label = label + "만";
          } else {
            q.ticks[tick].label = label + "";
          }
        }
      },
    },
  },
};
