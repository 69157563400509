import { createQueryKeys } from "@lukemorales/query-key-factory";

export const documentQueryKeys = createQueryKeys("document", {
  fetchBusinessLicense: (applicationId: number) => [
    "fetchBusinessLicense",
    applicationId,
  ],
  maskingBusinessLicense: (applicationId: number) => [
    "maskingBusinessLicense",
    applicationId,
  ],
  verifyBusinessLicense: (applicationId: number) => [
    "verifyBusinessLicense",
    applicationId,
  ],
  updateIdentification: (applicationId: number) => [
    "updateIdentification",
    applicationId,
  ],
});
