import React from "react";
import styled from "styled-components";
import { UseFormRegister, FieldValues } from "react-hook-form";

interface RadioButtonProps {
  isDisabled?: boolean;
  isSelected?: boolean;
  size?: "MD";
  name: string;
  value: string;
  register?: UseFormRegister<FieldValues>;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  isSelected,
  name,
}) => {
  return (
    <Wrapper>
      <SelectedCircle name={name} isSelected={isSelected}>
        <SelectedInnerCircle isSelected={isSelected} />
      </SelectedCircle>
    </Wrapper>
  );
};

export default RadioButton;

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 7px;
  cursor: pointer;
`;

const SelectedCircle = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  border: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "2px solid #0e5cff" : "2px solid #a8a8a8"};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
`;

const SelectedInnerCircle = styled.input`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #0e5cff;
  display: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "flex" : "none"};
  cursor: pointer;
  transition: all ease-in-out 0.15s;
`;
