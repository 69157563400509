// component(base)
import { FlexBox } from "@components/commons";
// styled
import styled from "styled-components";
import theme from "@styles/theme";

export const ChartCard = styled(FlexBox)`
  width: 100%;
  border: 1px solid #efefef;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
  margin-bottom: 40px;

  h3 {
    font-weight: 600;
    margin-bottom: 8px;
    color: #1f1f1f;
    font-size: 16px;
  }

  p {
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 11px;
    color: ${theme.color.gray_60};
  }
`;
