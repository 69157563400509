import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import type { SaleReduxInterface } from "@/@types/depositType";
import { getDailySalesPlatform } from "@apis/endpoints";

export interface StoreParamsType {
  page?: number;
  pageSize?: number;
  isCanceled?: boolean;
  isDelivery?: boolean;
  platform?: string;
  cardCompany?: string;
  gte: string;
  lte: string;
  storeId: number;
}

export const getPlatformDailySalesThunk = createAsyncThunk(
  "sale/getPlatformDailySalesThunk",
  async (params: StoreParamsType) => {
    try {
      const { data } = await getDailySalesPlatform({
        ...params,
      });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getTabPlatformDailySalesThunk = createAsyncThunk(
  "sale/getTabPlatformDailySalesThunk",
  async (params: StoreParamsType) => {
    try {
      const { data } = await getDailySalesPlatform({
        ...params,
      });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getNextPlatformDailySalesThunk = createAsyncThunk(
  "sale/getNextPlatformDailySalesThunk",
  async (params: StoreParamsType) => {
    try {
      const { data } = await getDailySalesPlatform({
        ...params,
      });
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const salesSlice = createSlice({
  name: "sales",
  initialState: {
    sales: {
      totalAmountGroupByPlatform: {
        국민: { amount: 0, count: 0, amount_deposit: 0 },
        하나: { amount: 0, count: 0, amount_deposit: 0 },
        YOGIYO: { amount: 0, count: 0, amount_deposit: 0 },
        신한: { amount: 0, count: 0, amount_deposit: 0 },
        BAEMIN: { amount: 0, count: 0, amount_deposit: 0 },
        삼성: { amount: 0, count: 0, amount_deposit: 0 },
        롯데: { amount: 0, count: 0, amount_deposit: 0 },
        농협: { amount: 0, count: 0, amount_deposit: 0 },
        비씨: { amount: 0, count: 0, amount_deposit: 0 },
        CANCELED: { amount: 0, count: 0, amount_deposit: 0 },
        현대: { amount: 0, count: 0, amount_deposit: 0 },
      },
      data: {
        count: null,
        results: [],
      },
      params: {
        ordering: "-sold_at",
        page: 1,
        page_size: 20,
      },
    },
  } as SaleReduxInterface,
  reducers: {},
  extraReducers: {
    [getPlatformDailySalesThunk.fulfilled.type]: (state, { payload }) => {
      state.sales.totalAmountGroupByPlatform =
        payload.total_amount_group_by_platform;

      state.sales.data.count = payload.count;
      state.sales.data.results = [...payload.results];
    },
    [getTabPlatformDailySalesThunk.fulfilled.type]: (state, { payload }) => {
      state.sales.data.count = payload.count;
      state.sales.data.results = [...payload.results];
    },
    [getNextPlatformDailySalesThunk.fulfilled.type]: (state, { payload }) => {
      state.sales.data.results = state.sales.data.results.concat(
        payload.results,
      );
    },
  },
});

export default salesSlice.reducer;
