import React from "react";
// style
import {
  DefaultSpanStyle,
  ButtonSpanStyle,
  WarningSpanStyle,
} from "./index.styled";
import theme from "@styles/theme";

interface SpanPropsType {
  _medium?: boolean;
  _bold?: boolean;
  _center?: boolean;
  _fontType?: string;
  className?: string;
  _style?: object;
  _buttonInner?: boolean;
  _warning?: boolean;
  _onClick?(): void;
  children?: React.ReactNode | string | [];
}

const Span = ({
  _medium,
  _bold,
  _center,
  _fontType,
  className,
  _style,
  _buttonInner,
  _warning,
  _onClick,
  children,
}: SpanPropsType): JSX.Element => {
  const styles = {
    _medium,
    _bold,
    _center,
    _warning,
  };

  const fontClass: string = theme.fontFamily.filter(
    x => x.class === _fontType,
  )[0]?.fontFamily;

  const classArr = [fontClass, className];

  if (_buttonInner) {
    return (
      <ButtonSpanStyle
        onClick={_onClick}
        className={classArr.join(" ")}
        style={{ ..._style }}
        {...styles}
      >
        {children}
      </ButtonSpanStyle>
    );
  }

  if (_warning) {
    return (
      <WarningSpanStyle
        onClick={_onClick}
        className={classArr.join(" ")}
        style={{ ..._style }}
        {...styles}
      >
        {children}
      </WarningSpanStyle>
    );
  }

  return (
    <DefaultSpanStyle
      onClick={_onClick}
      className={classArr.join(" ")}
      style={{ ..._style }}
      {...styles}
    >
      {children}
    </DefaultSpanStyle>
  );
};

export default Span;
