import React from "react";
// styled
import styled from "styled-components";
import theme from "@styles/theme";

interface ProgressBarInterface {
  _per?: string;
}

const ProgressBar = ({ _per = "0%" }: ProgressBarInterface) => {
  return (
    <BarDiv _per={_per}>
      <div></div>
    </BarDiv>
  );
};

export default ProgressBar;

const BarDiv = styled.div`
  width: calc(100% - 48px);
  height: 5px;
  background: #dbdbdb;
  border-radius: 10px;
  margin: 0 24px;

  div {
    transition: all 0.3s ease-in-out;
    width: ${(props: { [key: string]: string }) => props._per};
    width: red;
    height: 5px;
    background: ${theme.color.blue_00};
    border-radius: 10px;
  }
`;
