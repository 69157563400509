import { sentryHook } from "@earlypay/shared/configs";
import {
  ErrorResponse,
  ServerError,
  modalCode,
} from "@earlypay/shared/typings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { useConfirmModal } from "@earlybird/ui";

import { successToast } from "@components/atoms/Toast";

import { useLogin } from "@hooks/useLogin";

import {
  getUsersToken,
  postUserAuth,
  postUserAuthProps,
} from "@apis/endpoints/user";
import { applicationsQueryKeys } from "@apis/hooks/applications/queryKeys";

import { userQueryKeys } from "./queryKeys";

export const useRedirectUsers = (userId: number) => {
  return useMutation({
    mutationKey: userQueryKeys.fetchUsersToken().queryKey,
    mutationFn: async (pathname: string) => await getUsersToken(userId),
    onSuccess: (
      data: { ok: boolean; accessToken: string },
      variables: string,
    ) => {
      const pathname = `${
        import.meta.env.VITE_EPAYLOAN_API_URL
      }/v2/users/redirect`;
      const params = {
        url: `${import.meta.env.VITE_EPAYLOAN_BASE_URL}${variables}`,
        token: encodeURIComponent(data.accessToken),
      };
      const queryString = new URLSearchParams(params).toString();

      window.location.href = `${pathname}?${queryString}`;
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "리디렉트에 필요한 토큰 발급에 실패하였습니다.",
        error: error,
      });
    },
    retry: 0,
  });
};

export const useUpdateUserAuth = (redirectUri: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { openConfirmModal } = useConfirmModal();
  const { handleLogout } = useLogin();

  return useMutation({
    mutationKey: userQueryKeys.updateUserAuth().queryKey,
    mutationFn: async (payload: postUserAuthProps) => postUserAuth(payload),
    onError: (error: ServerError) => {
      const errorData = error?.response?.data;
      const errorResponse =
        typeof errorData === "string"
          ? undefined
          : (errorData as ErrorResponse);

      if (
        errorResponse &&
        "code" in errorResponse &&
        errorResponse.code === "under-age"
      ) {
        openConfirmModal({
          id: modalCode.WARNING_UNDER_AGE_AUTH,
          onClickPrimaryButton: handleLogout,
        });
      }

      sentryHook({
        title: "유저 본인인증에 실패하였습니다.",
        error: error,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });

      if (location.pathname.includes("my")) {
        successToast("개인정보 변경에 성공했습니다");
      }
      return navigate(redirectUri);
    },
    retry: 0,
  });
};
