import styled from "styled-components";

export const SpinnerBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  top: 0;
  left: 0;

  p {
    position: absolute;
    font-weight: bold;
    color: #000;
    opacity: 0.2;
  }
`;

export const EarlyPayLogo = styled.div`
  border-radius: 0 40% 30% 69% / 0 56% 43% 100%;
  width: 86px;
  height: 55px;
  margin-right: 15px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  clip-path: polygon(
    5% 0,
    29% 64%,
    40% 84%,
    48% 93%,
    56% 98%,
    65% 100%,
    60% 77%,
    59% 35%,
    79% 35%,
    79% 68%,
    50% 68%,
    60% 100%,
    86% 100%,
    99% 100%,
    100% 85%,
    100% 5%,
    100% 0%
  );
  overflow: hidden;
`;

export const FirstWave = styled.div`
  width: 150px;
  height: 150px;
  background: #ccc;
  position: absolute;
  border-radius: 40%;
  background: linear-gradient(180deg, #2a45d2 0%, #253aa9 45.31%, #602798 100%);
  animation: rotate 3000ms infinite;

  @keyframes rotate {
    to {
      transform: rotate(0deg);
      top: 00%;
    }
    from {
      transform: rotate(360deg);
      top: 100%;
    }
  }
`;

export const SecondaryWave = styled.div`
  width: 110px;
  height: 120px;
  background: #ccc;
  position: absolute;
  border-radius: 30%;
  background: linear-gradient(180deg, #2a45d2 0%, #253aa9 45.31%, #602798 100%);
  animation: rotate 3000ms infinite;
  left: -30px;
  @keyframes rotate {
    to {
      transform: rotate(20deg);
      top: -10%;
    }
    from {
      transform: rotate(380deg);
      top: 110%;
    }
  }
`;
