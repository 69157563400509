// component(base)
import { FlexBox } from "@components/commons/index";
// style
import styled from "styled-components";

export const CalendarHeaderBox = styled(FlexBox)`
  padding: 5px 0 10px 0;
  position: relative;
  display: flex;
  align-items: center;
`;

export const HeaderDate = styled.div`
  width: 90px;
  text-align: center;
`;

export const DateText = styled.span`
  font-size: 16px;
  width: 90px;
  display: inline-block;
  text-align: center;
`;

export const IndexGrid = styled(FlexBox)`
  position: absolute;
  right: 10px;
  width: auto;
`;

export const CalendarIndexIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props: { [key: string]: string }) =>
    props._type === "매출" && "#ff8b97"};
  background: ${(props: { [key: string]: string }) =>
    props._type === "정산" && "#899dff"};
  margin-right: 2px;
  border-radius: 1px;
  margin-top: 1px;
`;

export const CalendarIndexText = styled.div`
  font-size: 12px;
  color: ${(props: { [key: string]: string }) =>
    props._type === "매출" && "#ff8b97"};
  color: ${(props: { [key: string]: string }) =>
    props._type === "정산" && "#899dff"};
  &:nth-child(2) {
    margin-right: 8px;
  }
`;

export const PrevButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  background: #fff;
  justify-content: flex-start;
  text-align: left;
  margin-right: 10px;
  margin-top: 1px;
  font-size: 20px;
  border-radius: 50%;
  align-items: center;
  &:active {
    background: #eee;
  }
`;

export const NextButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  background: #fff;
  justify-content: flex-end;
  text-align: left;
  margin-left: 10px;
  margin-top: 1px;
  font-size: 20px;
  border-radius: 50%;
  align-items: center;
  opacity: ${(props: { [key: string]: boolean }) => (props._current ? 0 : 1)};

  &:active {
    background: #eee;
  }
`;
