import { useQuery } from "@tanstack/react-query";
import { storeQueryKeys } from "./queryKeys";
import { getCardConnect } from "@apis/endpoints/stores";
import { getCheckedEarlypaid } from "@apis/endpoints/stores";

export const useFetchCardConnect = () => {
  return useQuery({
    queryKey: storeQueryKeys.fetchCardConnect().queryKey,
    queryFn: () => getCardConnect(),
    retry: 0,
  });
};

export const useFetchCheckedEarlypaid = (storeId: number) => {
  return useQuery({
    queryKey: storeQueryKeys.fetchCheckedEarlypaid(storeId).queryKey,
    queryFn: () => getCheckedEarlypaid(storeId),
    enabled: !!storeId,
    retry: 0,
  });
};
