import { getDayOfWeek, getFormattedDateWithDots } from "@utils/date";
import { ImageTypes } from "@components/atoms/Image/type";
import {
  CardCode,
  DeliveryAdCode,
  DeliveryCode,
  DepositorCode,
  PlatformNames,
  EarlypaysEtcCode,
  UnderDepositDetailInfoTypes,
  DeliveryCodeTypes,
  DeliveryAdCodeTypes,
} from "@earlypay/shared/typings";

export const getSalesDateTime = (value: string) => {
  const date = new Date(value);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = getDayOfWeek(value);
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const result = `${month}.${day}(${week}) ${hour}:${minutes}`;

  return result;
};

export const changeSettlementCodeToImage = (
  value:
    | CardCode
    | DeliveryCodeTypes
    | DeliveryAdCodeTypes
    | "UNKNOWN"
    | "ETC"
    | string,
) => {
  switch (value) {
    case DeliveryCode.BAEMIN:
      return ImageTypes.SETTLEMENT_BAEMIN;
    case DeliveryAdCode.BAEMIN_ONE:
      return ImageTypes.SETTLEMENT_BAEMIN_ONE;
    case DeliveryCode.YOGIYO:
      return ImageTypes.SETTLEMENT_YOGIYO;
    case DeliveryCode.COUPANG:
      return ImageTypes.SETTLEMENT_COUPANG_EATS;
    case CardCode.KB:
      return ImageTypes.SETTLEMENT_LOGO_KB;
    case CardCode.BC:
      return ImageTypes.SETTLEMENT_LOGO_BC;
    case CardCode.HANA:
      return ImageTypes.SETTLEMENT_LOGO_HANA;
    case CardCode.SAMSUNG:
      return ImageTypes.SETTLEMENT_LOGO_SAMSUNG;
    case CardCode.SHINHAN:
      return ImageTypes.SETTLEMENT_LOGO_SHINHAN;
    case CardCode.HYUNDAI:
      return ImageTypes.SETTLEMENT_LOGO_HYUNDAI;
    case CardCode.LOTTE:
      return ImageTypes.SETTLEMENT_LOGO_LOTTE;
    case CardCode.NH:
      return ImageTypes.SETTLEMENT_LOGO_NH;
    case CardCode.WOORI:
      return ImageTypes.SETTLEMENT_LOGO_WOORI;
    case "ETC":
      return ImageTypes.SETTLEMENT_ETC;
    case "UNKNOWN":
      return ImageTypes.SETTLEMENT_QUESTION_MARK_IN_CIRCLE;
    case "BAEMIN_1_CLICK":
      return ImageTypes.SETTLEMENT_BAEMIN_ONE;
    case "BAEMIN_CLICK":
      return ImageTypes.SETTLEMENT_BAEMIN;
    case "TIME_DEAL":
      return ImageTypes.SETTLEMENT_YOGIYO;
    case "UNDER_DEPOSIT_REPAYMENT":
      return ImageTypes.SETTLEMENT_MONEYBAG_WARNING;
    default:
      return ImageTypes.SETTLEMENT_ETC;
  }
};

export const changeTypeToImage = (depositor: DepositorCode, type: string) => {
  if (type === "UNDER_DEPOSIT") {
    return ImageTypes.SETTLEMENT_MONEYBAG_WARNING;
  }

  switch (depositor) {
    case DepositorCode.ETC:
      if (type === EarlypaysEtcCode.SERVICE_FEE) {
        return ImageTypes.SETTLEMENT_RECEIPT_EARLYPAY;
      }
      if (type === EarlypaysEtcCode.FINANCIAL_INTEREST) {
        return ImageTypes.SETTLEMENT_RECEIPT_MONEYBAG;
      }
      if (type === EarlypaysEtcCode.ETC_UNDER_DEPOSIT) {
        return ImageTypes.SETTLEMENT_MONEYBAG_WARNING;
      }
      return ImageTypes.SETTLEMENT_MONEYBAG_GREEN;
    case DepositorCode.CARD:
      return ImageTypes.SETTLEMENT_CARD_ANGLE;
    case DepositorCode.BAEMIN:
      return ImageTypes.SETTLEMENT_BAEMIN;
    case DepositorCode.YOGIYO:
      return ImageTypes.SETTLEMENT_YOGIYO;
    case DepositorCode.COUPANG:
      return ImageTypes.SETTLEMENT_COUPANG_EATS;
    default:
      return ImageTypes.SETTLEMENT_ETC;
  }
};

/** 차감금 상세내역에서 광고기간을 반환합니다. */
export const getAdDuration = (startDate: string, endDate: string) => {
  const start = getFormattedDateWithDots(startDate).slice(3);
  const end = getFormattedDateWithDots(endDate).slice(3);

  return `${start}~${end}`;
};

/** 차감금 상세 내역의 항목 타이틀을 반환합니다. (e.g. 우리가게클릭) */
export const getAmountTitle = (
  type: string,
  detail: UnderDepositDetailInfoTypes,
) => {
  const isCardType = Object.values(CardCode).includes(type as CardCode);
  const cardName = `${PlatformNames[type]}카드`;

  if (detail) {
    const name = detail.name.replace(/배달의민족\s*/g, "");
    return name;
  }
  if (isCardType) {
    return cardName;
  }

  if (type === "ETC") return "기타 미상환금";

  return "";
};
