import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";

import {
  ActionButton,
  Box,
  Image,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import { useAuth } from "@/hooks/useAuth";

const Auth = () => {
  const { loading, handleCertificate } = useAuth(
    "/service-apply/license-verify",
  );

  trackPageViewMixpanel("본인 인증");

  return (
    <>
      <Meta title={`얼리페이 | 서비스 신청`} />
      <Header previous={"/service-apply/sales-type"}></Header>

      <PageContainer spacing={30}>
        <VStack spacing={4}>
          <Text typo={"subtitle-1"} bold>
            {"서비스를 이용하려면\n본인 인증이 필요해요"}
          </Text>
          <Text color={"content-secondary"}>
            대표자 명의의 핸드폰으로만 인증이 가능해요.
          </Text>
        </VStack>

        <Box width={"100%"} center>
          <Image
            src={"/service/id-verification.svg"}
            alt={"얼리페이 본인 인증"}
            width={240}
            height={240}
          />
        </Box>
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"인증하기"}
        onClickPrimaryButton={handleCertificate}
        loading={loading}
        primaryButtonProperty={{
          description: "본인인증 인증 버튼",
        }}
      />
    </>
  );
};

export default Auth;
