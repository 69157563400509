import styled from "styled-components";
import { Button } from "@components/commons/index";

export const IndexButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: ${(props: { _type: string }) =>
    props._type === "banner" ? "0px" : "16px 20px"};
  font-size: 18px;
  border-radius: 0;

  &:not(:first-child) {
    border-top: ${(props: { [_disabled: string]: string }) =>
      props._disabled && "1px solid #ddd"};
  }
`;

export const IndexTitle = styled.h3`
  color: #98a0a4;
  font-size: 14px;
  font-weight: 500;
`;

export const ImageBanner = styled.img`
  width: 100%;
  display: block;
`;

export const PrivacyPolicyButton = styled.a`
  padding: 16px 20px;
  text-decoration: none;
`;
