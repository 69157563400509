import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";

import { Container, Image, PageContainer, Text, VStack } from "@earlybird/ui";
import { SocialLoginButton } from "@earlybird/ui";

import { Meta } from "@components/commons";

import { useLogin } from "@/hooks/useLogin";

const Login = () => {
  const { getKakaoLoginUrl } = useLogin();

  trackPageViewMixpanel("얼리페이 로그인");

  return (
    <Container>
      <Meta title="얼리페이 | 로그인" />
      <PageContainer
        padding={"74px 20px 20px 20px"}
        justify={"between"}
        height={"100%"}
      >
        <VStack spacing={30} align={"center"} height={"100%"}>
          <Text typo={"title-3"} bold center>
            {"이제, 기다리지 말고\n365 얼리페이"}
          </Text>
          <Image
            width={240}
            height={240}
            src={"/service/delivery-card-money.png"}
            alt={"이제, 기다리지 말고 365일 얼리페이"}
          />
        </VStack>

        <SocialLoginButton as={"a"} link={getKakaoLoginUrl()} type={"KAKAO"} />
      </PageContainer>
    </Container>
  );
};

export default Login;
