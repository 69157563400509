import {
  ServiceApplyCode,
  ServiceApplyCodeTypes,
  ServiceApplyPath,
} from "../typings";

export function changePathToServiceApplyCode(
  pathname: string
): ServiceApplyCodeTypes | undefined {
  const entry = Object.entries(ServiceApplyPath).find(
    ([_, path]) => path === pathname
  );
  return entry ? (entry[0] as ServiceApplyCodeTypes) : undefined;
}

export function changeServiceApplyCodeToPath(
  code: ServiceApplyCodeTypes
): string {
  return ServiceApplyPath[code];
}

// 서비스 신청서 단계를 체크하여 대부 서비스에 해당하는 단계인지 판별하는 함수입니다.
export function checkEpayloanStage(code: ServiceApplyCodeTypes) {
  // 얼리페이 서비스에 해당하는 서비스 신청 단계입니다.
  const earlypayStage = [
    ServiceApplyCode.INTRO,
    ServiceApplyCode.SALES_TYPE,
    ServiceApplyCode.USER_AUTH,
    ServiceApplyCode.BUSINESS_AUTH,
    ServiceApplyCode.AGREE_SERVICE,
  ];

  return !earlypayStage.includes(code);
}

// 서비스 신청서 단계를 체크하여 얼리페이 서비스에 해당하는 단계인지 판별하는 함수입니다.
export function checkEarlypayStage(code: ServiceApplyCodeTypes) {
  // 얼리페이 서비스에 해당하는 서비스 신청 단계입니다.
  const epayloanStage = [
    ServiceApplyCode.GATEWAY,
    ServiceApplyCode.AGREE_CREDIT,
    ServiceApplyCode.ID_CARD,
    ServiceApplyCode.DEPOSIT_ACCOUNT,
    ServiceApplyCode.WITHDRAWAL_ACCOUNT,
    ServiceApplyCode.DELIVERY_ACCOUNT,
    ServiceApplyCode.DELIVERY_AGENT,
    ServiceApplyCode.VAN_IMAGE,
    ServiceApplyCode.AGREE_LOAN,
    ServiceApplyCode.SCREENING,
  ];

  return !epayloanStage.includes(code);
}
