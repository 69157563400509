import React from "react";

import { Calendar } from "@pages/calendar";
import { SystemError } from "@pages/errors";
import { Home } from "@pages/home";
import Logout from "@pages/serviceApply/Logout";
import { serviceApplyRouters } from "@routes/routers/serviceApplyRouters";

import MainRouteGuard from "../../guards/MainRouteGuard";
import { myRouters } from "./myRouters";
import { settlementRouters } from "./settlementRouters";

export const protectedRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <MainRouteGuard />,
  children: [
    { index: true, Component: Home },
    {
      ...serviceApplyRouters,
    },
    {
      path: "settlement",
      children: settlementRouters,
    },
    {
      path: "deposits",
      children: settlementRouters,
    },
    {
      path: "calendar",
      Component: Calendar,
    },
    {
      path: "my",
      children: myRouters,
    },
    {
      path: "logout",
      Component: Logout,
    },
  ],
};
