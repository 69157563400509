import theme from "@styles/theme";
import styled from "styled-components";

export const EventPopupWrapper = styled.div`
  width: 100%;

  animation: showLandingEventPopup 500ms ease-in;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
  margin-top: -${(props: { _height: number }) => props._height}px;
  opacity: ${(props: { _height: number }) => (props._height === 0 ? 0 : 1)};
  position: relative;

  @keyframes showLandingEventPopup {
    to {
      margin-top: 0px;
    }
  }
`;

export const CountDownWrapper = styled.div`
  position: absolute;
  height: ${(props: { _height: number }) => props._height * 0.4}px;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: ${theme.color.white_00};
    font-weight: bold;
    padding-top: 2px;
  }

  div {
    margin: 0 2px 0 6px;
    background: ${theme.color.black_20};
    border-radius: 4px;
    padding: 2px 4px;

    color: #ffe500;
  }

  @media screen and (min-width: 500px) {
    p,
    div {
      font-size: ${theme.px[22]};
    }

    div {
      border-radius: 6px;
      padding: 3px 8px;
    }
  }
`;
