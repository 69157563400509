import React, { useEffect } from "react";
import {
  EarlyPayLogo,
  FirstWave,
  SecondaryWave,
  SpinnerBackground,
} from "./index.styled";

const Spinner = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <SpinnerBackground>
      <EarlyPayLogo>
        <FirstWave />
        <SecondaryWave />
      </EarlyPayLogo>
    </SpinnerBackground>
  );
};

export default Spinner;
