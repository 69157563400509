import React, { useState } from "react";
import theme from "@styles/theme";
import styled from "styled-components";
import { SCREEN_MAX_WIDTH } from "@commons/constants";

const HelpButtonWrapper = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 4px;
`;

const QuestionmarkButton = styled.button`
  display: inline;
  background: #bbb;
  color: #fff;
  border-radius: 50%;
  font-weight: bold;
  font-size: ${(props: { _size: string }) => props._size + "px"};
  height: ${(props: { _size: string }) => props._size + 4 + "px"};
  width: ${(props: { _size: string }) => props._size + 4 + "px"};
`;

const HelpTextModal = styled.span`
  position: absolute;
  top: calc(100% + 10px);
  box-sizing: border-box;
  padding: 10px;
  width: 80vw;
  border-radius: 10px;
  transition: all 0.15s;
  background: ${theme.color.gray_00};
  color: ${theme.color.black_20};
  left: ${(props: { _modalLeftMargin: number }) =>
    props._modalLeftMargin + "px"};
  opacity: ${(props: { _isModal: boolean }) => (props._isModal ? 1 : 0)};
  z-index: ${(props: { _isModal: boolean }) => (props._isModal ? 1 : 0)};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.3);
  text-align: start;
  font-size: 13px;
  border: 1px solid #e9e9e9;
  max-width: ${SCREEN_MAX_WIDTH - 40}px;
`;

const Triangle = styled.span`
  display: inline-block;
  transition: all 0.15s;
  width: 0;
  height: 0;
  left: 0;
  top: calc(100% - 7px);
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid #eaeaea;
  transform: rotate(270deg);
  position: absolute;
  opacity: ${(props: { _isModal: boolean }) => (props._isModal ? 1 : 0)};
  z-index: ${(props: { _isModal: boolean }) => (props._isModal ? 2 : 0)};
`;

const ModalTitle = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
`;

interface HelpButtonInterface {
  _size: number;
  _title: string;
  _content: string;
}

const HelpButton = ({
  _size = 14,
  _title = "제목",
  _content = "내용",
}: HelpButtonInterface) => {
  const [isModal, setIsModal] = useState(false);

  const documentWidth = window.document.getElementById("root").offsetWidth;
  const modalWidth = document?.getElementById("help-modal")?.offsetWidth;
  const leftDistance = -document
    ?.getElementById("help-button")
    ?.getBoundingClientRect().left;
  const modalLeftMargin = (documentWidth - modalWidth) / 2;

  return (
    <HelpButtonWrapper>
      <QuestionmarkButton
        _size={_size}
        id={"help-button"}
        onClick={() => setIsModal(!isModal)}
      >
        ?
      </QuestionmarkButton>
      <Triangle _isModal={isModal} />
      <HelpTextModal
        id={"help-modal"}
        _modalLeftMargin={leftDistance + modalLeftMargin}
        _isModal={isModal}
        onClick={() => setIsModal(false)}
      >
        <ModalTitle>{_title}</ModalTitle>
        <span style={{ fontWeight: 400 }}>{_content}</span>
      </HelpTextModal>
    </HelpButtonWrapper>
  );
};

export default HelpButton;
