import React from "react";

import DaumPostcodeEmbed, { Address } from "react-daum-postcode";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

import { VStack } from "@earlybird/ui";

import { Header } from "@components/layouts";

import { daumPostCodeState } from "@/recoil/common/atoms";

interface DaumPostCodeModalProps {
  isOpen: boolean;
  closeModal: () => void;
  defaultValue?: string;
}

export const DaumPostCodeModal: React.FC<DaumPostCodeModalProps> = ({
  isOpen,
  closeModal,
  defaultValue,
}) => {
  const setRoadAddress = useSetRecoilState(daumPostCodeState);

  const handleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setRoadAddress({
      fullAddress: fullAddress,
      postCode: data.zonecode,
    });
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper>
      <VStack height={"100%"}>
        <Header
          hasChat={false}
          handleClickLeftButton={closeModal}
          style={{ position: "relative" }}
        />
        <DaumPostcodeEmbed
          defaultQuery={defaultValue}
          autoClose={false}
          onComplete={handleComplete}
          style={{ height: "100%" }}
        />
      </VStack>
    </Wrapper>
  );
};

export default DaumPostCodeModal;

const Wrapper = styled.div`
  position: fixed;
  background-color: #ececec;
  width: 100%;
  max-width: var(--earlybird-breakpoint-md);
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;
