/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import parse from "html-react-parser";
import {
  QNASectionWrapper,
  TabTitle,
  QNATabStack,
  QNAContentStack,
  CustomAccordion,
} from "./index.styles";
import Text from "@components/atoms/Text";
import { messageDummyData } from "./messageDummyData.mock";
import Accordion from "../../../atoms/Accordion";
import { FAQ_TITLE_LIST } from "@/commons/constants";
import { FontTypes } from "@/@types/ThemesType";
import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

export const QNASection = () => {
  const [tabTitle, setTabTitle] = useState<string>("정산");

  const handleClickTab = (title: string) => {
    setTabTitle(title);
  };

  return (
    <QNASectionWrapper tabTitle={tabTitle}>
      <Stack direction={StackDirectionsTypes.ROW}>
        <Text
          className="earlypay-ana-title"
          tag={FontTypes.TITLE_2}
          weight="SEMI_BOLD"
        >
          자주 묻는{" "}
        </Text>
        <Text
          className="earlypay-ana-title"
          tag={FontTypes.TITLE_2}
          weight="SEMI_BOLD"
          color="CONTENT_HIGHLIGHT"
        >
          질문
        </Text>
      </Stack>

      <QNATabStack>
        {FAQ_TITLE_LIST.map((item, index) => (
          <TabTitle key={index} onClick={() => handleClickTab(item)}>
            <Text
              className="qna-tab-title"
              as="h4"
              tag="BODY_2"
              color={tabTitle === item ? "CONTENT_PRIMARY" : "CONTENT_TERTIARY"}
              underlined={tabTitle === item}
            >
              {item}
            </Text>
          </TabTitle>
        ))}
      </QNATabStack>

      <QNAContentStack>
        {FAQ_TITLE_LIST.map((title: string, idx: number) => (
          <CustomAccordion key={idx} disabled={tabTitle !== title}>
            {Object.values(messageDummyData[title]).map(
              (items: { label: string; content: string }, index: number) => {
                return (
                  <Accordion key={index} label={items.label}>
                    <Text
                      className="accordion-content-text"
                      tag={FontTypes.BODY_2}
                      color="CONTENT_SECONDARY"
                    >
                      {parse(items.content)}
                    </Text>
                  </Accordion>
                );
              },
            )}
          </CustomAccordion>
        ))}
      </QNAContentStack>
    </QNASectionWrapper>
  );
};

export default QNASection;
