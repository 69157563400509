/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
// apis
// component(base)
import { ChartCard } from "@components/modules/sales/TimeChart/index.styled";
// chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartInterface } from "../SalesTitle";
import { subMonths } from "date-fns";
// dateFns
import { format, lastDayOfMonth } from "date-fns";
// styled
import theme from "@styles/theme";
import { HelpButton } from "../../common";
import { FlexBox } from "@components/commons";
import { getSalesAvg, getServiceInfo } from "@apis/endpoints";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const AvgChart = ({ _currentMonth, _storeId }: ChartInterface) => {
  const [chartData, setChartData] = useState([]);
  const [earlypay, setEarlypay] = useState({ year: 0, month: 0 });

  useEffect(() => {
    if (!_storeId) return;
    (async function getAvgChart() {
      const startDate = format(subMonths(_currentMonth, 3), "yyyy-MM-01");
      const endDate = format(lastDayOfMonth(_currentMonth), "yyyy-MM-dd");

      const sale = await getSalesAvg({
        start_date: startDate,
        end_date: endDate,
        store_id: _storeId,
      });
      const { data } = await getServiceInfo(_storeId);

      const date = data?.results[0]?.firstEarlypayDate.split("-") ?? [];
      const yearMonth: any = { year: date[0], month: date[1] };
      setEarlypay(yearMonth);
      setChartData([...sale]);
    })();
  }, [_currentMonth, _storeId]);

  // ---------------------------------- chart option ---------------------------------
  const options: any = {
    responsive: true,
    plugins: {
      tooltip: {
        intersect: false,
        callbacks: {
          label: (context: any) => {
            const title = context.dataIndex === 4 ? "예상매출" : "매출";

            const sale =
              title + " : " + context.parsed.y.toLocaleString() + "원";
            return sale;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        afterDataLimits: (scale: { max: number }) => {
          scale.max = scale.max * 1.1;
        },
        afterTickToLabelConversion: function (q: any) {
          for (const tick in q.ticks) {
            const label = Math.floor(q.ticks[tick].value / 10000);
            q.ticks[tick].label = label + "만";
          }
        },
      },
    },
  };

  // set chart data
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "총 매출 데이터",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  });

  // ---------------------------------- get chart ---------------------------------
  useEffect(() => {
    let labels = chartData.map(x => `${Number(x.month.split("-")[1])}월`);
    let data = chartData.map(x => x.totalSales);
    const firstDate = Number(earlypay.year + earlypay.month);

    const earlypayDate = chartData.filter(x => {
      const date = Number(x.month.split("-")[0] + x.month.split("-")[1]);
      if (date === firstDate) return x;
    });

    if (labels.length < 4) {
      let extraLables: any = [];

      if (earlypayDate.length > 0) {
        for (let i = 0; i < 4 - labels.length; i++) {
          extraLables = [
            ...extraLables,
            `${subMonths(_currentMonth, 3 - i).getMonth() + 1}월`,
          ];
        }
        labels = [...extraLables, ...labels];
      } else {
        for (let i = 0; i < 4 - labels.length; i++) {
          extraLables = [
            `${subMonths(_currentMonth, i).getMonth() + 1}월`,
            ...extraLables,
          ];
        }
        labels = [...labels, ...extraLables];
      }
    }

    if (data.length < 4) {
      let extraData: any = [];
      for (let i = 0; i < 4 - data.length; i++) {
        extraData = [0, ...extraData];
      }
      if (earlypayDate.length > 0) {
        data = [...extraData, ...data];
      } else {
        data = [...data, ...extraData];
      }
    }

    const extraMonth = Number(labels[labels.length - 1]?.split("월")[0]);

    const montData = {
      labels: [...labels, `${(extraMonth !== 12 ? extraMonth : 0) + 1}월`],
      datasets: [
        {
          label: "총 매출 데이터",
          data: [...data, (data[data.length - 1] + data[data.length - 2]) / 2],
          backgroundColor: [
            "#CDE1FF",
            "#CDE1FF",
            "#CDE1FF",
            theme.color.blue_00,
            "#ccc",
          ],
          borderRadius: "8",
        },
      ],
    };
    setData(montData);
    return;
  }, [chartData]);

  const totalSalesAvg: number = chartData.reduce(function add(sum, currValue) {
    return sum + currValue.totalSales;
  }, 0);

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <ChartCard>
      <FlexBox _row>
        <h3>{_currentMonth.getMonth() + 1}월 총 매출 및 예상매출 </h3>
        <HelpButton
          _size={11}
          _title="예상매출이란?"
          _content="얼리페이에서 집계한 3개월분의 매출과 이번달 매출의 통계값을 구하여 다음달 예상매출을 알려드립니다."
        />
      </FlexBox>
      <p>
        최근 4개월 평균매출은{" "}
        <span style={{ color: theme.color.blue_00 }}>
          {(totalSalesAvg / 4).toLocaleString()}원
        </span>{" "}
        입니다.
      </p>
      <Bar options={options} data={data} />
    </ChartCard>
  );
};

export default AvgChart;
