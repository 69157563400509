import { StoreTypes } from "@earlypay/shared/typings";

import { PricePolicyPayload } from "@apis/endpoints/stores/types";
import { instance } from "@apis/instance";

/**
 * 유저의 선정산 시작 여부를 확인하는 API 함수입니다.
 */
export const getCheckedEarlypaid = async (storeId: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/stores/${storeId}/check-earlypaid`,
  });

  return data.earlypaid;
};

/**
 * 유저의 카드 연동 상태 정보를 가져오는 함수입니다.
 */
export const getCardConnect = async () => {
  const { data } = await instance({
    method: "get",
    url: `/v1/stores/card-connect`,
  });

  return data.results;
};

/**
 * 사업자등록증 이미지를 전달하여 OCR 인증을 진행 후, 저장하는 API 호출 함수입니다.
 * @param payload
 * @param storeId
 */
export const patchStore = async (storeId: number, payload: StoreTypes) => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/stores/${storeId}`,
    data: payload,
  });

  return data;
};

export const getServiceInfo = async (storeId: number) => {
  const { data } = await instance({
    method: "get",
    url: `v1/stores/service-info/`,
    data: { store_id: storeId },
  });

  return data;
};
