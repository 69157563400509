import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";

import { Meta } from "@components/layouts";
// component
import { Header } from "@components/modules/common/index";
import { Faq } from "@components/modules/landing/index";

const MyFaq = () => {
  trackPageViewMixpanel("자주 묻는 질문");

  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} />
      <Header _white _backHistory="/my">
        자주 묻는 질문
      </Header>
      <MainOldLayout _padding>
        <Faq _landing={false} _tab={true} />
      </MainOldLayout>
    </>
  );
};

export default MyFaq;
