import type {
  EarlypayInterface,
  DeliveryDepositType,
  CardDepositType,
  EarlypayServiceFeeType,
  OverDepositsType,
  ExtraPaymentsType,
} from "@/@types/depositType";

import type {
  DeliveryPlatformSaleType,
  CardPlatformSaleType,
} from "@/@types/saleType";

interface EarlypayKeyInterface {
  baemin: DeliveryDepositType;
  yogiyo: DeliveryDepositType;
  coupang: DeliveryDepositType;
  card: CardDepositType;
  earlypayServiceFee: EarlypayServiceFeeType;
  baeminMeetSale?: {
    [key: string]: number;
    meetCard: number;
    meetCardCount: number;
    meetCash: number;
    meetCashCount: number;
  };
  yogiyoMeetSale?: {
    [key: string]: number;
    meetCard: number;
    meetCardCount: number;
    meetCash: number;
    meetCashCount: number;
  };
}

interface ExtraKeyInterface {
  extraPayments: ExtraPaymentsType;
  overDeposits: OverDepositsType;
}

interface TotalEarlypayInterface extends EarlypayKeyInterface {
  extraPayments: ExtraPaymentsType;
  overDeposits: OverDepositsType;
  totalEarlypayAmount: number;
  totalAggregationTargetAmount: number;
}

interface EarlypayTargetSaleKeyInterface {
  baemin: DeliveryPlatformSaleType;
  yogiyo: DeliveryPlatformSaleType;
  card: CardPlatformSaleType;
}

export const calculateDeposit = (payload: EarlypayInterface) => {
  // 선정산 init 객체
  const earlypay: EarlypayKeyInterface = {
    baemin: {
      fee: 0,
      feeCount: 0,
      deposit: 0,
      depositCount: 0,
      ceoDcCount: 0,
      ceoDc: 0,
      deliveryAgentFee: 0,
      deliveryAgentFeeCount: 0,
    },
    yogiyo: {
      fee: 0,
      feeCount: 0,
      deposit: 0,
      depositCount: 0,
      ceoDcCount: 0,
      ceoDc: 0,
      deliveryAgentFee: 0,
      deliveryAgentFeeCount: 0,
    },
    coupang: {
      fee: 0,
      feeCount: 0,
      deposit: 0,
      depositCount: 0,
      ceoDcCount: 0,
      ceoDc: 0,
      deliveryAgentFee: 0,
      deliveryAgentFeeCount: 0,
    },
    card: {
      fee: 0,
      feeCount: 0,
      deposit: 0,
      depositCount: 0,
    },
    earlypayServiceFee: {
      fee: 0,
      interest: 0,
      underDeposit: 0,
    },
    baeminMeetSale: {
      meetCard: 0,
      meetCardCount: 0,
      meetCash: 0,
      meetCashCount: 0,
    },
    yogiyoMeetSale: {
      meetCard: 0,
      meetCardCount: 0,
      meetCash: 0,
      meetCashCount: 0,
    },
  };

  // 추가정산금 init 객체
  const extra: ExtraKeyInterface = {
    extraPayments: {
      extraEarlypay: 0,
      extraOverdeposit: 0,
      replaceOverdeposit: 0,
      etc: 0,
    },
    overDeposits: {
      overDeposit: 0,
      paidOverDeposit: 0,
      repayUnderdeposit: 0,
    },
  };

  const extraPaymentsDepositList = Object.values(
    payload.extraEarlypay.extraPayments,
  );
  const overDepositsDepositList = Object.values(
    payload.extraEarlypay.overDeposits,
  );
  const baeminDepositList = Object.values(payload.earlypay.baemin);
  const yogiyoDepositList = Object.values(payload.earlypay.yogiyo);
  const coupangDepositList = Object.values(payload.earlypay.coupang);
  const cardDepositList = Object.values(payload.earlypay.card);
  const earlypayServiceFeeList = Object.values(
    payload.earlypay.earlypayServiceFee,
  );

  baeminDepositList.forEach((deposit, idx) => {
    if (!deposit) return;
    Object.keys(earlypay.baemin).map(depositKey => {
      earlypay.baemin[depositKey] =
        earlypay.baemin[depositKey] + baeminDepositList[idx][depositKey];
    });
    Object.keys(earlypay.yogiyo).map(depositKey => {
      earlypay.yogiyo[depositKey] =
        earlypay.yogiyo[depositKey] + yogiyoDepositList[idx][depositKey];
    });
    Object.keys(earlypay.coupang).map(depositKey => {
      earlypay.coupang[depositKey] =
        earlypay.coupang[depositKey] + coupangDepositList[idx][depositKey];
    });
    Object.keys(earlypay.card).map(depositKey => {
      earlypay.card[depositKey] =
        earlypay.card[depositKey] + cardDepositList[idx][depositKey];
    });
    Object.keys(earlypay.earlypayServiceFee).map(depositKey => {
      earlypay.earlypayServiceFee[depositKey] =
        earlypay.earlypayServiceFee[depositKey] +
        earlypayServiceFeeList[idx][depositKey];
    });
  });

  extraPaymentsDepositList.forEach((deposit, idx) => {
    if (!deposit) return;
    Object.keys(extra.extraPayments).map(extraKey => {
      extra.extraPayments[extraKey] =
        extra.extraPayments[extraKey] + extraPaymentsDepositList[idx][extraKey];
    });
    Object.keys(extra.overDeposits).map(extraKey => {
      extra.overDeposits[extraKey] =
        extra.overDeposits[extraKey] + overDepositsDepositList[idx][extraKey];
    });
  });

  if (payload.yesterdaySale ?? null) {
    const baeminYesterdaySaleList = Object.values(payload.yesterdaySale.baemin);
    const yogiyoYesterdaySaleList = Object.values(payload.yesterdaySale.yogiyo);

    baeminYesterdaySaleList.forEach((sale, idx) => {
      if (!sale) return;
      Object.keys(earlypay.baeminMeetSale).map(saleKey => {
        earlypay.baeminMeetSale[saleKey] =
          earlypay.baeminMeetSale[saleKey] +
          baeminYesterdaySaleList[idx][saleKey];
      });
      Object.keys(earlypay.yogiyoMeetSale).map(saleKey => {
        earlypay.yogiyoMeetSale[saleKey] =
          earlypay.yogiyoMeetSale[saleKey] +
          yogiyoYesterdaySaleList[idx][saleKey];
      });
    });
  }

  // 배달의민족
  const baeminDeposit = earlypay.baemin.deposit;
  const baeminFee = earlypay.baemin.fee;
  const baeminCeoDc = earlypay.baemin.ceoDc;
  const baeminDeliveryAgentFee = earlypay.baemin.deliveryAgentFee;

  // 요기요
  const yogiyoDeposit = earlypay.yogiyo.deposit;
  const yogiyoFee = earlypay.yogiyo.fee;
  const yogiyoCeoDc = earlypay.yogiyo.ceoDc;
  const yogiyoDeliveryAgentFee = earlypay.yogiyo.deliveryAgentFee;

  // 쿠팡이츠
  const coupangDeposit = earlypay.coupang.deposit;
  const coupangFee = earlypay.coupang.fee;
  const coupangCeoDc = earlypay.coupang.ceoDc;
  const coupangDeliveryAgentFee = earlypay.coupang.deliveryAgentFee;

  // 카드
  const cardDeposit = earlypay.card.deposit;
  const cardFee = earlypay.card.fee;

  // 총 수수료
  const EarlypayFee = earlypay.earlypayServiceFee.fee;
  const EarlypayInterest = earlypay.earlypayServiceFee.interest;
  const EarlypayUnderDeposit = earlypay.earlypayServiceFee.underDeposit;

  // 총 선정산금
  const totalEarlypayAmount =
    baeminDeposit +
    yogiyoDeposit +
    coupangDeposit +
    cardDeposit -
    (EarlypayFee + EarlypayInterest + EarlypayUnderDeposit);

  // 선정산 대상 집계 매출
  const totalAggregationTargetAmount =
    totalEarlypayAmount +
    baeminFee +
    baeminCeoDc +
    baeminDeliveryAgentFee +
    yogiyoFee +
    yogiyoCeoDc +
    yogiyoDeliveryAgentFee +
    coupangFee +
    coupangCeoDc +
    coupangDeliveryAgentFee +
    cardFee +
    EarlypayFee +
    EarlypayInterest +
    EarlypayUnderDeposit;

  // 총 선정산 객체 (선정산 + 추가정산금 + 총 정산 집계 대상 매출 + 총 선정산금)
  const totalEarlypay: TotalEarlypayInterface = {
    ...earlypay,
    ...extra,
    totalEarlypayAmount: totalEarlypayAmount,
    totalAggregationTargetAmount: totalAggregationTargetAmount,
  };

  return totalEarlypay;
};

export const calculateTargetSale = (payload: EarlypayInterface) => {
  const earlypayTargetSale: EarlypayTargetSaleKeyInterface = {
    card: {
      totalSales: 0,
      totalSalesCount: 0,
      sales: 0,
      salesCount: 0,
      cancelSales: 0,
      cancelSalesCount: 0,
    },
    baemin: {
      totalSales: 0,
      totalSalesCount: 0,
      sales: 0,
      salesCount: 0,
      meetCard: 0,
      meetCardCount: 0,
      meetCash: 0,
      meetCashCount: 0,
    },
    yogiyo: {
      totalSales: 0,
      totalSalesCount: 0,
      sales: 0,
      salesCount: 0,
      meetCard: 0,
      meetCardCount: 0,
      meetCash: 0,
      meetCashCount: 0,
    },
  };

  const baeminSaleList = Object.values(payload.sales.baemin);
  const yogiyoSaleList = Object.values(payload.sales.yogiyo);
  const cardSaleList = Object.values(payload.sales.card);

  baeminSaleList.forEach((sale, idx) => {
    if (!sale) return;
    Object.keys(earlypayTargetSale.baemin).map(saleKey => {
      earlypayTargetSale.baemin[saleKey] =
        earlypayTargetSale.baemin[saleKey] + baeminSaleList[idx][saleKey];
    });
    Object.keys(earlypayTargetSale.yogiyo).map(saleKey => {
      earlypayTargetSale.yogiyo[saleKey] =
        earlypayTargetSale.yogiyo[saleKey] + yogiyoSaleList[idx][saleKey];
    });
    Object.keys(earlypayTargetSale.card).map(saleKey => {
      earlypayTargetSale.card[saleKey] =
        earlypayTargetSale.card[saleKey] + cardSaleList[idx][saleKey];
    });
  });

  return earlypayTargetSale;
};

export const calculateCalendarEarlypay = (payload: EarlypayInterface) => {
  const saleKeyList = Object.keys(payload.sales.baemin);
  const depositKeyList = Object.keys(payload.earlypay.baemin);

  const baeminSales = Object.values(payload.sales.baemin);
  const yogiyoSales = Object.values(payload.sales.yogiyo);
  const cardSales = Object.values(payload.sales.card);

  const baeminDeposit = Object.values(payload.earlypay.baemin);
  const yogiyoDeposit = Object.values(payload.earlypay.yogiyo);
  const coupangDeposit = Object.values(payload.earlypay.coupang);
  const cardDeposit = Object.values(payload.earlypay.card);
  const earlypayServiceFee = Object.values(payload.earlypay.earlypayServiceFee);

  const extraPaymentsDepositList = Object.values(
    payload.extraEarlypay.extraPayments,
  );
  const overDepositsDepositList = Object.values(
    payload.extraEarlypay.overDeposits,
  );

  const monthSaleList = baeminSales.map((item, index) => {
    if (!item) return;

    const totalEarlypay =
      baeminSales[index].totalSales +
      yogiyoSales[index].totalSales +
      cardSales[index].totalSales -
      baeminSales[index].meetCard -
      yogiyoSales[index].meetCard;
    const dailySale = { date: saleKeyList[index], price: totalEarlypay };

    return dailySale;
  });

  const monthDepositList = baeminDeposit.map((item, index) => {
    if (!item) return;

    const totalEarlypay =
      baeminDeposit[index].deposit +
      yogiyoDeposit[index].deposit +
      coupangDeposit[index].deposit +
      cardDeposit[index].deposit +
      extraPaymentsDepositList[index].extraOverdeposit +
      overDepositsDepositList[index].overDeposit -
      earlypayServiceFee[index].fee -
      earlypayServiceFee[index].interest -
      earlypayServiceFee[index].underDeposit;
    const dailyDeposit = { date: depositKeyList[index], price: totalEarlypay };

    return dailyDeposit;
  });

  return { monthDepositList, monthSaleList };
};
