import React, { useState } from "react";
import styled from "styled-components";
import { AdditionalDepositPlatformTypes } from "@earlypay/shared/typings";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import { getFormattedDateWithDots } from "@utils/date";
import { changeNumberToMoney } from "@utils/types";
import { changeSettlementCodeToImage } from "@components/templates/settlement/SettlementAmountList/utils";
import Image from "@components/atoms/Image";
import Skeleton from "react-loading-skeleton";
import EmptyAmountBox from "@components/organisms/EmptyAmountBox";
import { ImageTypes } from "@components/atoms/Image/type";
import BottomSheet from "@components/molecules/BottomSheet";
import Interaction from "@components/atoms/Interaction";

export interface AdditionalDepositDetailAmountListProps {
  list: AdditionalDepositPlatformTypes[];
  isLoading: boolean;
  date: string;
}

export const AdditionalDepositDetailAmountList: React.FC<
  AdditionalDepositDetailAmountListProps
> = ({ isLoading, list, date }) => {
  const [isOpenEtcInfo, setIsOpenEtcInfo] = useState(false);
  const [isOpenUnderDepositInfo, setIsOpenUnderDepositInfo] = useState(false);

  const handleOpenBottomSheet = (type: string) => {
    type === "ETC" && setIsOpenEtcInfo(true);
    type === "UNDER_DEPOSIT_REPAYMENT" && setIsOpenUnderDepositInfo(true);
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Skeleton width={100} height={22} />
        {[1, 2, 3, 4].map(index => (
          <CardWrapper key={index}>
            <TitleWrapper>
              <Skeleton containerClassName="flex-1" width={44} height={44} />
              <Skeleton containerClassName="flex-1" width={120} height={18} />
            </TitleWrapper>
            <Skeleton containerClassName="flex-1" width={140} height={18} />
          </CardWrapper>
        ))}
      </Wrapper>
    );
  }

  if (!list?.length) {
    return <EmptyAmountBox label={"상세내역이 없습니다."} />;
  }

  return (
    <Wrapper>
      {isOpenEtcInfo && (
        <BottomSheet
          headerText={"기타에는 이런 게 포함돼요"}
          primaryButtonText={"확인"}
          onClose={() => setIsOpenEtcInfo(false)}
        >
          <Text tag={FontTypes.BODY_2} color={ColorsTypes.CONTENT_SECONDARY}>
            지급된 선정산금보다 회수된 금액이 많아 추가로 입금해 드리는
            금액으로, 당일 18시 전후로 입금됩니다.
          </Text>
        </BottomSheet>
      )}

      {isOpenUnderDepositInfo && (
        <BottomSheet
          headerText={"미상환금 회수란?"}
          primaryButtonText={"확인"}
          onClose={() => setIsOpenUnderDepositInfo(false)}
        >
          <Text tag={FontTypes.BODY_2} color={ColorsTypes.CONTENT_SECONDARY}>
            기타 추가입금이 발생한 경우 지급 전 잔여 미상환금을 회수한
            내역입니다.
          </Text>
        </BottomSheet>
      )}

      <Text color={ColorsTypes.CONTENT_SECONDARY} bold>
        {getFormattedDateWithDots(date)}
      </Text>
      {list.map((items, index) => {
        return (
          <CardWrapper key={index}>
            <TitleWrapper>
              <CustomImage
                tag={changeSettlementCodeToImage(items.type)}
                alt={"추가입금 플랫폼 이미지"}
                width={44}
                height={44}
              />
              {items.type === "ETC" ||
              items.type === "UNDER_DEPOSIT_REPAYMENT" ? (
                <Interaction>
                  <CustomButton
                    onClick={() => handleOpenBottomSheet(items.type)}
                  >
                    <Text
                      tag={FontTypes.BODY_2}
                      color={ColorsTypes.CONTENT_SECONDARY}
                      bold
                      underlined
                    >
                      {items.name}
                    </Text>
                  </CustomButton>
                </Interaction>
              ) : (
                <Text
                  tag={FontTypes.BODY_2}
                  color={ColorsTypes.CONTENT_SECONDARY}
                  bold
                >
                  {items.name}
                </Text>
              )}
            </TitleWrapper>
            <Text
              tag={FontTypes.BODY_2}
              color={ColorsTypes.CONTENT_SECONDARY}
              bold
            >
              {changeNumberToMoney(items.amount)}
            </Text>
          </CardWrapper>
        );
      })}
    </Wrapper>
  );
};

export default AdditionalDepositDetailAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CustomImage = styled(Image)`
  width: 44px;
  height: 44px;
  border-radius: 16px;
  border: ${({ tag }: { tag: string }) =>
    tag === ImageTypes.SETTLEMENT_COUPANG_EATS ? "1px solid #F4F4F4" : "none"};
`;

const CustomButton = styled.button`
  background: transparent;
`;
