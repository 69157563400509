import { atom } from "recoil";
import { v1 } from "uuid";

export const userState = atom({
  key: `UserState/${v1()}`,
  default: {
    id: 0,
    username: "",
    name: "",
    email: "",
    phone: "",
    mobile_carrier: "",
    gender: "",
    birthdate: "",
    profile_image: "",
    is_certified: false,
    is_foreigner: false,
    recommender_code: "",
    _store: {
      id: 0,
      user: 0,
      title: "",
      address: "",
      detail_address: "",
      business_registration_number: "",
      service_type: "",
      inflow_medium: "",
      inflow_path: "",
      inflow_campaign: null,
      _kn_bank_account: {
        bank: "",
        account: "",
        is_ars_authenticated: false,
      },
      price_policy: 0,
    },
    _bank_account: {
      id: 0,
      bank: "",
      account: "",
    },
    created: "",
  },
});
