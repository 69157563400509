export const getS3TermsFile = (fileKey: string): string => {
  return `${import.meta.env.VITE_S3_URI}/terms/current/${fileKey}`;
};

export const addUserInfoToTerms = (
  username: string,
  date: string,
  signature: string,
  htmlContent: string,
) => {
  let updatedHtmlContent = htmlContent;

  if (username && !updatedHtmlContent.includes(`신청자 : ${username}`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /신청자\s*:/g,
      `신청자 : ${username}`,
    );
  }

  if (date && !updatedHtmlContent.includes(`신청일 : ${date}`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /신청일\s*:/g,
      `신청일 : ${date}`,
    );
  }

  if (signature && !updatedHtmlContent.includes(`id="earlypay-signature"`)) {
    updatedHtmlContent = updatedHtmlContent.replace(
      /\(인\)/g,
      `
    <span id="earlypay-signature" style="position: relative; display: inline-block; vertical-align: middle;">
        (인)
        <img src="${signature}" alt="signature" style="display: inline-block; vertical-align: middle; width: 60px; height: auto; margin-left: -40px;">
      </span>
  `,
    );
  }

  return updatedHtmlContent;
};
