// component(base)
import { FlexBox } from "@components/commons/index";
import theme from "@styles/theme";
// style
import styled from "styled-components";

export const TitleBox = styled.div`
  position: absolute;
  width: 100%;
  top: ${(props: { [key: string]: boolean }) =>
    props._toggle ? "97px" : `calc(100% - 170px)`};
  transition: all 0.3s;
  left: 0;
  z-index: 1;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 40px;
  padding: 5px 20px 57px 20px;
  height: calc(100% - 103px);
  box-shadow: 0px -10px 12px 5px rgba(0, 0, 0, 0.05);
`;

export const TotalAmountBox = styled.div`
  padding: 0px 18px;
  border-radius: 6px;
  margin-bottom: 40px;
`;

export const AmountText = styled(FlexBox)`
  justify-content: space-between;
  padding: 10px 0;

  &:not(:first-child) {
    border-top: 1px solid ${theme.color.blue_00};
  }
`;

export const ArrowButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export const ScrollBox = styled.div`
  width: 100%;
  height: calc(100vh - 190px);
  overflow: ${(props: { [key: string]: boolean }) =>
    props._toggle ? "scroll" : "none"};
  border-radius: 3px;
`;
