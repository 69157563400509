import GuestRouteGuard from "../guards/GuestRouteGuard";
import { Landing } from "@pages/landing";
import { Login } from "@pages/login";
import React from "react";
import { SystemError } from "@pages/errors";
import MainLayout from "@routes/layouts/MainLayout";

export const guestRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <GuestRouteGuard />,
  children: [
    {
      index: true,
      path: "landing",
      element: <Landing />,
    },
    {
      path: "Login",
      element: <Login />,
    },
  ],
};
