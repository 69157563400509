// style
import styled from "styled-components";
// component
import { Text } from "@components/commons/index";

export const CountCardBox = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 11px;
  margin-top: 24px;
  box-shadow: 0px 0px 10px 0px #00000020;
  position: relative;
`;

export const TitleBox = styled(Text)`
  border-radius: 4px;
  padding: 4px 6px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const BeforeEarlypaidBox = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;
