import { modalCode } from "@earlypay/shared/typings";
import { openChannelIOChat } from "@earlypay/shared/configs";
import { ConfirmModalOptionsType } from "@ui/components/atoms/ConfirmModal/ConfirmModal.types";

// TODO: services 마다 각 modal.maps.ts 를 가지고 있을 수 있도록 maps 파일을 분리할 예정입니다.
export const ConfirmModalMaps: ConfirmModalOptionsType[] = [
  {
    id: modalCode.WARNING_UNDER_AGE_AUTH,
    state: "warning",
    title: "지금은 이용할 수 없어요.",
    contentItem: "text",
    text: "만 19세 미만은 얼리페이를\n이용할 수 없어요. 2024년 5월 9일 이후에\n다시 가입해주세요",
    primaryButtonLabel: "나중에 다시 오기",
  },
  {
    id: modalCode.ERROR_UNKNOWN,
    state: "error",
    title: "알 수 없는 오류가 발생했어요.\n고객센터로 문의해주세요.",
    primaryButtonLabel: "문의하기",
    secondaryButtonLabel: "닫기",
    onClickPrimaryButton: openChannelIOChat,
  },
  {
    id: modalCode.ERROR_MAX_RETRY_EXCEEDED,
    state: "error",
    title: "시도 횟수가 초과됐어요.\n고객센터로 문의해주세요.",
    primaryButtonLabel: "문의하기",
    secondaryButtonLabel: "닫기",
    onClickPrimaryButton: openChannelIOChat,
  },
  {
    id: modalCode.WARNING_INVALID_OCR_BUSINESS_LICENSE,
    state: "warning",
    contentItem: "text",
    title: "사업자등록증 인식이 불가해요",
    text: "모든 정보가 선명하게 보이는 사진으로\n다시 첨부해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_INVALID_DOC_TYPE_BUSINESS_LICENSE,
    state: "error",
    contentItem: "text",
    title: "사업자등록증명이 아닌\n사업자등록증을 다시 첨부해주세요",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_ONLINE_BUSINESS_LICENSE,
    state: "warning",
    contentItem: "text",
    title: "사업자등록증명이 아닌\n사업자등록증을 다시 첨부해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_DUPLICATE_BUSINESS_LICENSE,
    state: "error",
    contentItem: "text",
    title: "양도 또는 인수받은 사업장인가요?\n맞다면 고객센터로 문의해주세요",
    secondaryButtonLabel: "닫기",
    primaryButtonLabel: "문의하기",
    onClickPrimaryButton: openChannelIOChat,
  },
  {
    id: modalCode.WARNING_INVALID_OCR,
    state: "warning",
    contentItem: "text",
    title: "추출된 정보가 맞는지 확인해주세요",
    text: "계속해서 정확한 정보가 추출되지 않으면\n고객센터로 문의해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.CONFIRM_DELETE_BAEMIN_ACCOUNT,
    state: "confirm",
    contentItem: "text",
    title: "배달의민족 계정을 삭제할까요?",
    text: "삭제하면 배달의민족 매출은 선정산에\n포함되지 않아요. (추후 고객선터를 통해 등록 가능해요)",
    primaryButtonLabel: "삭제하기",
    secondaryButtonLabel: "취소",
  },
  {
    id: modalCode.CONFIRM_DELETE_YOGIYO_ACCOUNT,
    state: "confirm",
    contentItem: "text",
    title: "요기요 계정을 삭제할까요?",
    text: "삭제하면 요기요 매출은 선정산에\n포함되지 않아요. (추후 고객선터를 통해 등록 가능해요)",
    primaryButtonLabel: "삭제하기",
    secondaryButtonLabel: "취소",
  },
  {
    id: modalCode.CONFIRM_DELETE_COUPANG_ACCOUNT,
    state: "confirm",
    contentItem: "text",
    title: "쿠팡이츠 계정을 삭제할까요?",
    text: "삭제하면 쿠팡이츠 매출은 선정산에\n포함되지 않아요. (추후 고객선터를 통해 등록 가능해요)",
    primaryButtonLabel: "삭제하기",
    secondaryButtonLabel: "취소",
  },
  {
    id: modalCode.ERROR_ONLINE_BUSINESS_LICENSE,
    state: "error",
    contentItem: "text",
    title: "배달앱 또는 오프라인 매장에서\n발생한 카드 매출이 있으신가요?",
    text: "전자상거래(쿠팡,네이버스토어 등)로\n발생한 매출은 선정산이 어려워요.",
    primaryButtonLabel: "네, 있어요",
    secondaryButtonLabel: "둘 다 없어요",
  },
  {
    id: modalCode.WARNING_INVALID_IMAGE_FILE,
    state: "warning",
    contentItem: "info",
    title: "아래 조건에 맞는 사진을\n다시 첨부해주세요",
    info: ["첨부 파일 용량 : 10MB 이하", "유형 : JPG, JPEG, PNG"],
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_FAILED_IDENTIFICATION,
    state: "warning",
    contentItem: "text",
    title:
      "인식에 실패했어요.\n모든 정보가 잘 보이는 가로 방향의\n신분증을 첨부해주세요.",
    primaryButtonLabel: "다시 첨부하기",
  },
  {
    id: modalCode.WARNING_DIFFERENT_IDENTIFICATION,
    state: "warning",
    contentItem: "text",
    title: "본인 인증 정보와 일치하지 않는\n신분증이에요.",
    primaryButtonLabel: "다시 첨부하기",
  },
  {
    id: modalCode.WARNING_DIFFERENT_BUSINESS_LICENSE,
    state: "warning",
    contentItem: "text",
    title: "본인 명의의 사업자등록증으로 진행해주세요",
    text: "본인 명의의 사업장이 맞다면 모든 정보가\n정확하게 보이도록 다시 촬영해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.TEXT_SKIP_AGENCY,
    state: "error",
    contentItem: "text",
    title: "배달 대행사 등록 단계를\n건너뛰시겠어요?",
    text: "해당 단계를 건너뛰면 배달앱의 만나서 카드 결제에\n대한 선정산을 받기 어려워요.",
    primaryButtonLabel: "네",
    secondaryButtonLabel: "아니요",
  },
  {
    id: modalCode.TEXT_SKIP_CARD_TERMINAL,
    state: "error",
    contentItem: "text",
    title: "카드 단말기 등록 단계를\n건너뛰시겠어요?",
    text: "해당 단계를 넘어가면 카드 매출에\n대한 선정산을 받기 어려워요.",
    primaryButtonLabel: "네",
    secondaryButtonLabel: "아니요",
  },
  {
    id: modalCode.WARNING_INVALID_USER_ACCOUNT,
    state: "warning",
    contentItem: "text",
    title: "다른 사람의 계좌로는\n입금이 어려워요",
    text: "사장님 명의의 계좌를 입력해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_INVALID_WITHDRAWAL_ACCOUNT,
    state: "warning",
    contentItem: "text",
    title: "사장님 명의의 경남은행\n계좌로 다시 진행해주세요",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_FAILED_WITHDRAWAL_ACCOUNT_ARS,
    state: "warning",
    contentItem: "text",
    title: "인증이 완료되지 않았어요",
    text: "번호를 입력하지 않았거나 잘못 입력하였어요.\n전화 인증을 다시 시도해주세요.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.WARNING_DUPLICATE_ACCOUNT_ARS,
    state: "warning",
    contentItem: "text",
    title: "이미 인증이 완료된 계좌입니다.",
    primaryButtonLabel: "확인",
  },
  {
    id: modalCode.ERROR_RETRY_SERVICE_APPLY,
    state: "error",
    contentItem: "text",
    title: "고객센터에 새로운 신청서를\n작성하겠다는 요청을 남겨주세요",
    text: "요청 후 처리에는 다소 시간이 걸릴 수\n있으니 양해 부탁드려요.",
    primaryButtonLabel: "요청하기",
    secondaryButtonLabel: "닫기",
    onClickPrimaryButton: openChannelIOChat,
  },
  {
    id: modalCode.ERROR_LOGOUT,
    state: "error",
    title: "로그아웃 하시겠습니까?",
    contentItem: "text",
    text: "로그아웃하더라도 지금까지 입력하신 정보는 저장돼요.",
    primaryButtonLabel: "로그아웃",
    secondaryButtonLabel: "뒤로",
  },
];
