/**
 * 쿠폰날짜를 체크하여 상태를 확인합니다
 * @param start 시작날짜
 * @param end 종료날짜
 * @returns {
      state: 'using / hold / done',
      color: string,
      text: '사용중 / 사용예정 / 사용완료',
      textColor: string,
      line: 'none / none / line-through',
      bgColor: '#FFDEE1',
    }
 */
export const checkCouponState = (start: string, end: string) => {
  const today = new Date().getTime();
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();

  if (startDate <= today && today <= endDate) {
    return {
      state: "using",
      color: "#DD0920",
      text: "사용중",
      textColor: "#ffffff",
      line: "none",
      bgColor: "#FFDEE1",
    };
  } else if (today < startDate) {
    return {
      state: "hold",
      color: "#E8E8E8",
      text: "사용 예정",
      textColor: "#707070",
      line: "none",
    };
  } else if (endDate < today) {
    return {
      staet: "done",
      color: "#585F6A",
      text: "사용 완료",
      textColor: "#ffffff",
      line: "line-through",
      bgColor: "#F7F7F7",
    };
  }
};
