import { EarlypaysEtcCodeTypes } from "@earlypay/shared";

import {
  GetAdditionalDepositParams,
  GetEarlypaysEtcDetailParams,
  GetEarlypaysEtcParams,
  GetEarlypaysParams,
  GetEarlypaysSummaryParams,
  GetTotalSalesParams,
  GetUnderDepositsParams,
  GetUnderDepositsSummaryParams,
} from "@apis/endpoints/earlypay/types";
import { instance } from "@apis/instance";

export const getEarlypaySummary = async (params: {
  start_date: string;
  end_date: string;
  store_id: number;
  page: string;
}) => {
  try {
    const { data } = await instance({
      method: "get",
      url: `/v1/earlypay/summary/`,
      params: { ...params },
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getExtraPaymentSummary = async (params: {
  start_date: string;
  end_date: string;
  store_id: number;
}) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/extra-payments/summary/`,
    params: { ...params },
  });

  return data;
};

export const getOverDeposit = async (params: {
  gte: string;
  lte: string;
  store_id?: number;
}) => {
  const { data } = await instance({
    method: "get",
    url: `v1/earlypay/over_deposit/`,
    params: { ...params },
  });

  return data.data;
};

export const getUnderDeposit = async (params: {
  gte: string;
  lte: string;
  store_id?: number;
}) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/stores/underdeposit-repayment-range/`,
    params: { ...params },
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 그 외 내역 중, 추가입금 상세 정보를 가져오는 함수입니다.
 * @param params
 */
export const getAdditionalDeposit = async (
  params: GetAdditionalDepositParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/etc/ADDITIONAL_DEPOSIT/detail`,
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 내역을 가져오는 함수입니다.
 * @param params
 */
export const getEarlypays = async (params: GetEarlypaysParams) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/earlypays`,
    params: params,
  });

  return data.items;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 그 외 정보를 가져오는 함수입니다.
 * @param params
 */
export const getEarlypaysEtc = async (params: GetEarlypaysEtcParams) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/etc`,
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 그 외 상세 정보를 가져오는 함수입니다.
 * @param params
 * @param type
 */
export const getEarlypaysEtcDetail = async (
  params: GetEarlypaysEtcDetailParams,
  type: EarlypaysEtcCodeTypes,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/etc/${type}`,
    params: params,
  });

  return data.items;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산 내역을 가져오는 함수입니다.
 * @param params
 */
export const getEarlypaysSummary = async (
  params: GetEarlypaysSummaryParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/earlypays/summary`,
    params: params,
  });

  return data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 총 매출 정보를 가져오는 함수입니다.
 * @param params
 */
export const getTotalSales = async (params: GetTotalSalesParams) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/total-sales`,
    params: params,
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 차감금 정보를 가져오는 함수입니다.
 * @param params
 */
export const getUnderDeposits = async (params: GetUnderDepositsParams) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/under-deposits`,
    params: params,
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 선정산에 대한 차감금 요약 정보를 가져오는 api 함수입니다.
 * @param params
 */
export const getUnderDepositsSummary = async (
  params: GetUnderDepositsSummaryParams,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/earlypay/under-deposits/summary`,
    params: params,
  });

  return data.data;
};
