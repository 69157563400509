import React from "react";

import { useNavigate } from "react-router-dom";

import { Container, ErrorScreen, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

const Forbidden = () => {
  const navigate = useNavigate();

  const handleGoToPrevious = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Meta title={`Forbidden`} />
      <Header hasLeftButton={false} />
      <PageContainer>
        <ErrorScreen type={"403"} handleGoToPrevious={handleGoToPrevious} />
      </PageContainer>
    </Container>
  );
};

export default Forbidden;
