import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import { subDays } from "date-fns";

import { Text, Logo } from "@components/commons/index";
import {
  CalendarSpinner,
  SpinerPosition,
} from "@components/modules/sales/CalendarCell/index.styled";

import { formatPlatformSaleData } from "@/utils/formatEarlypay";
import { options } from "./chartOption";
import type { SalesInterface } from "@/@types/saleType";
import { useFetchApplication, useFetchCheckedEarlypaid } from "@apis/hooks";
import { getFormattedDateWithDash } from "@utils/date";
import { getSalesSummary } from "@apis/endpoints";

const BeforeEarlypaidBox = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

Chart.register(...registerables);

const SaleChart = () => {
  const { data: application } = useFetchApplication();
  const { data: earlypaid } = useFetchCheckedEarlypaid(application.store.id);

  const today = new Date().getDay();
  const lastWeekSunday = subDays(new Date(), today + 7);
  const lastWeekSaturday = subDays(new Date(), today + 1);
  const thisWeekSunday = subDays(new Date(), today);
  const thisWeekToday = subDays(new Date(), 0);

  const [thisWeekSales, setThisWeekSales] = useState([]);
  const [lastWeekSales, setLastWeekSales] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const calculateAllSale = (saleData: SalesInterface) => {
    const cardTotalSales = Object.values(saleData.card).map(
      data => data.totalSales,
    );
    const baeminTotalSales = Object.values(saleData.baemin).map(
      data => data.totalSales,
    );
    const baeminTotalMeetCardSales = Object.values(saleData.baemin).map(
      data => data.meetCard,
    );
    const yogiyoTotalSales = Object.values(saleData.yogiyo).map(
      data => data.totalSales,
    );
    const yogiyoTotalMeetCardSales = Object.values(saleData.yogiyo).map(
      data => data.meetCard,
    );

    const totalSales = cardTotalSales.map((sale, index) => {
      return (
        sale +
        baeminTotalSales[index] +
        yogiyoTotalSales[index] -
        baeminTotalMeetCardSales[index] -
        yogiyoTotalMeetCardSales[index]
      );
    });

    return totalSales;
  };

  const getWeekGraphData = async (
    lastWeekSunday: string,
    lastWeekSaturday: string,
    thisWeekSunday: string,
    thisWeekToday: string,
  ) => {
    setIsLoading(true);

    try {
      const lastWeekSaleData = await getSalesSummary({
        start_date: lastWeekSunday,
        end_date: lastWeekSaturday,
        store_id: application.store.id,
      });

      const currentWeekSaleData = await getSalesSummary({
        start_date: thisWeekSunday,
        end_date: thisWeekToday,
        store_id: application.store.id,
      });
      const lastWeekSale = formatPlatformSaleData(
        lastWeekSunday,
        lastWeekSaturday,
        lastWeekSaleData,
      );
      const currentWeekSale = formatPlatformSaleData(
        thisWeekSunday,
        thisWeekToday,
        currentWeekSaleData,
      );

      const lastWeekTotalSale = calculateAllSale(lastWeekSale);
      const currentWeekTotalSale = calculateAllSale(currentWeekSale);

      setLastWeekSales([...lastWeekTotalSale]);
      setThisWeekSales([...currentWeekTotalSale]);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!earlypaid) return;
    getWeekGraphData(
      getFormattedDateWithDash(lastWeekSunday),
      getFormattedDateWithDash(lastWeekSaturday),
      getFormattedDateWithDash(thisWeekSunday),
      getFormattedDateWithDash(thisWeekToday),
    );
  }, [earlypaid]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = {
    labels: ["일", "월", "화", "수", "목", "금", "토"],
    borderColor: "#fff",
    datasets: [
      {
        type: "line",
        label: "이번주 매출",
        borderColor: "#5165cc",
        backgroundColor: "rgba(170, 197, 255, 0.3)",
        fontColor: "#fff",
        borderWidth: 1,
        data: [...thisWeekSales],
        fill: true,
      },
      {
        type: "line",
        label: "저번주 매출",
        borderColor: "#aaa",
        backgroundColor: "rgba(170, 170, 170, 0.1)",
        borderWidth: 1,
        fill: true,
        data: [...lastWeekSales],
        borderDash: [5, 5],
      },
    ],
  };

  return (
    <>
      {isLoading && (
        <CalendarSpinner>
          <SpinerPosition>
            <Logo />
          </SpinerPosition>
        </CalendarSpinner>
      )}
      <Text _medium _style={{ padding: "6px", fontSize: "14px" }}>
        주간매출 비교
      </Text>
      <Line data={data} options={options} height={"230%"} />
      {!earlypaid && (
        <BeforeEarlypaidBox>최초 선정산 이후 집계됩니다</BeforeEarlypaidBox>
      )}
    </>
  );
};

export default SaleChart;
