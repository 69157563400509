import { instance } from "@apis/instance";

export const getFeedbackStatus = async () => {
  const { data } = await instance({
    method: "get",
    url: `/v2/cs/voc/exists`,
  });

  return data;
};

/**
 * VOC 제출을 위한 함수입니다.
 * @param payload
 */

export interface FeedbackStatusParams {
  category: string;
  evaluation_score: number;
  user_comment: string;
  store: number;
}

export const postFeedbackStatus = async (payload: FeedbackStatusParams) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/cs/voc`,
    data: payload,
  });
  return data;
};
