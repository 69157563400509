import { useLocation } from "react-router-dom";
import { useState } from "react";

const useTrackingUtm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referrerList = ["naver", "google", "daum", "earlypay"];
  const referrer = location.state?.referrer ?? document.referrer;

  const [utmSource, setUtmSource] = useState(
    queryParams.get("utm_source") || "direct",
  );
  const [utmMedium, setUtmMedium] = useState(
    queryParams.get("utm_medium") || "portal",
  );
  const [utmCampaign, setUtmCampaign] = useState(
    queryParams.get("utm_campaign") || "",
  );

  // utm 정보를 가져와서 검사 후, 플랫폼에 맞게 변환합니다.
  const convertUtmInfo = () => {
    // 0. 카카오 웰컴 메시지로 들어왔을 경우, utm 정보를 덮어쓰지 않고 제거합니다.
    // TODO: 이후 utm 정보가 history 로 쌓일 수 있게끔 서버 로직 변경이 된다면 해당 부분을 제거해야 합니다.
    if (utmMedium === "welcome") {
      return {
        utmSource,
        utmMedium,
        utmCampaign,
      };
    }

    // 1-1. 페이스북 utm 정보 저장
    if (utmSource.includes("fbclid")) {
      setUtmSource("facebook");
      setUtmMedium("campaign");
    }

    // 1-2. 핀크럭스 utm 정보 저장
    if (utmSource.includes("pinkey")) {
      setUtmSource("pincrux");
      setUtmMedium(utmSource.replace("pinkey=", ""));
    }

    // 2. src params 에 utm 정보가 없고 회원가입 상태인 경우, referrer or direct 저장
    if (utmSource === "") {
      // 2-1. src params 에 utm 정보가 없을 경우 direct 기본 저장
      setUtmSource("direct");
      setUtmMedium("portal");
      setUtmCampaign("");

      // 2-2. referrer 에 포털 사이트가 있는지 확인. 값 존재하면 utm 정보 저장
      referrerList.forEach(site => {
        referrer.includes(site) && setUtmSource(site);
      });
    }

    return {
      utmSource,
      utmMedium,
      utmCampaign,
    };
  };

  return {
    convertUtmInfo,
  };
};

export default useTrackingUtm;
