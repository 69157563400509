import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@redux/store";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { getTodayEarlypayThunk } from "@redux/modules/deposit";

import { Text, Span } from "@components/commons/index";
import { HelpButton } from "@components/modules/common";
import {
  DeopositsBox,
  DepositRoutingButton,
  DepositTextBox,
  AmountBox,
} from "./index.styled";
import theme from "@styles/theme";

import { useGetTodayEarlypay } from "@/hooks/useEarlypay";
import { errorToast } from "@/components/atoms/Toast";
import type { DateRangeParams } from "@/@types/commonType";
import { useFetchApplication } from "@apis/hooks";
import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";
import { ColorsTypes } from "@/@types/ThemesType";

// TODO: 오타 수정
const TodayDeopositsCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { data: application } = useFetchApplication();
  const { todayEarlypay } = useGetTodayEarlypay();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const storeId = application.store.id;
  const status = application.status;
  const todayEarlypayAmount =
    typeof todayEarlypay.todayEarlypayAmount === "number"
      ? todayEarlypay.todayEarlypayAmount.toLocaleString()
      : "선정산 집계중";
  const todayAggregationTargetAmount =
    typeof todayEarlypay.todayAggregationTargetAmount === "number"
      ? todayEarlypay.todayAggregationTargetAmount.toLocaleString()
      : "선정산 집계중";

  const spinnerColor = theme.color.black_20;

  const changePrice = (amount: number | string) => {
    if (status !== "APPROVED") {
      return "승인대기중";
    }
    return amount;
  };

  const goDepositPage = () => {
    if (status !== "APPROVED") {
      return errorToast("승인 후 이용하실수 있는 서비스입니다");
    }
    navigate("/settlement");
  };

  const getTodayEarlypay = async () => {
    if (!storeId) return;

    const today = new Date().toISOString().split("T")[0];

    setIsLoading(true);

    try {
      const params: DateRangeParams = {
        startDate: today,
        endDate: today,
        storeId,
        page: "home",
      };
      await dispatch(getTodayEarlypayThunk(params)).unwrap();
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTodayEarlypay();
  }, [application]);

  return (
    <DeopositsBox>
      <DepositTextBox>
        <AmountBox _row>
          <Text
            _center
            _style={{
              color: "#707070",
              fontSize: "14px",
              display: "flex",
            }}
          >
            정산 대상 집계 금액{" "}
            <HelpButton
              _size={11}
              _title="정산 대상 집계 금액이란?"
              _content="사장님의 어제 매출중 얼리페이 이상거래탐지 시스템이 탐지한 매출을 제외한 총 매출의 합입니다."
            />
          </Text>
          <Text _medium _style={{ color: "#707070", fontSize: "18px" }}>
            {isLoading ? (
              <BeatLoader size={"8px"} color={spinnerColor} />
            ) : (
              changePrice(todayAggregationTargetAmount)
            )}
            <span style={{ fontSize: "13px" }}> 원</span>
          </Text>
        </AmountBox>
        <AmountBox _row>
          <Text _center>오늘 선정산 금액</Text>
          <Text _medium _style={{ fontSize: "24px" }}>
            {isLoading ? (
              <BeatLoader size={"10px"} color={spinnerColor} />
            ) : (
              changePrice(todayEarlypayAmount)
            )}
            <span style={{ fontSize: "15px" }}> 원</span>
          </Text>
        </AmountBox>
      </DepositTextBox>
      <DepositRoutingButton _full _onClick={() => goDepositPage()}>
        정산금 내역 상세보기
        <Icon
          tag={IconTags.CHEVRON_RIGHT}
          color={ColorsTypes.CONTENT_ON_COLOR}
          size={"SM"}
        />
      </DepositRoutingButton>
    </DeopositsBox>
  );
};

export default TodayDeopositsCard;
