import {
  Intro,
  Auth,
  SalesType,
  UploadLicenseImage,
  CheckLicenseInfo,
  VerifyLicenseInfo,
  ServiceAgreement,
  VerifyLicenseResult,
  Submit,
} from "@pages/serviceApply";
import { SystemError } from "@pages/errors";
import ServiceApplyRouteGuard from "@routes/guards/ServiceApplyRouteGuard";
import React from "react";

export const serviceApplyRouters = {
  path: "/service-apply",
  errorElement: <SystemError />,
  element: <ServiceApplyRouteGuard />,
  children: [
    {
      index: true,
      Component: Intro,
    },
    {
      path: "intro",
      Component: Intro,
    },
    {
      path: "sales-type",
      Component: SalesType,
    },
    {
      path: "auth",
      Component: Auth,
    },
    {
      path: "license-verify",
      children: [
        { index: true, Component: UploadLicenseImage },
        { path: "upload", Component: UploadLicenseImage },
        { path: "check", Component: CheckLicenseInfo },
        { path: "verify", Component: VerifyLicenseInfo },
        { path: "result", Component: VerifyLicenseResult },
      ],
    },
    {
      path: "service-agreement",
      Component: ServiceAgreement,
    },
    {
      path: "screening",
      children: [
        {
          path: "restart",
        },
        {
          path: "incomplete",
        },
        {
          path: "expired",
        },
        {
          path: "admin-rejected",
        },
        {
          path: "credit-rejected",
        },
        {
          path: "retry",
        },
      ],
    },
    // --------- 대부 ---------
    {
      path: "gateway",
    },
    {
      path: "credit-agreement",
    },
    {
      path: "document-guide",
    },
    {
      path: "identification",
    },
    {
      path: "deposit-account",
    },
    {
      path: "withdrawal-account",
    },
    {
      path: "delivery-account",
    },
    {
      path: "delivery-agency",
    },
    {
      path: "card-terminal",
    },
    {
      path: "terms-agreement",
    },
    {
      path: "submit",
      Component: Submit,
    },
  ],
};
