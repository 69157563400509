import React, { useLayoutEffect } from "react";

import { ApplicationStatusCode, getCookie } from "@earlypay/shared";
import { applicationState } from "@recoil/application/atom";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useRedirectUsers } from "@apis/hooks";

/** 얼리페이에서 로그인이 필요한 페이지 전체를 가드하는 Route 컴포넌트입니다.
 */
const ProtectedMainRoute = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = getCookie("earlypay-token");
  const application = useRecoilValue(applicationState);
  const setApplication = useSetRecoilState(applicationState);

  const currentQuery = new URLSearchParams(location.search);
  const status = application.status; // 서비스 신청서 상태

  const now = new Date();
  const past = new Date(application.statusChangedAt);
  const timeDiff = now.getTime() - past.getTime();
  const hoursPassed = timeDiff / (1000 * 60 * 60);

  /** accessToken 이 없는 경우, 로그인 또는 랜딩 페이지로 이동합니다. */
  if (!accessToken) {
    return (
      <Navigate
        to={`/login?${currentQuery.toString()}`}
        replace
        state={{ from: location }}
      />
    );
  }

  const checkApplicationStatus = () => {
    if (
      !location.pathname.includes("submit") &&
      status === ApplicationStatusCode.WAITING_REVIEW
    ) {
      return navigate("/service-apply/submit", {
        state: { from: location },
      });
    }

    if (status === ApplicationStatusCode.NEED_COMPLEMENT) {
      return navigate("/service-apply/screening/incomplete");
    }

    if (status === ApplicationStatusCode.CANCELLED) {
      return navigate("/service-apply/screening/restart");
    }

    if (status === ApplicationStatusCode.EXPIRED) {
      return navigate("/service-apply/screening/expired");
    }

    // 승인 거절일 경우, credit-rejected 페이지로 이동합니다.
    if (status === ApplicationStatusCode.REJECTED_CREDIT) {
      if (hoursPassed < 24) {
        return navigate("/service-apply/screening/credit-rejected");
      }
    }

    // 승인 거절로부터 24시간이 지났을 경우, retry 페이지로 이동합니다.
    if (status === ApplicationStatusCode.REJECTED_CREDIT) {
      if (hoursPassed >= 24) {
        return navigate("/service-apply/screening/retry");
      }

      return;
    }

    if (status === ApplicationStatusCode.REJECTED_OPERATION) {
      return navigate("/service-apply/screening/admin-rejected");
    }

    return;
  };

  useLayoutEffect(() => {
    if (application.id) {
      setApplication(application);

      // 서비스 신청서 상태를 확인하고 심사 결과 페이지로 이동 여부를 결정합니다.
      checkApplicationStatus();

      // deposits -> settlement 로 리다이렉트 진행합니다.
      if (
        location.pathname === "/deposits" ||
        location.pathname === "/deposits/"
      ) {
        return navigate("/settlement");
      }
    }
  }, [application]);

  if (!application.id) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedMainRoute;
