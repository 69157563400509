import React from "react";

import { useNavigate } from "react-router-dom";

import { Container, ErrorScreen, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

const SystemError = () => {
  const navigate = useNavigate();

  const handleGoToPrevious = () => {
    navigate(-1);
  };

  return (
    <>
      <Meta title={`System Error`} />
      <Container>
        <Header hasLeftButton={false} />
        <PageContainer>
          <ErrorScreen type={"500"} handleGoToPrevious={handleGoToPrevious} />
        </PageContainer>
      </Container>
    </>
  );
};

export default SystemError;
