import React, { useEffect, useState } from "react";

import { subDays } from "date-fns";

import { FlexBox, Text } from "@components/commons/index";

import { getSalesSummary } from "@apis/endpoints";
import { useFetchApplication, useFetchCheckedEarlypaid } from "@apis/hooks";

import { BeforeEarlypaidBox, CountCardBox, TitleBox } from "./index.styled";

interface SaleCountInterface {
  baeminCount: number;
  yogiyoCount: number;
  cardCount: number;
  cancelCount: number;
}

interface PlatformCountInterface {
  type: string[];
  title: "요기요" | "카드" | "배민" | "취소";
  count: number;
  cardColor: string;
  textColor: string;
}

const CountCard = () => {
  const [saleCount, setSaleCount] = useState<SaleCountInterface>({
    baeminCount: 0,
    yogiyoCount: 0,
    cardCount: 0,
    cancelCount: 0,
  });

  const { data: application } = useFetchApplication();
  const { data: earlypaid } = useFetchCheckedEarlypaid(application.store.id);

  const serviceType: string = application.serviceType;
  const storeId: number = application.store.id;

  const getSaleCounts = async () => {
    if (!storeId) return;

    const yesterday = subDays(new Date(), 1).toISOString().split("T")[0];
    const yesterdaySale = await getSalesSummary({
      start_date: yesterday,
      end_date: yesterday,
      store_id: storeId,
    });
    // @ts-ignore
    const baeminCount = Object.values(yesterdaySale.baemin)[0]?.totalSalesCount; // @ts-ignore
    const yogiyoCount = Object.values(yesterdaySale.yogiyo)[0]?.totalSalesCount; // @ts-ignore
    const cardCount = Object.values(yesterdaySale.card)[0]?.totalSalesCount; // @ts-ignore
    const cancelCount = Object.values(yesterdaySale.card)[0]?.cancelSalesCount;

    setSaleCount({
      baeminCount: baeminCount,
      yogiyoCount: yogiyoCount,
      cardCount: cardCount,
      cancelCount: cancelCount,
    });
  };

  useEffect(() => {
    getSaleCounts();
  }, [storeId]);

  const countList: PlatformCountInterface[] = [
    {
      type: ["CA", "AL"],
      title: "카드",
      count: saleCount.cardCount ?? 0,
      cardColor: "rgba(153, 155, 247, 0.3)",
      textColor: "#0070FA",
    },
    {
      type: ["DE", "AL"],
      title: "배민",
      count: saleCount.baeminCount ?? 0,
      cardColor: "rgba(39, 201, 198, 0.3)",
      textColor: "#34D2DC",
    },
    {
      type: ["DE", "AL"],
      title: "요기요",
      count: saleCount.yogiyoCount ?? 0,
      cardColor: "rgba(249, 0, 80, 0.3)",
      textColor: "#F3004E",
    },
    {
      type: ["CA", "AL", "DE"],
      title: "취소",
      count: saleCount.cancelCount ?? 0,
      cardColor: "rgba(255, 0, 0, 0.3)",
      textColor: "#D12E2E",
    },
  ];

  const renderList = countList.filter(platform => {
    if (!platform.type.includes(serviceType)) return;
    return platform;
  });

  return (
    <CountCardBox>
      <Text _medium _style={{ padding: "20px", fontSize: "14px" }}>
        어제 주문 건수
      </Text>
      <FlexBox _row _style={{ paddingBottom: "20px" }}>
        {renderList.map(platform => (
          <FlexBox
            _justify
            _align
            _style={{ width: `${100 / renderList.length}%` }}
            key={platform.title}
          >
            <TitleBox _style={{ color: platform.textColor }}>
              {platform.title}
            </TitleBox>
            <Text _style={{ fontSize: "15px", color: "#333" }}>
              {platform.count}건
            </Text>
          </FlexBox>
        ))}
      </FlexBox>
      {!earlypaid && (
        <BeforeEarlypaidBox>최초 선정산 이후 집계됩니다</BeforeEarlypaidBox>
      )}
    </CountCardBox>
  );
};

export default CountCard;
