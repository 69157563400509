import React, { useState } from "react";

import { deleteCookie, setCookie } from "@earlypay/shared";
import { useNavigate } from "react-router-dom";

import { instance } from "@apis/instance";

import { errorToast } from "@/components/atoms/Toast";

import Presenter from "./index.presenter";

const Admin = () => {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState("");

  const tokenLogin = (userToken: string) => {
    try {
      deleteCookie("earlypay-token");
      setCookie("earlypay-token", userToken, 7);
      Object.assign(instance.defaults, {
        headers: { authorization: `token ${userToken}` },
      });
      navigate("/");
    } catch (err) {
      if (err?.response?.status === 502) {
        errorToast("현재 업데이트중입니다. 잠시후 다시 시도해주세요");
      }
      deleteCookie("earlypay-token");
      Object.assign(instance.defaults, { headers: { authorization: `` } });
      errorToast("잘못된 토큰입니다.");
      navigate("/500");
    }
  };

  return (
    <>
      <Presenter
        _userToken={userToken}
        _setUserToken={setUserToken}
        _tokenLogin={tokenLogin}
      />
    </>
  );
};

export default Admin;
