import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./DeliveryAccountViewList.module.scss";
import {
  DeliveryAccountViewListProps,
  PlatformListTypes,
} from "./DeliveryAccountViewList.types";
import "@ui/styles/index.scss";
import { HStack, Icon, Text, VStack } from "@ui/components/atoms";
import { ChevronRightIcon } from "@earlybird/icons";
import { DeliveryNames } from "@earlypay/shared/typings";

/**
 * `DeliveryAccountViewList` 는 등록된 배달앱 계정을 보여주는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <DeliveryAccountViewList
 * />
 * ```
 */
export const DeliveryAccountViewList = forwardRef<
  HTMLElement,
  DeliveryAccountViewListProps
>(function DeliveryAccountViewList(
  {
    className,
    as,
    platformList,
    handleClickPlatform,
    ...rest
  }: DeliveryAccountViewListProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "button";

  return (
    <VStack spacing={4}>
      {platformList.map((item: PlatformListTypes) => (
        <BaseComponent
          key={item.code}
          className={classNames(
            styles.DeliveryAccountViewList,
            "earlybird-delivery-account-view-list",
          )}
          onClick={() => handleClickPlatform(item.code)}
          type={"button"}
        >
          <Text bold>{DeliveryNames[item.code]}</Text>

          <HStack align={"center"} width={"fit-content"}>
            <Text color={"content-highlight"} bold>
              {item.value}
            </Text>
            <Icon
              size={"lg"}
              color={"content-tertiary"}
              icon={ChevronRightIcon}
            />
          </HStack>
        </BaseComponent>
      ))}
    </VStack>
  );
});

export default DeliveryAccountViewList;
