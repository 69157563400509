import { useNavigate } from "react-router-dom";

interface NavigateParams {
  pathname: string;
  query?: Record<string, string | number | boolean>;
  state?: Record<string, string | number | boolean>;
}

export const useNavigateWithParams = () => {
  const navigate = useNavigate();

  const buildQueryString = (
    query?: Record<string, string | number | boolean>,
  ): string => {
    if (!query) return "";

    return (
      "?" + new URLSearchParams(query as Record<string, string>).toString()
    );
  };

  const navigateWithParams = ({ pathname, query, state }: NavigateParams) => {
    const currentQuery = new URLSearchParams(location.search);
    const queryString = buildQueryString(query);

    if (!query) {
      return navigate(`${pathname}?${currentQuery.toString()}`, {
        state: state,
      });
    }

    return navigate(`${pathname}${queryString}`, { state: state });
  };

  return { navigateWithParams };
};

export default useNavigateWithParams;
