import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchStore } from "@apis/endpoints/stores";
import { ServerError, StoreTypes, modalCode } from "@earlypay/shared/typings";
import { sentryHook } from "@earlypay/shared/configs";
import { applicationsQueryKeys } from "@apis/hooks/applications/queryKeys";
import { useConfirmModal } from "@earlybird/ui";

export const useUpdateStore = (storeId: number) => {
  const queryClient = useQueryClient();
  const { openConfirmModal } = useConfirmModal();

  return useMutation({
    mutationFn: async (payload: StoreTypes) => patchStore(storeId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
      sentryHook({
        title: "가게 정보 업데이트에 실패했습니다.",
        error: error,
      });

      return error;
    },
    retry: 0,
  });
};
