import { createQueryKeys } from "@lukemorales/query-key-factory";

export const applicationsQueryKeys = createQueryKeys("applications", {
  fetchApplication: () => ["fetchApplication"],
  updateIdentification: (applicationId: number) => [
    "updateIdentification",
    applicationId,
  ],
  updateBusinessLicense: (applicationId: number) => [
    "updateBusinessLicense",
    applicationId,
  ],
});
