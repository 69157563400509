import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Icon from "src/components/atoms/Icon";
import {
  BackRouteButton,
  ChannelIOButton,
  HeaderStyle,
  Wrapper,
} from "./index.styled";
import { ButtonSizes, ButtonTypes } from "@components/atoms/Button/type";
import { useLogin } from "@/hooks/useLogin";
import { openChannelIOChat } from "@earlypay/shared/configs";

interface HeaderIndexType {
  _white?: boolean;
  _backHistory?: string;
  _logOut?: boolean;
  hasBackButton?: boolean;
  children?: React.ReactNode | string;
}

const Header = ({
  _white,
  hasBackButton = true,
  _backHistory,
  _logOut,
  children,
}: HeaderIndexType) => {
  const { handleLogout } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const isDisabledChannelIO =
    url.includes("/service-apply") ||
    url.includes(`/login`) ||
    url.includes("/submit");

  // 입력된 _backHistory 로 이동합니다. 로그아웃일 경우 landing 으로 이동합니다
  const backRoute = () => {
    if (_logOut) {
      handleLogout();
    }
    navigate(
      _backHistory
        ? `${_backHistory}${location.search}`
        : `/${location.search}`,
    );
  };

  return (
    <Wrapper>
      <HeaderStyle backgroundColor={_white} hasBackButton={hasBackButton}>
        {hasBackButton && (
          <BackRouteButton onClick={backRoute}>
            <Icon tag="ARROW_LEFT" size="MD" />
          </BackRouteButton>
        )}
        {children && <h2>{children}</h2>}
        <ChannelIOButton
          onClick={openChannelIOChat}
          disabled={!isDisabledChannelIO}
          width="auto"
          size={ButtonSizes.SM}
          tag={ButtonTypes.OUTLINED}
        >
          문의하기
        </ChannelIOButton>
      </HeaderStyle>
    </Wrapper>
  );
};

export default Header;
