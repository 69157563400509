import { createQueryKeys } from "@lukemorales/query-key-factory";

export const storeQueryKeys = createQueryKeys("store", {
  fetchCardConnect: () => ["fetchCardConnect"],
  fetchCheckedEarlypaid: (storeId: number) => [
    "fetchCheckedEarlypaid",
    storeId,
  ],
  updateStore: (storeId: number) => ["updateStore", storeId],
});
