// style
import styled from "styled-components";
// component(base)

export const TitleBox = styled.div`
  padding: 12px 0;
  div {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 2px;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    color: #1f1f1f;
    margin-bottom: 4px;
  }

  span {
    font-weight: 400;
    font-size: 10px;
    color: #a0a0a0;
  }
`;

export const TextBox = styled.div`
  background: #f9f9f9;
  font-weight: 300;
  font-size: 13px;
  padding: 16px 20px 30px 20px;

  h6 {
    font-weight: 300;
    &:first-child {
      margin-bottom: 14px;
    }
  }
`;

export const PolicyBox = styled.div`
  background: #f9f9f9;
  font-weight: 300;
  font-size: 13px;
  padding: 16px 20px 30px 20px;
  height: auto;
`;

export const PolicyText = styled.div`
  padding: 6px 0px;
`;
