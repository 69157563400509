import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./PageModal.module.scss";
import { PageModalProps } from "./PageModal.types";
import "@ui/styles/index.scss";
import { ArrowLeftIcon, WheelIcon } from "@earlybird/icons";
import { Text, Box, IconButton, HStack } from "@ui/components/atoms";
import { ChatButton } from "@ui/components/molecules";
import { PAGE_HEADER_HEIGHT } from "@ui/common/styles";
import { getPixelValue } from "@ui/utils/types";
import { openChannelIOChat } from "@earlypay/shared/configs";
import { changeSpacingToClassName } from "@ui/utils/className";

/**
 * `PageModal` 는 페이지 형태의 화면을 채우는 모달 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <PageModal
 *   visible={visible}
 *   onClose={onClose}
 * >
 * 타이틀
 * </PageModal>
 * ```
 */
export const PageModal = forwardRef<HTMLElement, PageModalProps>(
  function PageModal(
    {
      className,
      as,
      children,
      visible,
      align = "start",
      justify = "start",
      center,
      style,
      ...rest
    }: PageModalProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const [showAnimation, setShowAnimation] = useState(false);
    const BaseComponent = as ?? "div";

    const defaultStyle = {
      justifyContent: center ? "center" : justify,
      alignItems: center ? "center" : align,
    };

    // useEffect(() => {
    //   if (visible) {
    //     setShowAnimation(true);
    //   } else {
    //     const timeout = setTimeout(() => setShowAnimation(false), 250);
    //     return () => clearTimeout(timeout);
    //   }
    // }, [visible]);

    if (!visible) {
      return null;
    }

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.PageModal,
          visible ? styles["modal-open"] : styles["modal-close"],
          "earlybird-page-modal",
          className,
        )}
        style={{ ...style, ...defaultStyle }}
        {...rest}
      >
        {children}
      </BaseComponent>
    );
  },
);

export default PageModal;
