import React from "react";

// style
import styled from "styled-components";
import theme from "@styles/theme";

// component
import { Text } from "@components/commons/index";
import { IMG_URL } from "@/@types/commonType";
import { openChannelIOChat } from "@earlypay/shared/configs";

const MyFooter = () => {
  return (
    <FooterBox>
      <FooterContent onClick={openChannelIOChat}>
        <div>
          <Text _style={{ color: theme.color.gray_60, fontSize: "15px" }}>
            1:1 문의
          </Text>
          <Text
            _style={{
              color: theme.color.gray_30,
              fontSize: "13px",
              marginTop: "6px",
            }}
          >
            운영시간 / 평일 09:00 ~ 18:00
          </Text>
        </div>
        <img
          src={IMG_URL.icon.channelTok}
          alt="카카오 버튼 아이콘"
          width={36}
          height={36}
          style={{ marginRight: "6px" }}
        />
      </FooterContent>
      <a href="tel:1522-6911" style={{ textDecoration: "none" }}>
        <FooterContent>
          <div>
            <Text _style={{ color: theme.color.gray_60, fontSize: "15px" }}>
              고객센터
            </Text>
            <Text
              _style={{
                color: theme.color.gray_30,
                fontSize: "13px",
                marginTop: "6px",
              }}
            >
              운영시간 / 평일 09:00 ~ 18:00
            </Text>
          </div>
          <img
            src={IMG_URL.icon.phone}
            alt="카카오 버튼 아이콘"
            width={36}
            height={36}
            style={{ marginRight: "6px" }}
          />
        </FooterContent>
      </a>
    </FooterBox>
  );
};

export default MyFooter;

const FooterBox = styled.footer`
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  display: grid;
  gap: 10px;
  margin-top: auto;
`;

const FooterContent = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 24px 30px;
  border-bottom: 1px solid #ededed;
`;
