export const WEEKDAY = ["일", "월", "화", "수", "목", "금", "토"];

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {Date} e.g.YYYY-MM-DDT00:00:00+09:00
 */
const getTodayWithTimeZone = (value?: Date | string): Date => {
  const date = value ? new Date(value) : new Date();
  const currentYear = date.getFullYear();
  const currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  const currentDay = String(date.getDate()).padStart(2, "0");
  const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const [year, month, day] = currentDate.split("-").map(Number);

  return new Date(year, month - 1, day);
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.0000-0-0
 */
const getFormattedDateWithDash = (value?: Date | string): string => {
  const date = value ? new Date(value) : new Date();
  const currentYear = new Date(date).getFullYear().toString();
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = date.getDate().toString().padStart(2, "0");

  const customDate = `${currentYear}-${currentMonth}-${currentDay}`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.00.00.00
 */
const getFormattedDateWithDots = (value?: Date | string): string => {
  const date = value ? new Date(value) : new Date();
  const currentYear = new Date(date).getFullYear().toString().slice(-2);
  const currentMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const currentDay = date.getDate().toString().padStart(2, "0");

  const customDate = `${currentYear}.${currentMonth}.${currentDay}`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @param {Date | string} length 년도 자릿수
 * @returns {string} e.g.00년 00월 00일 또는 0000년 00월 00일
 */
const getFormattedDateKR = (value: Date | string, length?: number): string => {
  const date = new Date(value);
  const currentYear = new Date(date)
    .getFullYear()
    .toString()
    .slice(length === 2 ? -2 : 0);
  const currentMonth = new Date(date).getMonth() + 1;
  const currentDay = new Date(date).getDate();

  const customDate = `${currentYear}년 ${currentMonth}월 ${currentDay}일`;
  return customDate;
};

/** 특정 포맷 형태로 날짜를 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.00년 00월 00일 (0)
 */
const getFormattedDateFullKR = (value?: Date | string) => {
  const date = new Date(value);
  const currentYear = new Date(date).getFullYear().toString();
  const currentMonth = new Date(date).getMonth() + 1;
  const currentDay = new Date(date).getDate();
  const week = getDayOfWeek(value);

  const customDate = `${currentYear}년 ${currentMonth}월 ${currentDay}일 (${week})`;
  return customDate;
};

/** 특정 포맷 형태로  `요일`을 반환합니다.
 * @param {Date | string} value 날짜 객체
 * @returns {string} e.g.월
 */
const getDayOfWeek = (value?: Date | string): string => {
  const dayOfWeek = new Date(value).getDay();

  return WEEKDAY[dayOfWeek];
};

export {
  getTodayWithTimeZone,
  getFormattedDateKR,
  getFormattedDateFullKR,
  getFormattedDateWithDash,
  getFormattedDateWithDots,
  getDayOfWeek,
};
