/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import Slider from "react-slick";
import {
  ActiveDot,
  Dot,
  DotsWrapper,
  ImageWrapper,
  SliderWrapper,
} from "./index.styled";

export interface IntroSlide {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  slideRef: any;
}

export const IntroSlide: React.FC<IntroSlide> = ({
  currentIndex,
  setCurrentIndex,
  slideRef,
}) => {
  const reactSliderSettings = {
    dots: true,
    infinite: false,
    navigation: true,
    lazyLoad: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    speed: 500,
    swipeToSlide: true,
    appendDots: (dots: number) => <DotsWrapper>{dots}</DotsWrapper>,
    customPaging: (i: number) => (i === currentIndex ? <ActiveDot /> : <Dot />),
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    },
  };

  return (
    <SliderWrapper>
      <Slider {...reactSliderSettings} ref={slideRef}>
        <ImageWrapper>
          <Image
            className="earlypay-intro-image"
            tag={ImageTypes.SERVICE_APPLY_INTRO_1}
            alt={"복잡한 서류 없이 가입은 간편하게"}
            width={240}
            height={240}
          />
        </ImageWrapper>
        <ImageWrapper>
          <Image
            className="earlypay-intro-image"
            tag={ImageTypes.SERVICE_APPLY_INTRO_2}
            alt={"약정이나 위약금 없이 언제든지 자유롭게"}
            width={240}
            height={240}
          />
        </ImageWrapper>
        <ImageWrapper>
          <Image
            className="earlypay-intro-image"
            tag={ImageTypes.SERVICE_APPLY_INTRO_3}
            alt={"첫 달은 부담없이 무료로 써보고 결정하세요"}
            width={240}
            height={240}
          />
        </ImageWrapper>
      </Slider>
    </SliderWrapper>
  );
};

export default IntroSlide;
