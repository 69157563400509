import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./BottomSheet.module.scss";
import { BottomSheetProps } from "./BottomSheet.types";
import "@ui/styles/index.scss";
import {
  BoxButton,
  HStack,
  IconButton,
  Box,
  Text,
  VStack,
} from "@ui/components/atoms";
import { XIcon } from "@earlybird/icons";
import { getPixelValue } from "@ui/utils/types";
import { useWindowSize } from "@earlypay/shared/hooks";

/**
 * `BottomSheet` 는 페이지를 이탈하지 않고 집중이 필요한 중요 정보를 표시하거나 복잡한 정보 입력을 요청할 시 사용 하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <BottomSheet
 *   title={"타이틀"}
 *   headerType={"contentTitle"}
 *   buttonType={"single"}
 *   primaryButtonLabel={"contentTitle"}
 *   disabled={disabled}
 *   onClose={onClose}
 *   visible={true}
 * >
 *   <Box>
 *     <Text>{ ... }</Text>
 *   </Box>
 * </BottomSheet>
 * ```
 */
export const BottomSheet = forwardRef<HTMLElement, BottomSheetProps>(
  function BottomSheet(
    {
      className,
      children,
      as,
      headerType,
      buttonType,
      title = "타이틀",
      primaryButtonLabel = "레이블",
      onClickPrimaryButton,
      secondaryButtonLabel = "레이블",
      onClickSecondaryButton,
      disabled,
      onClose,
      visible,
      loading,
      type = "button",
      ...rest
    }: BottomSheetProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const windowSize = useWindowSize();

    const Overlay = "div";
    const BaseComponent = as ?? "div";

    const innerHeight =
      buttonType === "none"
        ? getPixelValue(windowSize.height - 104)
        : buttonType === "duo-vertical"
          ? getPixelValue(windowSize.height - 248)
          : getPixelValue(windowSize.height - 192);

    const style = {
      width: getPixelValue("100%"),
      height: getPixelValue("fit-content"),
      maxHeight: getPixelValue(windowSize.height - 32),
    };

    const RenderedHeader = () => {
      const isHeadline = headerType === "headline";

      if (headerType === "none") {
        return (
          <div
            className={classNames(
              styles.Header,
              isHeadline && styles["headline"],
              "earlybird-bottom-sheet-header",
            )}
          ></div>
        );
      }

      return (
        <div
          className={classNames(
            styles.Header,
            isHeadline && styles["headline"],
            "earlybird-bottom-sheet-header",
          )}
        >
          <Text
            typo={isHeadline ? "subtitle-2" : "body-1"}
            bold={isHeadline}
            className={classNames(
              styles.Title,
              isHeadline && styles["headline"],
              "earlybird-bottom-sheet-title",
            )}
          >
            {title}
          </Text>
          <IconButton
            icon={XIcon}
            onClick={onClose}
            className={classNames(
              styles.CloseButton,
              isHeadline && styles["headline"],
              "earlybird-bottom-sheet-close-button",
            )}
          />
        </div>
      );
    };

    const PrimaryButton = () => (
      <BoxButton
        loading={loading}
        size={"lg"}
        onClick={onClickPrimaryButton}
        disabled={disabled}
        type={type}
      >
        {primaryButtonLabel}
      </BoxButton>
    );
    const SecondaryButton = () => (
      <BoxButton
        size={"lg"}
        type={buttonType === "duo-horizontal" ? "fit" : "full"}
        hierarchy={buttonType === "duo-horizontal" ? "tertiary" : "ghost"}
        onClick={onClickSecondaryButton}
        loading={loading}
      >
        {secondaryButtonLabel}
      </BoxButton>
    );

    const RenderedButton = () => {
      switch (buttonType) {
        case "none":
          return;
        case "duo-horizontal":
          return (
            <HStack spacing={8}>
              <SecondaryButton />
              <PrimaryButton />
            </HStack>
          );
        case "duo-vertical":
          return (
            <VStack>
              <PrimaryButton />
              <SecondaryButton />
            </VStack>
          );
        case "single":
          return <PrimaryButton />;
      }
    };

    return (
      <>
        <Overlay
          className={classNames(
            styles.Overlay,
            visible ? styles[`visible`] : styles[`hidden`],
            "earlybird-overlay",
          )}
        />
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.BottomSheet,
            visible ? styles[`visible`] : styles[`hidden`],
            "earlybird-bottom-sheet",
            className,
          )}
          style={style}
        >
          <RenderedHeader />
          <div
            className={classNames(styles.ContentsWrapper)}
            style={{ maxHeight: innerHeight }}
          >
            {children}
          </div>

          <Box padding={"16px 20px"} width={"100%"}>
            <RenderedButton />
          </Box>
        </BaseComponent>
      </>
    );
  },
);

export default BottomSheet;
