// style
import styled from "styled-components";
// component(base)
import { Button } from "@components/commons/index";
import theme from "@styles/theme";

export const ImageGrid = styled.div`
  width: calc(100% + 40px);
  margin: 0 -20px;

  img {
    max-width: 500px;
    margin: 0 auto;
    display: block;
  }
`;

export const TitleText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 18px;

  h4 {
    font-size: 18px;
  }
`;

export const ButtuonGridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const KakaoButton = styled(Button)`
  background-color: #ffeb3b;
  border-radius: 12px;
  padding: 16px 20px;
  color: rgb(56, 30, 30);
  font-weight: 700;
`;

export const LinkButton = styled(Button)`
  background: ${theme.color.blue_00};
  color: #fff;
  border-radius: 12px;
  padding: 16px 20px;
  font-weight: 700;
`;
