import React, { useState, ReactNode, useRef } from "react";
// component(base)
import { Span } from "@components/commons/index";
// component(styled)
import { AccordionOutBox, ToggleTitle, ToggleInner } from "./index.styled";
// styled
import theme from "@styles/theme";
import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";

interface AccordionType {
  children: ReactNode[];
  _style?: object;
  _custom?: boolean;
  _onClick?: (state: boolean) => void;
}

const Accordion = ({
  children,
  _style,
  _custom = false,
  _onClick = () => {},
}: AccordionType) => {
  // Accordion height state
  const [accordion, setAccordion] = useState(false);

  // DOM control ref
  const outBox = useRef<HTMLDivElement>(null);
  const inBox = useRef<HTMLDivElement>(null);

  // button toggle action Fn
  const toggleButton = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (outBox === null || inBox === null) return;

    if (outBox.current.clientHeight > 0) {
      outBox.current.style.height = "0";
    } else {
      outBox.current.style.height = `${inBox.current.clientHeight}px`;
    }
    setAccordion(!accordion);
    _onClick(!accordion);
  };

  return (
    <AccordionOutBox style={{ ..._style }}>
      <div>
        <ToggleTitle onClick={toggleButton} _custom={_custom}>
          <div>{children[0]}</div>
          {!_custom && (
            <Icon
              tag={accordion ? IconTags.CHEVRON_UP : IconTags.CHEVRON_DOWN}
            />
          )}
        </ToggleTitle>
      </div>
      <ToggleInner ref={outBox}>
        <div ref={inBox}>{children[1]}</div>
      </ToggleInner>
    </AccordionOutBox>
  );
};

export default Accordion;
