import React, { useState } from "react";
import styled from "styled-components";
import Image from "@components/atoms/Image";
import {
  changeSettlementCodeToImage,
  getAdDuration,
  getAmountTitle,
} from "@components/templates/settlement/SettlementAmountList/utils";
import Text from "@components/atoms/Text";
import { changeNumberToMoney } from "@utils/types";
import { UnderDepositDetailItemsTypes } from "@earlypay/shared/typings";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import BottomSheet from "@components/molecules/BottomSheet";
import Skeleton from "react-loading-skeleton";
import Interaction from "@components/atoms/Interaction";

interface UnderDepositAmountItemProps {
  item: UnderDepositDetailItemsTypes;
  isLoading: boolean;
}

export const UnderDepositAmountItem: React.FC<UnderDepositAmountItemProps> = ({
  item,
  isLoading,
}) => {
  const [isVisibleEtcBottomSheet, setIsVisibleEtcBottomSheet] = useState(false);
  const { type, detail, createdDate, totalAmount, repayAmount, leftAmount } =
    item;
  const title = getAmountTitle(type, detail);
  const ImageType = detail ? detail.type : type;

  /** 기타 미상환금에 대한 안내 바텀시트를 띄웁니다. */
  const handleOpenEtcBottomSheet = () => {
    setIsVisibleEtcBottomSheet(true);
  };

  /** 기타 미상환금에 대한 안내 바텀시트를 닫습니다. */
  const handleCloseEtcBottomSheet = () => {
    setIsVisibleEtcBottomSheet(false);
  };

  /** 광고 기간 포맷에 맞춰서 날짜를 반환합니다. */
  const getAdDate = (value: string) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${month}.${day}`;
  };

  if (isLoading) {
    return (
      <Wrapper>
        <LeftWrapper>
          <Skeleton containerClassName="flex-1" width={38} height={38} />
          <TitleWrapper>
            <Skeleton containerClassName="flex-1" width={70} height={18} />
            <Skeleton containerClassName="flex-1" width={120} height={14} />
          </TitleWrapper>
        </LeftWrapper>
        <RightWrapper>
          <Skeleton containerClassName="flex-1" width={90} height={16} />
          <Skeleton containerClassName="flex-1" width={100} height={14} />
          <Skeleton containerClassName="flex-1" width={120} height={14} />
        </RightWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LeftWrapper>
        <CustomImage
          tag={changeSettlementCodeToImage(ImageType)}
          width={44}
          height={44}
          alt={"차감금 플랫폼 이미지"}
        />
        <TitleWrapper>
          {type === "ETC" && detail?.type !== "BPLUS" ? (
            <Interaction>
              <LinkButton onClick={handleOpenEtcBottomSheet}>
                <Text
                  bold
                  tag={FontTypes.BODY_2}
                  color={ColorsTypes.CONTENT_SECONDARY}
                  underlined
                >
                  {title}
                </Text>
              </LinkButton>
            </Interaction>
          ) : (
            <Text
              bold
              tag={FontTypes.BODY_2}
              color={ColorsTypes.CONTENT_SECONDARY}
            >
              {title}
            </Text>
          )}

          {/** 카드 차감금 또는 기타 미상환금인 경우, 미상환일을 보여줍니다.
           * 광고 차감금일 경우, 광고기간을 보여줍니다.
           */}
          {detail ? (
            detail.type === "BPLUS" ? (
              <Text
                tag={FontTypes.BODY_3}
                color={ColorsTypes.CONTENT_SECONDARY}
              >
                발생일 {getAdDate(createdDate)}
              </Text>
            ) : (
              <Text
                tag={FontTypes.BODY_3}
                color={ColorsTypes.CONTENT_SECONDARY}
              >
                광고기간 {getAdDuration(detail.startDate, detail.endDate)}
              </Text>
            )
          ) : (
            <Text tag={FontTypes.BODY_3} color={ColorsTypes.CONTENT_SECONDARY}>
              미상환일 {getAdDate(createdDate)}
            </Text>
          )}
        </TitleWrapper>
      </LeftWrapper>

      <RightWrapper>
        <Text bold tag={FontTypes.BODY_2} color={ColorsTypes.CONTENT_SECONDARY}>
          {changeNumberToMoney(repayAmount)}
        </Text>
        <Text tag={FontTypes.BODY_3} color={ColorsTypes.CONTENT_SECONDARY}>
          총 {changeNumberToMoney(totalAmount)}
        </Text>
        <Text tag={FontTypes.BODY_3} color={ColorsTypes.CONTENT_SECONDARY}>
          남은 금액 {changeNumberToMoney(leftAmount)}
        </Text>
      </RightWrapper>

      {isVisibleEtcBottomSheet && (
        <BottomSheet
          headerText={"기타 미상환금이란?"}
          onClose={handleCloseEtcBottomSheet}
          primaryButtonText={"확인"}
        >
          <Text color={ColorsTypes.CONTENT_SECONDARY} tag={FontTypes.BODY_2}>
            배달 플랫폼 내에서 파악 불가능한 사유로 인해 지급 받은 선정산
            금액보다 적게 회수되어 발생한 미상환금입니다. 정액요금제 결제, 주문
            취소/환불 등으로 인해 발생할 수 있습니다.
          </Text>
        </BottomSheet>
      )}
    </Wrapper>
  );
};

export default UnderDepositAmountItem;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`;

const CustomImage = styled(Image)`
  width: 44px;
  height: 44px;
  border-radius: 16px;
`;

const LeftWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 8px;
`;

const RightWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2px;
`;

const TitleWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const LinkButton = styled.button`
  width: fit-content;
  height: fit-content;
  background: transparent;
  cursor: pointer;
`;
