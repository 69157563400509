import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { errorData } from "@pages/serviceApply/LicenseVerify/Result/dataSetArray";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { LottieAnimation } from "@earlybird/ui";

import Button from "@components/atoms/Button";
import Text from "@components/atoms/Text";
import { Header, Meta } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateParams";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

export const Result: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { navigateWithParams } = useNavigateWithParams();
  const errorCode = searchParams.get("errorCode") ?? "";

  const currentError = errorData.filter(item =>
    item.errorCode.includes(errorCode),
  )[0];

  const handleGoToLicenseVerify = () => {
    navigateWithParams({ pathname: "/service-apply/license-verify" });
  };

  trackPageViewMixpanel("사업자등록증 인증 | 거절");

  return (
    <Container>
      <Meta title={`얼리페이 | 서비스 신청`} />
      <Header previous={"/service-apply/license-verify"}></Header>

      <Wrapper>
        <InnerWrapper>
          <Text center tag={FontTypes.SUBTITLE_1} bold>
            {currentError.title}
          </Text>
          <LottieAnimation src={"warning"} width={240} height={240} />
          <Text
            center
            color={ColorsTypes.CONTENT_SECONDARY}
            tag={FontTypes.BODY_2}
          >
            {currentError.text}
          </Text>
        </InnerWrapper>
        <ButtonWrapper>
          <Button onClick={handleGoToLicenseVerify}>
            {currentError.buttonLabel}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default Result;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  cursor: default;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-top: 6vh;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 8vh;
`;
