import React, { useLayoutEffect } from "react";

import {
  ApplicationStatusCode,
  ServiceApplyCode,
  ServiceApplyIndex,
  checkEarlypayStage,
} from "@earlypay/shared";
import {
  changePathToServiceApplyCode,
  changeServiceApplyCodeToPath,
} from "@earlypay/shared/src/utils/paths";
import { applicationState } from "@recoil/application/atom";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useRedirectUsers } from "@apis/hooks";

const ProtectedServiceApplyRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const application = useRecoilValue(applicationState);
  const stage = application.stage; // 서비스 신청서 플로우 단계

  const mutation = useRedirectUsers(application.user.id);

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const currentServiceApplyPath =
    pathSegments.includes("service-apply") && pathSegments.length
      ? pathSegments[1]
      : undefined;

  // 신청서가 승인된 경우, 대부에서 페이(사장님 페이지)로 이동합니다.
  if (application.status === ApplicationStatusCode.APPROVED) {
    return <Navigate to={`/`} replace state={{ from: location }} />;
  }

  const handleGoToEpayloanService = (path: string) => {
    const code = changePathToServiceApplyCode(path);
    const isEarlypay = checkEarlypayStage(code);

    if (application.status === ApplicationStatusCode.CANCELLED) {
      mutation.mutate(`/service-apply/screening/restart`);
    }

    if (!isEarlypay) {
      mutation.mutate(`/service-apply/${path}`);
    }

    return;
  };

  const handleCheckStage = (wantedStage: number, currentStage: number) => {
    const currentPath = changeServiceApplyCodeToPath(stage);

    // 본인 인증을 진행한 경우, 본인 인증 단계를 건너뜁니다.
    if (application.user.isCertified && location.pathname.includes("auth")) {
      if (
        application.depositAccount.account &&
        application.documents.imageBusinessRegistration
      ) {
        return navigate("/service-apply/license-verify/verify");
      }
      return navigate("/service-apply/license-verify");
    }

    // 로그인 이후 처음 인트로 페이지로 들어올 때, 리디렉션이 되지 않도록 return 합니다.
    if (location.pathname === "/" || currentStage === 1) {
      return;
    }

    // submit 페이지로 들어왔을 때, 신청서 상태가 NEW 인 경우는 매출 유형 페이지로 되돌아 갑니다.
    if (
      location.pathname.includes("submit") &&
      application.status === ApplicationStatusCode.NEW
    ) {
      navigate("/service-apply/sales-type");
    }

    // 서비스 신청서 심사가 이미 진행되었는데, 신청서 상태가 NEW 인 경우는 매출 유형 페이지로 되돌아갈 수 있도록 리디렉트를 제거합니다.
    if (
      application.status === ApplicationStatusCode.NEW &&
      currentStage >= ServiceApplyIndex[ServiceApplyCode.ID_CARD] &&
      location.pathname.includes("sales-type")
    ) {
      return;
    }

    if (application.status === ApplicationStatusCode.CANCELLED) {
      return mutation.mutate("/service-apply/screening/restart");
    }

    if (application.status === ApplicationStatusCode.WAITING_REVIEW) {
      return;
    }

    if (
      location.pathname === "/" ||
      location.pathname === "/service-apply" ||
      location.pathname === "/service-apply/"
    ) {
      return navigate(`/service-apply/${currentPath}`);
    }

    // 심사 결과 페이지로 들어오면, epayloan.kr 로 리디렉트를 진행합니다.
    if (location.pathname.includes("/service-apply/screening")) {
      return mutation.mutate("/service-apply/screening");
    }

    // 이미 진행한 단계 또는 현재 단계로 이동하는 것이므로 페이지 이동을 허용합니다
    if (wantedStage <= currentStage) {
      return;
    }

    // 진행하지 않은 단계로 이동하는 것이므로 페이지 이동을 허용하지 않고, 가장 최근 단계로 강제 리디렉트 합니다.
    if (wantedStage > currentStage) {
      return navigate(`/service-apply/${currentPath}`);
    }
  };

  useLayoutEffect(() => {
    if (application.id) {
      const wantedCode = changePathToServiceApplyCode(currentServiceApplyPath);
      const wantedStageIndex = ServiceApplyIndex[wantedCode];
      const currentStageIndex = ServiceApplyIndex[stage];

      // 현재 pathname 이 얼리페이가 아니라 `얼리페이 대부`에 해당하는 단계이면, epayloan.kr 로 리디렉트를 진행합니다.
      currentServiceApplyPath &&
        handleGoToEpayloanService(currentServiceApplyPath);

      /** 서비스 신청서 `단계`에 따라 페이지 이동 여부를 결정합니다. */
      handleCheckStage(wantedStageIndex, currentStageIndex);
    }
  }, [application, location]);

  return <>{children}</>;
};

export default ProtectedServiceApplyRoute;
