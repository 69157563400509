import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Image from "@components/atoms/Image";
import DaumPostCodeModal from "@components/organisms/DaumPostCodeModal";
import { floatingContentMotion } from "@styles/animations";
import { useRecoilValue } from "recoil";
import { daumPostCodeState } from "@/recoil/common/atoms";
import ImageFullScreenModal from "@components/organisms/ImageFullScreenModal";
import { Input, BoxButton, VStack, HStack, useToast } from "@earlybird/ui";
import {
  BusinessLicenseTypes,
  debounce,
  ManagerInfoTypes,
} from "@earlypay/shared";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface LicenseVerifyFormProps {
  data: BusinessLicenseTypes;
  progress?: "CHECK" | "VERIFY";
}

export const LicenseVerifyForm: React.FC<LicenseVerifyFormProps> = ({
  data,
  progress = "CHECK",
}) => {
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const address = useRecoilValue(daumPostCodeState);
  const { trigger, watch, control, setValue } = useFormContext();

  const handleTrigger = useCallback(
    debounce(async () => {
      await trigger(["address", "detailAddress"]);
    }, 500),
    [],
  );

  useEffect(() => {
    handleTrigger();
  }, [handleTrigger, watch("address"), watch("detailAddress")]);

  useEffect(() => {
    if (address.fullAddress?.length) {
      setValue("address", address.fullAddress, {
        shouldValidate: true,
      });
      setValue("postCode", address.postCode.toString(), {
        shouldValidate: true,
      });
    }
  }, [address]);

  return (
    <VStack spacing={8} padding={"0 0 32px 0"}>
      <ImageWrapper>
        <Image
          src={data?.imageBusinessRegistration}
          alt={"사업자등록증 이미지"}
          width={260}
          height={340}
          objectFit={"cover"}
        />
        <ImageFullScreenModal imageUrl={data?.imageBusinessRegistration} />
      </ImageWrapper>

      {data?.address && (
        <DaumPostCodeModal
          defaultValue={data.address}
          isOpen={isOpenAddressModal}
          closeModal={() => setIsOpenAddressModal(false)}
        />
      )}

      {progress === "VERIFY" && (
        <>
          <Controller
            control={control}
            render={({ field }) => (
              <HStack align={"end"} spacing={4}>
                <Input
                  required
                  title={"사업장 주소"}
                  name={"address"}
                  value={field?.value}
                  placeholder={"현재 사업장 소재지 입력"}
                />
                <BoxButton
                  onClick={() => setIsOpenAddressModal(true)}
                  hierarchy={"secondary"}
                  state={"fit"}
                >
                  주소 찾기
                </BoxButton>
              </HStack>
            )}
            name={"address"}
          />

          <Controller
            control={control}
            render={({ field }) => (
              <Input
                required
                title={"사업장 상세 주소"}
                name={"detailAddress"}
                placeholder={"상세 주소 입력"}
                value={field.value}
                onChange={field.onChange}
              />
            )}
            name={"detailAddress"}
          />
        </>
      )}

      <Input
        title={"사업자등록번호"}
        name={"licenseNumber"}
        value={data?.businessRegistrationNumber}
        readonly
      />
      <Input
        title={"개업연월일"}
        name={"openDate"}
        value={data?.openDate}
        readonly
      />
      <HStack spacing={4}>
        <Input
          width={"50%"}
          title={"대표자 성명"}
          name={"ownerName"}
          value={data?.owner.name}
          readonly
        />
        <Input
          title={"대표자 생년월일"}
          name={"ownerBirthdate"}
          value={data?.owner.birthdate}
          readonly
        />
      </HStack>

      {!!data?.coFounders && (
        <VStack spacing={6}>
          {data.coFounders.map((items: ManagerInfoTypes, index: number) => (
            <HStack key={index} spacing={4} width={"100%"}>
              <Input
                width={"50%"}
                title={index === 0 && "공동대표자 성명"}
                name={"ownerName"}
                value={items.name}
                readonly
              />
              <Input
                title={index === 0 && "공동대표자 생년월일"}
                name={"ownerBirthdate"}
                value={items.birthdate}
                readonly
              />
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default LicenseVerifyForm;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 340px;
  margin: auto;

  ${floatingContentMotion}
`;
