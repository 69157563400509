import { ImageTypes } from "@components/atoms/Image/type";

export const changeStringToImageType = (value: string) => {
  switch (value) {
    case "CARD":
      return ImageTypes.SETTLEMENT_CARD_ANGLE;
    case "BAEMIN":
      return ImageTypes.SETTLEMENT_BAEMIN;
    case "YOGIYO":
      return ImageTypes.SETTLEMENT_YOGIYO;
    case "COUPANG":
      return ImageTypes.SETTLEMENT_COUPANG_EATS;
    case "ETC":
      return ImageTypes.SETTLEMENT_ETC;
  }
};

export const changeStringToPath = (value: string) => {
  switch (value) {
    case "CARD":
      return "card";
    case "BAEMIN":
      return "baemin";
    case "YOGIYO":
      return "yogiyo";
    case "COUPANG":
      return "coupang";
    case "ETC":
      return "etc";
  }
};
