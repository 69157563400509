import { KAKAO_AUTH_URL } from "@commons/constants";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";

import useNavigateWithParams from "@hooks/useNavigateParams";

import { changeToken } from "@utils/changeToken";
import { deleteCookie } from "@utils/cookie";

import useTrackingUtm from "@/hooks/useTrackingUtm";

export const useLogin = () => {
  const location = useLocation();
  const { convertUtmInfo } = useTrackingUtm();
  const { navigateWithParams } = useNavigateWithParams();
  const { utmSource, utmMedium, utmCampaign } = convertUtmInfo();

  const getLoginUrlWithNextUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const hasQueryParams = [...searchParams.keys()].length > 0;
    const loginUrl = hasQueryParams
      ? `/login?${searchParams.toString()}&nextUrl=${location.pathname}`
      : `/login?nextUrl=${location.pathname}`;

    return loginUrl;
  };

  const generateRedirectUrl = (): string => {
    const searchParams = new URLSearchParams(location.search);
    const baseUrl = import.meta.env.VITE_EARLY_BASE_URL;
    const nextUrl = searchParams.get("nextUrl") || "";

    searchParams.delete("nextUrl");
    searchParams.delete("utm_source");
    searchParams.delete("utm_medium");
    searchParams.delete("utm_campaign");

    const additionalParams = searchParams.toString();

    let link = `${baseUrl}${nextUrl}`;

    const queryParams: string[] = [];

    if (utmSource) queryParams.push(`utm_source=${utmSource}`);
    if (utmMedium) queryParams.push(`utm_medium=${utmMedium}`);
    if (utmCampaign) queryParams.push(`utm_campaign=${utmCampaign}`);
    if (additionalParams) queryParams.push(additionalParams);

    if (queryParams.length > 0) {
      link += `?${queryParams.join("&")}`;
    }

    return `redirectUrl=${link}`;
  };

  const getKakaoLoginUrl = () => {
    const apiUrl = import.meta.env.VITE_EARLY_API;
    const url = new URL(KAKAO_AUTH_URL);
    const queryParams: Record<string, string> = {
      client_id: `${import.meta.env.VITE_KAKAO_CLIENT_KEY}`,
      redirect_uri: `${apiUrl}/v2/users/kakao-login`,
      response_type: "code",
      prompt: "select_account",
      state: `${generateRedirectUrl()}`,
    };
    Object.keys(queryParams).forEach(key =>
      url.searchParams.append(key, queryParams[key]),
    );

    return url.toString();
  };

  const clearLoginInfo = () => {
    localStorage.clear();
    changeToken("");
    deleteCookie("EARLYPAY_STORE_ID");
    deleteCookie("kakao-token");
    deleteCookie("users-id");
    deleteCookie("earlypay-token");
  };

  const handleLogout = () => {
    mixpanel.reset();
    clearLoginInfo();
    navigateWithParams({ pathname: `/landing` });
  };

  return {
    getKakaoLoginUrl,
    getLoginUrlWithNextUrl,
    clearLoginInfo,
    handleLogout,
  };
};
