import { instance } from "@apis/instance";
import { GetAdsParams } from "@apis/endpoints/ads/types";

/**
 * FAQ 목록을 가져오는 api 함수입니다.
 */
export const getAdsExtra = async (params: GetAdsParams) => {
  try {
    const { data } = await instance({
      method: "get",
      url: `/v1/ads/extra/`,
      params: params,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
