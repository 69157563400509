import styled from "styled-components";
import { SCREEN_MAX_WIDTH } from "@/commons/constants";
import themes from "@styles/themes";

export const SliderWrapper = styled.div`
  width: ${SCREEN_MAX_WIDTH}px;
  height: 100%;
  border: none;

  > div {
    border: none !important;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
`;

export const DotsWrapper = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${themes.colors.BORDER_DEFAULT};
`;

export const ActiveDot = styled.div`
  width: 14px;
  height: 6px;
  border-radius: 6px;
  background-color: ${themes.colors.BORDER_HIGHLIGHT};
`;
