import ServiceApplyLayout from "../layouts/ServiceApplyLayout";
import React from "react";
import ProtectedServiceApplyRoute from "@routes/utils/ProtectedServiceApplyRoute";

const ServiceApplyRouteGuard = () => {
  return (
    <ProtectedServiceApplyRoute>
      <ServiceApplyLayout />
    </ProtectedServiceApplyRoute>
  );
};

export default ServiceApplyRouteGuard;
