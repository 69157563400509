import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import { getRecommendationInfo } from "@apis/endpoints/user";

import { getCookie } from "@utils/cookie";

import { userState } from "@/recoil/user/atoms";

import { userQueryKeys } from "./queryKeys";

export const useFetchRecommendationInfo = (userId: number) => {
  const accessToken = getCookie("earlypay-token");
  const defaultValue = useRecoilValue(userState);

  return useQuery({
    queryKey: userQueryKeys.fetchRecommendation(userId).queryKey,
    queryFn: () => getRecommendationInfo(userId),
    enabled: !!accessToken,
    placeholderData: defaultValue,
    retry: 0,
  });
};
