import type { SalesInterface } from "@/@types/saleType";
import type { ExtraInterface } from "@/@types/depositType";

export const getDatesBetween = (startDay: string, endDay: string) => {
  const start: Date = new Date(startDay);
  const end: Date = new Date(endDay);

  const dates: Date[] = [];

  while (start <= end) {
    dates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }

  return dates.map(date => date.toISOString().split("T")[0]);
};

export const formatPlatformSaleData = (
  startDay: string,
  endDay: string,
  data: SalesInterface,
) => {
  const saleData = { ...data };
  const betweenDatesList = getDatesBetween(startDay, endDay);

  let card = {};
  let delivery = {};

  betweenDatesList.forEach(date => {
    const defaultObject = {
      totalSales: 0,
      totalSalesCount: 0,
      sales: 0,
      salesCount: 0,
    };

    const cardObject = {
      [date]: {
        ...defaultObject,
        cancelSales: 0,
        cancelSalesCount: 0,
      },
    };

    const deliveryObject = {
      [date]: {
        ...defaultObject,
        meetCard: 0,
        meetCardCount: 0,
        meetCash: 0,
        meetCashCount: 0,
      },
    };

    card = Object.assign(card, { ...cardObject });
    delivery = Object.assign(delivery, { ...deliveryObject });
  });

  const saleObject = {
    card: {
      ...card,
      ...saleData.card,
    },
    baemin: {
      ...delivery,
      ...saleData.baemin,
    },
    yogiyo: {
      ...delivery,
      ...saleData.yogiyo,
    },
  };

  return saleObject;
};

export const formatExtraPaymentsData = (
  startDay: string,
  endDay: string,
  data: ExtraInterface,
) => {
  const extraData = { ...data };
  const betweenDatesList = getDatesBetween(startDay, endDay);

  let extraPayments = {};
  let overDeposits = {};

  betweenDatesList.forEach(date => {
    const extraPaymentsObject = {
      [date]: {
        extraEarlypay: 0,
        extraOverdeposit: 0,
        replaceOverdeposit: 0,
        etc: 0,
      },
    };

    const overDepositsObject = {
      [date]: {
        overDeposit: 0,
        paidOverDeposit: 0,
        repayUnderdeposit: 0,
      },
    };

    extraPayments = Object.assign(extraPayments, { ...extraPaymentsObject });
    overDeposits = Object.assign(overDeposits, { ...overDepositsObject });
  });

  const extraObject = {
    extraPayments: {
      ...extraPayments,
      ...extraData.extraPayments,
    },
    overDeposits: {
      ...overDeposits,
      ...extraData.overDeposits,
    },
  };

  return extraObject;
};
