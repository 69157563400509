import { SalesTypeCode } from "@earlypay/shared/typings";

export const salesTypeList = [
  {
    label: "배달앱, 카드 매출\n모두 미리 받기",
    value: SalesTypeCode.AL,
    image: "/service/delivery-card.png",
  },
  {
    label: "배달앱 매출만\n미리 받기",
    value: SalesTypeCode.DE,
    image: "/service/delivery.png",
  },
  {
    label: "카드 매출만\n미리 받기",
    value: SalesTypeCode.CA,
    image: "/service/card.png",
  },
];
