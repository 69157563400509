// styled
import theme from "@styles/theme";
import styled from "styled-components";
// component(base)
import { Button, Span, FlexBox } from "@components/commons/index";

export const AuthButton = styled(Button)`
  border-radius: 5px;
  background: ${theme.color.blue_00};
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoText = styled(Span)`
  font-size: 15px;
  margin-top: 10px;
  color: ${theme.color.gray_50};
`;

export const InfoGirdBox = styled(FlexBox)`
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 18px 0;
`;
