import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

export const useGetTodayEarlypay = () => {
  const todayEarlypay = useSelector(
    (state: RootState) => state.deposit.todayEarlypay,
  );

  return { todayEarlypay };
};

export const useGetMonthEarlypay = () => {
  const monthDeposit = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthDeposit,
  );
  const monthSales = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthSales,
  );
  const monthTotalSale = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthTotalSale,
  );
  const monthTotalPlatformFee = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthTotalPlatformFee,
  );
  const monthTotalInterestFee = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthTotalInterestFee,
  );
  const monthTotalEarlypayFee = useSelector(
    (state: RootState) => state.deposit.monthEarlypay.monthTotalEarlypayFee,
  );

  return {
    monthDeposit,
    monthSales,
    monthTotalSale,
    monthTotalPlatformFee,
    monthTotalInterestFee,
    monthTotalEarlypayFee,
  };
};
