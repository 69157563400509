import styled from "styled-components";
import theme from "@styles/theme";

export const UserTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  img {
    width: 30px;
  }

  p {
    font-size: 24px;
    font-weight: 700;
    color: ${theme.color.black_00};
  }
`;

export const StoreBadge = styled.div`
  border-radius: 100px;
  border: 1px solid #4699ff;
  color: #4699ff;
  font-size: 12px;
  padding: 2px 10px;
  line-height: 1.2;
`;

export const StoreName = styled.p`
  color: #1f1f1f;
  font-size: 16px;
  line-height: 1.2;
  margin-left: 6px;
`;
