export class FileSizeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "max-size-exceeded";
  }
}

export class FileFormatError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "invalid-format";
  }
}
