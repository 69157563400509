import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "@redux/store";
import { useDispatch } from "react-redux";
import {
  format,
  startOfWeek,
  addDays,
  isSameMonth,
  endOfMonth,
  startOfMonth,
  endOfWeek,
  subDays,
} from "date-fns";

import { getMonthEarlypayThunk } from "@redux/modules/deposit";

import { FlexBox, Logo } from "@components/commons/index";
import {
  CalendarSpinner,
  MonthCell,
  Deposit,
  Sale,
  SpinerPosition,
  Calendar,
} from "./index.styled";

import type { DateRangeParams } from "@/@types/commonType";
import { useGetMonthEarlypay } from "@/hooks/useEarlypay";
import { getTodayWithTimeZone } from "@/utils/date";
import { useHoliday } from "@/hooks/useHoliday";
import theme from "@styles/theme";
import { useFetchApplication } from "@apis/hooks";
import { getServiceInfo } from "@apis/endpoints";

interface CalendarCellType {
  _currentMonth: Date;
  _isLoading: boolean;
  _firstDate: Date;
  _setFirstDate: (date: Date) => void;
  _setIsLoading: (state: boolean) => void;
}

const CalendarCell = ({
  _currentMonth = new Date(),
  _isLoading,
  _firstDate,
  _setIsLoading,
  _setFirstDate,
}: CalendarCellType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { monthDeposit, monthSales } = useGetMonthEarlypay();
  const { holiday } = useHoliday(_currentMonth);
  const { data: application } = useFetchApplication();

  const dailySales = [...monthSales];
  const dailyDeposit = [...monthDeposit];

  const getMonthEarlypay = async (): Promise<void> => {
    if (!application.store.id) return;
    const startDate = new Date().toISOString().split("T")[0].slice(0, 8) + "01";
    const endDate = new Date().toISOString().split("T")[0];

    _setIsLoading(true);

    const params: DateRangeParams = {
      startDate: startDate,
      endDate: endDate,
      storeId: application.store.id,
      page: "calendar",
    };

    try {
      await dispatch(getMonthEarlypayThunk(params)).unwrap();
    } catch (err) {
      throw new Error("캘린더 불러오기 실패");
    } finally {
      _setIsLoading(false);
    }
  };

  const getService = async (): Promise<void> => {
    if (!application.store.id) return;

    try {
      const { data } = await getServiceInfo(application?.store.id);

      const earlypaid =
        new Date(data?.results[0]?.firstEarlypayDate) ?? new Date();

      _setFirstDate(subDays(earlypaid, 1));
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    getMonthEarlypay();
  }, [application]);

  // ---------------------------------- 달력 날짜 및 공휴일 텍스트 관리 ---------------------------------
  const startMonth: Date = startOfMonth(_currentMonth);
  const endMonth: Date = endOfMonth(startMonth);
  const startDate: Date = startOfWeek(startMonth);
  const endDate: Date = endOfWeek(endMonth);

  const rows = [];

  let days = [];
  let day = startDate;
  let formattedDate = "";

  const holidayTextChange = (text: string) => {
    if (text === "전국동시지방선거") {
      return "지방선거";
    } else if (text === "부처님오신날") {
      return "석가탄신일";
    } else if (text === "대통령선거일") {
      return "대통령선거";
    } else if (text === "기독탄신일") {
      return "크리스마스";
    } else {
      return text;
    }
  };

  // ---------------------------------- 캘린더 생성 ---------------------------------
  let count = 0;
  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, "d");
      const noneDate = !isSameMonth(day, startMonth);
      const disableDate = day > getTodayWithTimeZone() || day < _firstDate;
      const isToday =
        new Date(day).getTime() === getTodayWithTimeZone().getTime();
      const checkHoliday = `${day.getMonth() + 1}-${day.getDate()}`;

      const findHoliday = holiday.filter(x => {
        if (x.date === checkHoliday) return x;
      });

      const dateColor = () => {
        if (i === 0 || findHoliday.length !== 0) {
          return theme.color.red_00;
        } else if (i === 6) {
          return theme.color.blue_00;
        } else {
          return "#555";
        }
      };

      const sameSale =
        dailySales.filter(x => {
          if (startMonth.getMonth() + 1 !== Number(x?.date?.split("-")[1])) {
            return;
          }
          if (Number(x?.date?.split("-")[2]) === Number(formattedDate)) {
            return x;
          }
        })[0]?.price ?? 0;
      const sameDeposit =
        dailyDeposit.filter(x => {
          if (startMonth.getMonth() + 1 !== Number(x?.date?.split("-")[1])) {
            return;
          }
          if (Number(x?.date?.split("-")[2]) === Number(formattedDate)) {
            return x;
          }
        })[0]?.price ?? 0;

      const goDepositsPage = (day: string) => {
        if (disableDate || noneDate) return;
        const year = _currentMonth?.getFullYear();
        const month = (_currentMonth?.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const currentDay = day.toString().padStart(2, "0");
        navigate(
          `/settlement?startDate=${year}-${month}-${currentDay}&endDate=${year}-${month}-${currentDay}`,
        );
      };

      days.push(
        <MonthCell
          key={day}
          _disable={disableDate}
          _noneDate={noneDate}
          _color={dateColor()}
          _isToday={isToday}
          id={`day_${formattedDate}`}
          onClick={(e: React.MouseEvent) => {
            const target = e.target as HTMLElement;
            if (!target.offsetParent.id.split("_")[1]) return;
            goDepositsPage(target.offsetParent.id.split("_")[1]);
          }}
        >
          <div>{formattedDate}</div>
          <div
            style={{
              fontSize: "10px",
              color: findHoliday.length === 0 && "transparent",
            }}
          >
            {holidayTextChange(findHoliday[0]?.name) ?? "미정"}
          </div>
          {!noneDate && (
            <Sale _fontType="roboto" _pricr={sameSale !== 0}>
              {(sameSale * 0.0001).toFixed(2).slice(0, -1)} 만
            </Sale>
          )}
          {!noneDate && (
            <Deposit _fontType="roboto" _pricr={sameDeposit !== 0}>
              {(sameDeposit * 0.0001).toFixed(2).slice(0, -1)} 만
            </Deposit>
          )}
        </MonthCell>,
      );
      day = addDays(day, 1);
    }

    count++;

    rows.push(
      <FlexBox _row key={count}>
        {days}
      </FlexBox>,
    );

    days = [];
  }

  return (
    <div style={{ position: "relative" }}>
      {<Calendar>{rows}</Calendar>}
      {_isLoading && (
        <CalendarSpinner>
          <SpinerPosition>
            <Logo />
          </SpinerPosition>
        </CalendarSpinner>
      )}
    </div>
  );
};

export default CalendarCell;
