import {
  PlatformNames,
  CardCode,
  DeliveryCode,
} from "@earlypay/shared/typings";
import { changePathToPlatform } from "@pages/settlement/utils";

export const getFilterLabel = (type: "CARD" | "DELIVERY") => {
  const searchParams = new URLSearchParams(window.location.search);
  const cardList =
    searchParams.has("card") && searchParams.get("card").split(",");
  const deliveryList =
    searchParams.has("delivery") && searchParams.get("delivery").split(",");

  if (type === "CARD" && searchParams.has("card")) {
    const cardCode = changePathToPlatform(cardList[0]);
    const customLabel = `${PlatformNames[cardCode]}카드${
      cardList?.length > 1 ? ` 외 ${cardList.length - 1}개` : ""
    }`;
    return customLabel;
  }

  if (type === "DELIVERY" && searchParams.has("delivery")) {
    const deliveryCode = changePathToPlatform(deliveryList[0]);
    const customLabel = `${PlatformNames[deliveryCode]}${
      deliveryList?.length > 1 ? ` 외 ${deliveryList.length - 1}개` : ""
    }`;
    return customLabel;
  }

  return "전체";
};

export const getPlatformData = (type: "CARD" | "DELIVERY") => {
  const searchParams = new URLSearchParams(window.location.search);
  const allData = getAllData(type);

  if (type === "CARD" && searchParams.has("card")) {
    const cardList = searchParams.get("card").split(",");
    return cardList;
  }
  if (type === "DELIVERY" && searchParams.has("delivery")) {
    const list = searchParams.get("delivery").split(",");
    const deliveryList = list.map(item => changePathToPlatform(item));

    return deliveryList;
  }

  return allData;
};

export const getAllData = (type: "CARD" | "DELIVERY") => {
  return type === "CARD"
    ? Object.values(CardCode)
    : Object.values(DeliveryCode);
};
