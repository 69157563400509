export const errorData = [
  {
    errorCode: ["disable-corporate"],
    title: "아쉽지만 얼리페이 이용 가능한\n대상이 아니에요",
    text: "카드 매출이 발생되는 소상공인을 위한 서비스로,\n현재는 법인사업과 임대업 사장님께서 이용이\n어려운 점 양해 부탁드릴게요.",
    buttonLabel: "확인",
  },
  {
    errorCode: ["closed-business"],
    title: "아쉽지만 휴업 또는 폐업 중이라\n지금은 이용이 어려워요",
    text: "영업을 재개하시면 언제든지 다시 찾아주세요.\n얼리페이가 기다리고 있을게요.",
    buttonLabel: "나중에 다시 오기",
  },
  {
    errorCode: ["online-business"],
    title: "아쉽지만 온라인 매출만 있는\n사장님은 이용이 어려워요",
    text: "현재는 배달앱(배달의민족, 요기요, 쿠팡이츠) 또는 카드 단말기를 통한 매출에 대해서만 서비스를 제공하고 있어 이용이 어려운 점 양해 부탁드릴게요.",
    buttonLabel: "확인",
  },
];
