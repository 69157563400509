import { Outlet } from "react-router-dom";
import { BottomNav } from "@components/layouts";
import { Container } from "@earlybird/ui";
import React from "react";

export const MainLayout: React.FC = () => {
  return (
    <Container>
      <Outlet />
      <BottomNav />
    </Container>
  );
};

export default MainLayout;
