import styled from "styled-components";
import { KAKAO_BG_COLOR } from "@/commons/constants";

export const LoginButton = styled.a`
  background-color: ${KAKAO_BG_COLOR};
  width: 100%;
  height: fit-content;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
`;

export const Space = styled.div`
  width: 24px;
  height: 24px;
`;
