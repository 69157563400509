// component(base)
import { FlexBox } from "@components/commons/index";
// style
import styled from "styled-components";
import { scaleInnerBoxMotion, fadeBackgroundMotion } from "@styles/animation";
import theme from "@styles/theme";

export const ModalWrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`;

export const ModalAnimationBox = styled(FlexBox)`
  max-width: 500px;
  margin-bottom: 40px;
  ${scaleInnerBoxMotion}
`;

export const ModalInner = styled(FlexBox)`
  width: calc(100% - 40px);
  margin: 0 auto;
  background: #fff;
  padding: 16px 20px 14px 20px;
  border-radius: 12px;
  color: #3f3f3f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  animation-duration: 0.3s;
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  ${fadeBackgroundMotion}
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  gap: 12px;

  button {
    color: #fff;
    padding: 6px 14px;
    border-radius: 8px;
    font-size: ${theme.px[15]};
    width: 100%;
  }
`;
