import React from "react";
import styled from "styled-components";
import Button from "@components/atoms/Button";
import { ButtonSizes, ButtonTypes } from "@components/atoms/Button/type";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import Image from "@components/atoms/Image";
import { changeStringToImageType, changeStringToPath } from "./utils";
import { changeNumberToMoney } from "@utils/types";
import { DepositorCode, DepositorNames } from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { ImageTypes } from "@components/atoms/Image/type";
import useSettlement from "@hooks/useSettlement";

export interface EarlySettlementBoxProps {
  depositor: DepositorCode;
  value: number;
  isLoading: boolean;
}

export const EarlySettlementBox: React.FC<EarlySettlementBoxProps> = ({
  depositor,
  value,
  isLoading,
}) => {
  const navigate = useNavigate();
  const path = changeStringToPath(depositor);
  const { currentUrl, startDateParams, endDateParams } = useSettlement();

  const handleClickButton = () => {
    navigate(
      `/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${path}`,
      { state: { from: currentUrl } },
    );
  };

  return (
    <Wrapper>
      <ContentsWrapper>
        <PlatformImage
          tag={changeStringToImageType(depositor)}
          alt={"선정산 플랫폼 이미지"}
          width={44}
          height={44}
        />
        <SettlementLabelWrapper>
          <Text
            tag={FontTypes.CAPTION_1}
            color={ColorsTypes.CONTENT_TERTIARY}
            bold
          >
            {DepositorNames[depositor] === "기타"
              ? "그 외"
              : `${DepositorNames[depositor]} 선정산`}
          </Text>
          <Text bold>
            {isLoading ? (
              <Skeleton height={22} width={100} />
            ) : (
              changeNumberToMoney(value)
            )}
          </Text>
        </SettlementLabelWrapper>
      </ContentsWrapper>
      <Button
        onClick={handleClickButton}
        tag={ButtonTypes.SOLID}
        size={ButtonSizes.SM}
        width={"auto"}
      >
        상세보기
      </Button>
    </Wrapper>
  );
};

export default EarlySettlementBox;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 4px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const ContentsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const SettlementLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlatformImage = styled(Image)`
  border-radius: 16px;
  width: 44px;
  height: 44px;
  border: ${({ tag }: { tag: string }) =>
    tag === ImageTypes.SETTLEMENT_COUPANG_EATS ? "1px solid #F4F4F4" : "none"};
`;
