import { ChangeEvent, useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ButtonTypes } from "@components/atoms/Button/type";
import { ImageTypes } from "@components/atoms/Image/type";
import Text from "@components/atoms/Text";
import { errorToast } from "@components/atoms/Toast";
import { Meta } from "@components/commons";
import EmotionButton from "@components/templates/common/Feedback";

import { useFetchApplication, usePostFeedbackStatus } from "@apis/hooks";

import { FontTypes } from "@/@types/ThemesType";
import Button from "@/components/atoms/Button";

import Icon from "src/components/atoms/Icon";

const Feedback = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { data: application } = useFetchApplication();

  const emotionParams: string = searchParams.get("emotion");
  const storeId: number = application.store.id;

  const [currentEmotion, setCurrentEmotion] = useState<string>(emotionParams);
  const [userComment, setUserComment] = useState<string>("");

  const mutation = usePostFeedbackStatus("/settlement");

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserComment(e.target.value);
  };

  const navigate = useNavigate();
  const backRoute = () => {
    navigate("/settlement");
  };

  const selectEmotion = (type: string) => {
    setCurrentEmotion(type);
  };

  const submitFeedback = () => {
    if (!currentEmotion) {
      errorToast("만족도를 선택해주세요.");
      return;
    }

    const score = {
      unsatisfying: 0,
      soso: 5,
      satisfying: 10,
    } as const;

    const params = {
      category: "정산내역",
      evaluation_score:
        score[currentEmotion as "unsatisfying" | "soso" | "satisfying"],
      user_comment: userComment,
      store: storeId,
    };

    mutation.mutate(params);
  };

  trackPageViewMixpanel("피드백 수집");

  return (
    <>
      <Meta title="얼리페이 | 마이페이지" />
      <Header>
        <BackRouteButton onClick={backRoute}>
          <Icon tag="ARROW_LEFT" size="MD" />
        </BackRouteButton>
      </Header>
      <Container maxWidth={500}>
        <Content>
          <ScoreWrapper>
            <Text tag={FontTypes.BODY_1} bold>
              얼리페이 만족스럽게 사용 중이신가요?
            </Text>
            <EmotionWrapper>
              <EmotionButton
                isSelected={currentEmotion === "unsatisfying"}
                onClick={() => selectEmotion("unsatisfying")}
                imageTag={
                  currentEmotion === "unsatisfying"
                    ? ImageTypes.UNSATISFYING
                    : ImageTypes.UNFILL_UNSATISFYING
                }
                imageAlt={
                  currentEmotion === "unsatisfying"
                    ? "UNSATISFYING"
                    : "UNFILL_UNSATISFYING"
                }
                textColor={
                  currentEmotion === "unsatisfying"
                    ? "CONTENT_PRIMARY"
                    : "CONTENT_SECONDARY"
                }
                text="불만족"
              />
              <EmotionButton
                isSelected={currentEmotion === "soso"}
                onClick={() => selectEmotion("soso")}
                imageTag={
                  currentEmotion === "soso"
                    ? ImageTypes.SOSO
                    : ImageTypes.UNFILL_SOSO
                }
                imageAlt={currentEmotion === "soso" ? "SOSO" : "UNFILL_SOSO"}
                textColor={
                  currentEmotion === "soso"
                    ? "CONTENT_PRIMARY"
                    : "CONTENT_SECONDARY"
                }
                text="보통"
              />
              <EmotionButton
                isSelected={currentEmotion === "satisfying"}
                onClick={() => selectEmotion("satisfying")}
                imageTag={
                  currentEmotion === "satisfying"
                    ? ImageTypes.SATISFYING
                    : ImageTypes.UNFILL_SATISFYING
                }
                imageAlt={
                  currentEmotion === "satisfying"
                    ? "SATISFYING"
                    : "UNFILL_SATISFYING"
                }
                textColor={
                  currentEmotion === "satisfying"
                    ? "CONTENT_PRIMARY"
                    : "CONTENT_SECONDARY"
                }
                text="만족"
              />
            </EmotionWrapper>
          </ScoreWrapper>

          <CommentWrapper>
            <Text tag={FontTypes.BODY_1} bold>
              어떤 부분이 불만족스러우셨나요?
              <Text
                as="span"
                tag={FontTypes.BODY_1}
                color="CONTENT_DISABLED"
                styles={{ paddingLeft: "2px" }}
              >
                (선택)
              </Text>
            </Text>
            <TextAreaWrapper>
              <TextArea
                placeholder="의견을 작성해 주시면 꼼꼼히 읽고 더 나은 서비스로 보답할게요."
                maxLength={500}
                onChange={onChangeComment}
              />
              <Text
                tag={FontTypes.BODY_3}
                color="CONTENT_DISABLED"
                styles={{
                  textAlign: "right",
                  paddingRight: "4px",
                  paddingTop: "3px",
                }}
              >
                {userComment.length}/500
              </Text>
            </TextAreaWrapper>
          </CommentWrapper>
        </Content>
        <Button
          tag={ButtonTypes.PRIMARY}
          isDisabled={mutation.isPending}
          onClick={submitFeedback}
        >
          의견 보내기
        </Button>
      </Container>
    </>
  );
};

export default Feedback;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fefefe;
  overflow: scroll;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 20px;
`;

const ScoreWrapper = styled.div`
  width: 100%;
  height: auto;
  background: #fefefe;
`;

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Header = styled.div`
  display: flex;
  margin-left: 6px;
  padding: 2px;
`;

const BackRouteButton = styled.div`
  padding: 8px;
  cursor: pointer;
`;

const EmotionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-top: 12px;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const TextArea = styled.textarea`
  resize: none;
  border: 1.4px solid #c6c6c6;
  border-radius: 12px;
  padding: 12px 16px;
  height: 144px;
  font-size: 16px;
`;
