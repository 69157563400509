import React from "react";
import styled from "styled-components";
import Text from "@components/atoms/Text";
import { ColorsTypes } from "@/@types/ThemesType";
import { PlatformSalesTypes } from "@earlypay/shared/typings";
import HistoryAmountItem from "@components/templates/settlement/SettlementAmountList/HistoryAmountList/item.card";
import Skeleton from "react-loading-skeleton";
import EmptyAmountBox from "@components/organisms/EmptyAmountBox";

export interface HistoryAmountListProps {
  list: PlatformSalesTypes[];
  isLoading: boolean;
  totalCount: number;
}

export const HistoryAmountList: React.FC<HistoryAmountListProps> = ({
  list,
  isLoading,
  totalCount,
}) => {
  if (isLoading) {
    return (
      <Wrapper>
        <Skeleton width={100} height={22} />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => (
          <InnerWrapper key={index}>
            <HistoryAmountItem isLoading={isLoading} />
          </InnerWrapper>
        ))}
      </Wrapper>
    );
  }

  if (!list?.length) {
    return <EmptyAmountBox label={"매출 내역이 없습니다."} />;
  }

  return (
    <Wrapper>
      <Text color={ColorsTypes.CONTENT_SECONDARY}>{`총 ${totalCount}건`}</Text>

      {list.map((items, index) => {
        const isLast = index !== list.length - 1;

        return (
          <InnerWrapper key={index}>
            <HistoryAmountItem items={items} isLoading={isLoading} />
            {isLast && <Divider />}
          </InnerWrapper>
        );
      })}
    </Wrapper>
  );
};

export default HistoryAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #f4f4f4;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
