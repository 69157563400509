/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

import type { DateRangeParams } from "@/@types/commonType";
import type {
  EarlypayInterface,
  DepositReduxInterface,
} from "@/@types/depositType";
import {
  formatPlatformSaleData,
  formatExtraPaymentsData,
} from "@/utils/formatEarlypay";
import {
  calculateDeposit,
  calculateTargetSale,
  calculateCalendarEarlypay,
} from "@/utils/calculateEarlypay";
import { getAdsExtra } from "@apis/endpoints/ads";
import {
  getEarlypaySummary,
  getExtraPaymentSummary,
  getOverDeposit,
  getSalesSummary,
  getUnderDeposit,
} from "@apis/endpoints";

export const getTodayEarlypayThunk = createAsyncThunk(
  "deposit/getTodayEarlypayThunk",
  async (req: DateRangeParams): Promise<EarlypayInterface> => {
    const { startDate, endDate, storeId, page } = req;
    try {
      const earlypay = await getEarlypaySummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
        page: page,
      });

      const monthExtraEarlypay = await getExtraPaymentSummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
      });

      const formatExtraData = formatExtraPaymentsData(
        startDate,
        endDate,
        monthExtraEarlypay,
      );

      const totalEarlypay = {
        ...earlypay,
        extraEarlypay: formatExtraData,
      };

      return totalEarlypay;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getMonthEarlypayThunk = createAsyncThunk(
  "deposit/getMonthEarlypayThunk",
  async (req: DateRangeParams): Promise<EarlypayInterface> => {
    const { startDate, endDate, storeId, page } = req;
    try {
      const earlypay = await getEarlypaySummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
        page: page,
      });

      const monthExtraEarlypay = await getExtraPaymentSummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
      });

      const formatSaleData = formatPlatformSaleData(
        startDate,
        endDate,
        earlypay.sales,
      );

      const formatExtraData = formatExtraPaymentsData(
        startDate,
        endDate,
        monthExtraEarlypay,
      );

      const formatMonthEarlypay = {
        sales: formatSaleData,
        earlypay: earlypay.earlypay,
        extraEarlypay: formatExtraData,
      };

      return formatMonthEarlypay;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getDetailEarlypayThunk = createAsyncThunk(
  "deposit/getDetailEarlypayThunk",
  async (req: DateRangeParams): Promise<EarlypayInterface> => {
    const { startDate, endDate, storeId, page } = req;
    try {
      const earlypay = await getEarlypaySummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
        page: page,
      });

      const subStartDate = subDays(new Date(startDate), 1)
        .toISOString()
        .split("T")[0];

      const subEndDate = subDays(new Date(endDate), 1)
        .toISOString()
        .split("T")[0];

      const yesterdaySale = await getSalesSummary({
        start_date: subStartDate,
        end_date: subEndDate,
        store_id: storeId,
      });

      const extraEarlypay = await getExtraPaymentSummary({
        start_date: startDate,
        end_date: endDate,
        store_id: storeId,
      });

      const formatYesterSaleData = formatPlatformSaleData(
        subStartDate,
        subEndDate,
        yesterdaySale,
      );

      const formatSaleData = formatPlatformSaleData(
        startDate,
        endDate,
        earlypay.sales,
      );

      const formatExtraData = formatExtraPaymentsData(
        startDate,
        endDate,
        extraEarlypay,
      );

      const formatMonthEarlypay = {
        sales: formatSaleData,
        earlypay: earlypay.earlypay,
        extraEarlypay: formatExtraData,
        yesterdaySale: formatYesterSaleData,
      };

      return formatMonthEarlypay;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getAdsThunk = createAsyncThunk(
  "deposit/getAdsThunk",
  async (req: { store_id: number; start_date: string; end_date: string }) => {
    try {
      const res = await getAdsExtra({
        start_date: req.start_date,
        end_date: req.end_date,
        store_id: req.store_id,
      });
      return res.data;
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const getUnderOverThunk = createAsyncThunk(
  "deposit/getUnderOverThunk",
  async (req: { gte: string; lte: string; storeId: number }) => {
    try {
      const under: any = await getUnderDeposit({ ...req });
      const over: any = await getOverDeposit({ ...req });

      return { under, over };
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const depositSlice = createSlice({
  name: "deposit",
  initialState: {
    todayEarlypay: {
      todayAggregationTargetAmount: 0,
      todayEarlypayAmount: 0,
    },
    monthEarlypay: {
      monthSales: [{ date: "", price: 0 }],
      monthDeposit: [{ date: "", price: 0 }],
      monthTotalSale: 0,
      monthTotalPlatformFee: 0,
      monthTotalInterestFee: 0,
      monthTotalEarlypayFee: 0,
    },
    detailEarlypay: {
      detailSale: {
        card: {
          totalSales: 0,
          totalSalesCount: 0,
          sales: 0,
          salesCount: 0,
          cancelSales: 0,
          cancelSalesCount: 0,
        },
        baemin: {
          totalSales: 0,
          totalSalesCount: 0,
          sales: 0,
          salesCount: 0,
          meetCard: 0,
          meetCardCount: 0,
          meetCash: 0,
          meetCashCount: 0,
        },
        yogiyo: {
          totalSales: 0,
          totalSalesCount: 0,
          sales: 0,
          salesCount: 0,
          meetCard: 0,
          meetCardCount: 0,
          meetCash: 0,
          meetCashCount: 0,
        },
      },
      detailDeposit: {
        card: {
          fee: 0,
          feeCount: 0,
          deposit: 0,
          depositCount: 0,
        },
        baemin: {
          fee: 0,
          feeCount: 0,
          deposit: 0,
          depositCount: 0,
          ceoDcCount: 0,
          ceoDc: 0,
          deliveryAgentFee: 0,
          deliveryAgentFeeCount: 0,
        },
        yogiyo: {
          fee: 0,
          feeCount: 0,
          deposit: 0,
          depositCount: 0,
          ceoDcCount: 0,
          ceoDc: 0,
          deliveryAgentFee: 0,
          deliveryAgentFeeCount: 0,
        },
        earlypayServiceFee: {
          fee: 0,
          interest: 0,
          underDeposit: 0,
        },
        extraPayments: {
          extraEarlypay: 0,
          extraOverdeposit: 0,
          replaceOverdeposit: 0,
          etc: 0,
        },
        overDeposits: {
          overDeposit: 0,
          paidOverDeposit: 0,
          repayUnderdeposit: 0,
        },
        baeminMeetSale: {
          meetCard: 0,
          meetCardCount: 0,
          meetCash: 0,
          meetCashCount: 0,
        },
        yogiyoMeetSale: {
          meetCard: 0,
          meetCardCount: 0,
          meetCash: 0,
          meetCashCount: 0,
        },
        totalAggregationTargetAmount: 0,
        totalEarlypayAmount: 0,
      },
    },
    extraAds: {
      baemin: [],
      yogiyo: [],
    },
    under: [
      {
        underDepositDate: "",
        cardCompany: "",
        underDepositAmount: 0,
        isFullyRepaid: "",
        repayments: [
          {
            amount: 0,
            created: "",
          },
        ],
      },
    ],
    over: [{ date: "", cardCompany: "", amount: 0 }],
  } as DepositReduxInterface,
  reducers: {},
  extraReducers: {
    [getTodayEarlypayThunk.fulfilled.type]: (
      state,
      { payload }: { payload: EarlypayInterface },
    ) => {
      /**
       * 선정산 계산 전 상태라면 모든 객체가 비어있어 null 값을 리턴합니다.
       * 선정산 계산 후에는 모든 객체의 최소 0의 값이 들어간 객체가 생성됩니다.
       */
      if (!Object.values(payload.earlypay.baemin)[0]) {
        state.todayEarlypay.todayAggregationTargetAmount = null;
        state.todayEarlypay.todayEarlypayAmount = null;
        return;
      }

      const {
        totalAggregationTargetAmount,
        totalEarlypayAmount,
        overDeposits,
        extraPayments,
      } = calculateDeposit(payload);

      state.todayEarlypay.todayAggregationTargetAmount =
        totalAggregationTargetAmount;
      state.todayEarlypay.todayEarlypayAmount =
        totalEarlypayAmount +
        overDeposits.paidOverDeposit +
        extraPayments.extraOverdeposit;
    },

    [getMonthEarlypayThunk.fulfilled.type]: (
      state,
      { payload }: { payload: EarlypayInterface },
    ) => {
      const {
        totalAggregationTargetAmount,
        earlypayServiceFee,
        card,
        baemin,
        yogiyo,
        coupang,
      } = calculateDeposit(payload);

      const { monthDepositList, monthSaleList } =
        calculateCalendarEarlypay(payload);

      state.monthEarlypay.monthDeposit = [...monthDepositList];
      state.monthEarlypay.monthSales = [...monthSaleList];
      state.monthEarlypay.monthTotalSale = totalAggregationTargetAmount;
      state.monthEarlypay.monthTotalPlatformFee =
        card.fee +
        baemin.fee +
        baemin.ceoDc +
        baemin.deliveryAgentFee +
        yogiyo.fee +
        yogiyo.ceoDc +
        yogiyo.deliveryAgentFee +
        coupang.fee +
        coupang.ceoDc +
        coupang.deliveryAgentFee;
      state.monthEarlypay.monthTotalInterestFee = earlypayServiceFee.interest;
      state.monthEarlypay.monthTotalEarlypayFee = earlypayServiceFee.fee;
    },

    [getDetailEarlypayThunk.fulfilled.type]: (
      state,
      { payload }: { payload: EarlypayInterface },
    ) => {
      const deposit = calculateDeposit(payload);
      const targetSale = calculateTargetSale(payload);

      state.detailEarlypay.detailDeposit = { ...deposit };
      state.detailEarlypay.detailSale = { ...targetSale };
    },

    [getAdsThunk.fulfilled.type]: (state, { payload }) => {
      state.extraAds.baemin = payload?.baemin ?? [];
      state.extraAds.yogiyo = payload?.yogiyo ?? [];
    },
    [getUnderOverThunk.fulfilled.type]: (state, { payload }) => {
      const over = payload.over.data.results;
      const under = payload.under.data.results;

      state.over = over;
      state.under = under;
    },
  },
});

export default depositSlice.reducer;
