export const EARLYPAY_SERVICE_GUIDE = "https://bit.ly/3M0PyWk";
export const KN_BANK_APP_ANDROID =
  "https://play.google.com/store/apps/details?id=com.knb.psb";

export const KN_BANK_APP_IOS =
  "https://apps.apple.com/kr/app/bnk%EA%B2%BD%EB%82%A8%EC%9D%80%ED%96%89-%EB%AA%A8%EB%B0%94%EC%9D%BC%EB%B1%85%ED%82%B9/id678852685";

export const TERMS_PRIVACY_POLICY =
  "https://app.catchsecu.com/document/P/702da9c1780f2dc";

export const TERMS_SERVICE_AGREEMENT = "https://earlypay.kr/terms/EP02";
