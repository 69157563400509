import React, { useEffect, useState } from "react";

import { SalesTypeCodeTypes, trackPageViewMixpanel } from "@earlypay/shared";
import styled from "styled-components";

import {
  ActionButton,
  HStack,
  Image,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { ImageTypes } from "@components/atoms/Image/type";
import RadioButton from "@components/atoms/RadioButton";
import { Header, Meta } from "@components/layouts";

import useNavigateParams from "@hooks/useNavigateParams";

import { useFetchApplication, useUpdateStore } from "@apis/hooks";

import { salesTypeList } from "./dataSetArray";

interface ServiceTypeListProps {
  label: string;
  value: SalesTypeCodeTypes;
  image: ImageTypes;
}

export const SalesType = () => {
  const { data: application, isLoading } = useFetchApplication();
  const { navigateWithParams } = useNavigateParams();
  const storeMutation = useUpdateStore(application.store.id);
  const [value, setValue] = useState(application.serviceType);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (!value) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [value]);

  const handleUpdateSalesConnect = (value: string) => {
    storeMutation.mutate({ serviceType: value });
  };

  const handleClickCard = (items: ServiceTypeListProps) => {
    setValue(items.value);
  };

  useEffect(() => {
    if (storeMutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/auth" });
    }
  }, [storeMutation.isSuccess]);

  trackPageViewMixpanel("매출 유형 등록");

  return (
    <>
      <Meta title={`얼리페이 | 서비스 신청`} />
      <Header previous={"/service-apply/intro"}></Header>

      <PageContainer spacing={16}>
        <VStack spacing={4}>
          <Text typo={"subtitle-1"} bold>
            {`사장님! 어떤 매출을 미리\n받고 싶으세요?`}
          </Text>
          <Text color="content-secondary">서비스 이용료는 모두 동일해요.</Text>
        </VStack>

        <VStack spacing={6}>
          {salesTypeList.map((items: ServiceTypeListProps, index: number) => (
            <ServiceTypeCard
              key={index}
              isSelected={value === items.value}
              onClick={() => handleClickCard(items)}
            >
              <HStack align={"center"} spacing={3}>
                <RadioButton
                  name={items.label}
                  value={items.value}
                  isSelected={value === items.value}
                />
                <Text bold>{items.label}</Text>
              </HStack>
              <Image src={items.image} width={76} height={76} />
            </ServiceTypeCard>
          ))}
        </VStack>
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"완료"}
        onClickPrimaryButton={() => handleUpdateSalesConnect(value)}
        disabledPrimaryButton={isDisabled}
        loading={storeMutation.isPending || isLoading}
        primaryButtonProperty={{
          description: "매출 유형 선택 완료 버튼",
        }}
      />
    </>
  );
};

export default SalesType;

const ServiceTypeCard = styled.button`
  width: 100%;
  height: 92px;
  border-radius: 16px;
  padding: 8px 24px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "#E7EFFF" : "#FFFFFF"};
  border: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "2px solid #0E5CFF" : "1px solid #c6c6c6"};
  box-shadow: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? "0 2px 8px 0 rgba(0, 0, 0, 0.24)" : "none"};
  transition: background-color ease-in-out 0.2s;
`;
