import React from "react";
import styled from "styled-components";
import { useFetchApplication } from "@apis/hooks";

interface MainLayoutType {
  _home?: boolean;
  children?: React.ReactNode;
  _id?: string;
  _padding?: boolean;
  _color?: string;
}

const MainOldLayout = ({
  _home,
  children,
  _id,
  _padding = false,
  _color = "#fff",
}: MainLayoutType) => {
  // 승인된 상태일 경우에만 바텀 내비게이션을 보여줍니다
  const { data: application } = useFetchApplication();

  return (
    <>
      <MainStyle
        id={_id}
        _home={_home}
        _color={_color}
        _padding={_padding}
        _porgress={application.progress}
      >
        {children}
      </MainStyle>
    </>
  );
};

export default MainOldLayout;

const MainStyle = styled.div`
  max-height: ${(props: { [key: string]: string }) =>
    props._home ? "calc(100%)" : "calc(100% - 52px)"};
  height: 100%;
  box-sizing: border-box;
  padding: ${(props: { [key: string]: string }) =>
    props._padding ? "0 20px" : "0px"};
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow: auto;
  background: ${(props: { [key: string]: string }) => props._color};
  padding-bottom: 58px;
`;
