import React from "react";
import styled from "styled-components";

import { FlexBox } from "@components/commons/index";
import theme from "@styles/theme";

const Day = styled.div`
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props: { [key: string]: string }) => props._color};
`;

const CalendarDays = () => {
  const days = ["일", "월", "화", "수", "목", "금", "토"];

  const dayColor = (day: string): string => {
    if (day === "일") {
      return theme.color.red_00;
    } else if (day === "토") {
      return theme.color.blue_00;
    } else {
      return "#555";
    }
  };

  return (
    <FlexBox _row>
      {days.map((day, idx) => (
        <Day key={idx} _color={dayColor(day)}>
          {day}
        </Day>
      ))}
    </FlexBox>
  );
};

export default CalendarDays;
