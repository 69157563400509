import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./ErrorScreen.module.scss";
import { ErrorScreenProps } from "./ErrorScreen.types";
import "@ui/styles/index.scss";
import { BoxButton, Icon, Text, VStack } from "@ui/components/atoms";
import { errorScreenData } from "@ui/components/templates/ErrorScreen/errorScreen.maps";
import { TriangleExclamationMarkSolidIcon } from "@earlybird/icons";

/**
 * `ErrorScreen` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ErrorScreen
 * />
 * ```
 */
export const ErrorScreen = forwardRef<HTMLElement, ErrorScreenProps>(
  function ErrorScreen(
    {
      className,
      as,
      type = "404",
      title,
      message,
      handleGoToPrevious,
      ...rest
    }: ErrorScreenProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const errorScreenObject =
      type === "custom"
        ? { title, message }
        : errorScreenData.filter((item) => item.type === type)[0];

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.ErrorScreen,
          "earlybird-error-screen",
          className,
        )}
      >
        <VStack spacing={6} align={"center"}>
          <Icon
            icon={TriangleExclamationMarkSolidIcon}
            color={"content-warning"}
            size={"3xl"}
          />
          <VStack spacing={4} align={"center"} width={335}>
            <Text typo={"subtitle-1"} center bold>
              {errorScreenObject.title}
            </Text>
            <Text color={"content-secondary"} center>
              {errorScreenObject.message}
            </Text>
          </VStack>
        </VStack>

        <BoxButton
          state={"fit"}
          hierarchy={"quaternary"}
          size={"lg"}
          onClick={handleGoToPrevious}
        >
          이전 화면으로
        </BoxButton>
      </BaseComponent>
    );
  },
);

export default ErrorScreen;
