import { useQuery } from "@tanstack/react-query";
import { documentQueryKeys } from "./queryKeys";
import {
  getBusinessLicense,
  getBusinessLicenseOcr,
} from "@apis/endpoints/applications";

export const useFetchBusinessLicenseOcr = (applicationId: number) => {
  return useQuery({
    queryKey: documentQueryKeys.fetchBusinessLicense(applicationId).queryKey,
    queryFn: () =>
      getBusinessLicenseOcr(applicationId, { applicationId: applicationId }),
    enabled: !!applicationId,
    placeholderData: {
      imageBusinessRegistration: "",
      businessRegistrationNumber: "",
      address: "",
      detailAddress: null,
      openDate: "",
      owner: { name: "", birthdate: "" },
      coFounders: null,
    },
    retry: 0,
  });
};

export const useFetchBusinessLicense = (applicationId: number) => {
  return useQuery({
    queryKey: documentQueryKeys.fetchBusinessLicense(applicationId).queryKey,
    queryFn: () =>
      getBusinessLicense(applicationId, { applicationId: applicationId }),
    enabled: !!applicationId,
    placeholderData: {
      imageBusinessRegistration: "",
      businessRegistrationNumber: "",
      address: "",
      detailAddress: null,
      openDate: "",
      owner: { name: "", birthdate: "" },
      coFounders: null,
    },
    retry: 0,
  });
};
