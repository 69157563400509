import styled from "styled-components";
import themes from "@styles/themes";

export const TrialBottomWrapper = styled.div`
  width: 100%;
  height: 68px;
  background-color: ${themes.colors.BG_PRIMARY};

  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const TrialBottomButtonContainer = styled.div`
  position: fixed;
  bottom: 12px;
  right: 16px;
  left: 16px;
  z-index: 9999;
`;
