import { termsCodeTypes } from "@earlypay/shared/typings";
import { changeHtmlToPdf, mergePdfs } from "@earlypay/shared/utils";

export const generateTermsPdfFile = async (terms: termsCodeTypes[]) => {
  try {
    const pdfBlobs: Blob[] = [];

    for (let i = 0; i < terms.length; i++) {
      const code = terms[i];
      const pdfBlob = await changeHtmlToPdf(
        `terms-page-${code}`,
        `terms-${code}.pdf`,
      );
      if (pdfBlob.size > 0) {
        // Ensure the Blob is not empty
        pdfBlobs.push(pdfBlob);
      } else {
        console.warn(`PDF for ${code} is empty.`);
      }
    }

    if (pdfBlobs.length === 0) {
      console.error("No PDFs generated. Check the PDF generation process.");
      return;
    }

    const mergedPdfBlob = await mergePdfs(pdfBlobs);

    // const pdfUrl = URL.createObjectURL(mergedPdfBlob);
    // window.open(pdfUrl);
    return mergedPdfBlob;
  } catch (error) {
    console.error("Error generating or merging PDFs:", error);
  }
};
