import React, { useEffect, useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import theme from "@styles/theme";

// helmet
import { Meta } from "@components/commons/index";
// apis
// component(base)
import { Header } from "@components/modules/common/index";

// hooks
import { useFetchCardConnect } from "@apis/hooks";

// component(styled)
import { ConnectBadge, IndexGrid } from "./index.styled";

const CardConnect = () => {
  const { data: cardConnectList, isLoading } = useFetchCardConnect();

  // TODO: Enum 으로 관리 필요
  const deliveryType = ["DE", "AL"];
  const cardType = ["CA", "AL"];
  const allType = ["CA", "DE", "AL"];

  // TODO: Enum 으로 관리 필요
  // 배열 순서 조정용 배열
  const platforms = [
    {
      card_company: "국민",
      name: "국민카드",
    },
    {
      card_company: "신한",
      name: "신한카드",
    },
    {
      card_company: "비씨",
      name: "비씨카드",
    },
    {
      card_company: "롯데",
      name: "롯데카드",
    },
    {
      card_company: "현대",
      name: "현대카드",
    },
    {
      card_company: "삼성",
      name: "삼성카드",
    },
    {
      card_company: "농협",
      name: "농협카드",
    },
    {
      card_company: "하나",
      name: "하나카드",
    },
    {
      card_company: "우리",
      name: "우리카드",
    },
    {
      card_company: "배달의민족",
      name: "배달의민족",
    },
    {
      card_company: "요기요",
      name: "요기요",
    },
    {
      card_company: "쿠팡이츠",
      name: "쿠팡이츠",
    },
  ];

  // 연동상태를 알려줍니다
  const connectText = (connect: boolean, disable: boolean) => {
    if (disable) {
      return { active: "비활성", color: "#aaa" };
    }
    if (connect) {
      return { active: "연동", color: theme.color.blue_00 };
    } else {
      return { active: "미연동", color: "#E82639" };
    }
  };
  // ---------------------------------- 카드커넥츠 불러오기 ---------------------------------
  type cardConnectType = {
    card_company: string;
    is_disabled: boolean;
    is_connected: boolean;
    first_connected_at: string;
    serviceType: string[];
  };
  type cardConnectArrType = cardConnectType[];

  const classifyPlatform = (platformName: string) => {
    if (platformName === "기타") return allType;
    if (
      platformName === "배달의민족" ||
      platformName === "요기요" ||
      platformName === "쿠팡이츠"
    ) {
      return deliveryType;
    } else {
      return cardType;
    }
  };

  const [cardConnect, setCardConnect] = useState<cardConnectType[]>([]);
  const getCardConnect = async () => {
    try {
      const cardConnectArr: cardConnectArrType = [];

      platforms.map(platform => {
        cardConnectList.map((x: cardConnectType) => {
          if (x.card_company === platform.card_company) {
            cardConnectArr.push({
              ...x,
              serviceType: classifyPlatform(x.card_company),
            });
          }
        });
      });

      return setCardConnect([...cardConnectArr]);
    } catch (err) {
      throw new Error(err);
    }
  };

  // TODO: 삭제 예정
  // 마운트시 카드커넥츠 정보를 불러옵니다
  useEffect(() => {
    !isLoading && getCardConnect();
  }, [isLoading]);

  trackPageViewMixpanel("플랫폼 연동 현황");

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <>
      <Meta title="얼리페이 | 마이페이지" />
      <Header _white _backHistory="/my">
        플랫폼 연동 현황
      </Header>
      <MainOldLayout _padding>
        <IndexGrid style={{ marginTop: "10px" }}>
          <h3>플랫폼</h3>
          <h3 style={{ textAlign: "center", width: "auto" }}>연동현황</h3>
        </IndexGrid>
        {platforms.map((platform, idx) => {
          const connection = cardConnect.find(
            card => card.card_company === platform.card_company,
          );

          if (platform.card_company === "우리" && !connection?.is_connected) {
            return;
          }

          return (
            <IndexGrid _disable={connection?.is_disabled} key={idx}>
              <p
                style={{
                  textDecoration: connection?.is_disabled && "line-through",
                  color: connection?.is_disabled && "#777",
                }}
              >
                {platform?.name}
                {connection?.first_connected_at ? (
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    ({connection?.first_connected_at?.split("T")[0] ?? "연동중"}
                    )
                  </span>
                ) : null}
              </p>
              <div style={{ textAlign: "center" }}>
                <ConnectBadge
                  _active={
                    connectText(
                      connection?.is_connected,
                      connection?.is_disabled,
                    ).color
                  }
                >
                  {
                    connectText(
                      connection?.is_connected,
                      connection?.is_disabled,
                    ).active
                  }
                </ConnectBadge>
              </div>
            </IndexGrid>
          );
        })}
      </MainOldLayout>
    </>
  );
};

export default CardConnect;
