import React from "react";
import { ButtonQty } from "@components/molecules/BottomSheet/type";
import { DepositorCode, DepositorNames } from "@earlypay/shared/typings";
import BillingInfoMessage from "@components/templates/settlement/BillingInfoMessage";
import { BillingInfoMessageType } from "@components/templates/settlement/BillingInfoMessage/types";
import { DefaultAmountList } from "../../SettlementAmountList";
import BottomSheet from "@components/molecules/BottomSheet";
import { useSettlement } from "@hooks/useSettlement";
import { changeCodeToPath } from "@pages/settlement/utils";
import EmptyAmountBox from "@components/organisms/EmptyAmountBox";
import { useFetchUnderDepositsSummary } from "@apis/hooks";
import useNavigateWithParams from "@hooks/useNavigateParams";

interface UnderDepositBottomSheetProps {
  depositor: DepositorCode;
}

/** 입금사 별 차감금 내역을 BottomSheet 에서 보여줍니다. */
export const UnderDepositBottomSheet: React.FC<
  UnderDepositBottomSheetProps
> = ({ depositor }) => {
  const { navigateWithParams } = useNavigateWithParams();
  const { handleCloseBillingBottomSheet } = useSettlement();
  const { startDateParams, endDateParams } = useSettlement();
  const depositorName = DepositorNames[depositor];

  const { data, isLoading } = useFetchUnderDepositsSummary({
    startDate: startDateParams,
    endDate: endDateParams,
    depositor: depositor,
  });

  /* [자세히 보기] 버튼을 눌렀을 때, 차감금 상세내역으로 이동합니다. */
  const handleGoToDetail = () => {
    navigateWithParams({
      pathname: "/settlement/billing/detail",
      query: {
        startDate: startDateParams,
        endDate: endDateParams,
        depositor: changeCodeToPath(depositor),
        type: "under-deposit",
      },
    });
  };

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={`${depositorName} 차감금`}
      buttonQty={ButtonQty.DUO_HORIZONTAL}
      secondaryButtonText={"닫기"}
      primaryButtonText={"자세히 보기"}
      onClickPrimaryButton={handleGoToDetail}
    >
      <BillingInfoMessage
        type={
          DepositorCode.CARD === depositor
            ? BillingInfoMessageType.CARD_UNDER_DEPOSIT
            : BillingInfoMessageType.DELIVERY_UNDER_DEPOSIT
        }
      />
      {!isLoading && data && data[depositor]?.length ? (
        <DefaultAmountList list={data[depositor]} isLoading={isLoading} />
      ) : (
        <EmptyAmountBox label={"차감금 내역이 없습니다."} />
      )}
    </BottomSheet>
  );
};

export default UnderDepositBottomSheet;
