import mixpanel, { Dict } from "mixpanel-browser";
import { ApplicationTypes } from "../../typings";

// Mixpanel 초기화
export const initMixpanel = () => {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: import.meta.env.VITE_ENV !== "production",
    track_pageview: "full-url",
    persistence: "localStorage",
  });
};

// 사용자의 ID 및 프로필 속성을 저장합니다.
export const setMixpanelUser = (application: ApplicationTypes) => {
  if (!application?.id) {
    return;
  }

  mixpanel.identify(String(application.user.id));
  mixpanel.people.set({
    $userId: application.user.id,
    $storeId: application.store.id,
    $email: application.user.email,
    $name: application.user.name,
    $phone: application.user.phone,
    $gender: application.user.gender,
    $birthdate: application.user.birthdate,
    $isCertified: application.user.isCertified,
    $created: application.user.created,
    $businessNumber: application.businessRegistrationNumber,
    $store: application.store.title,
    $serviceType: application.serviceType,
    $approvedAt: application.approvedAt,
    $stage: application.stage,
    $status: application.status,
  });
};

export const trackPageViewMixpanel = (name: string) => {
  if (!mixpanel.people) {
    return;
  }

  // Send a default page view event
  mixpanel.track_pageview({ "Page Name": name });
};

export const trackEventMixpanel = (name: string, property?: Dict) => {
  if (!mixpanel.people) {
    return;
  }

  mixpanel.track(name, property);
};

export const resetMixpanel = () => {
  mixpanel.reset();
};
