import React, { useEffect, useState } from "react";

import {
  sentryAddAttachments,
  sentryHook,
  trackPageViewMixpanel,
} from "@earlypay/shared/configs";
import { useRecoilValue } from "recoil";

import {
  ActionButton,
  Image,
  ImageUploader,
  PageContainer,
  Text,
  VStack,
} from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import useNavigateWithParams from "@hooks/useNavigateParams";

import { useMaskingBusinessLicense } from "@apis/hooks/document/mutations";

import { createFormDataWithImage } from "@utils/forms";

import { applicationState } from "@/recoil/application/atom";

export const UploadLicenseImage: React.FC = () => {
  const { navigateWithParams } = useNavigateWithParams();
  const application = useRecoilValue(applicationState);
  const mutation = useMaskingBusinessLicense(application.id);
  const [visibleImageUploader, setVisibleImageUploader] = useState(false);
  const [licenseImage, setLicenseImage] = useState(null);

  /** 사업자 등록증 이미지를 서버로 전송합니다. */
  const handleUploadImage = async (image: File) => {
    setLicenseImage(image);
    const formData = createFormDataWithImage(
      image,
      "image_business_registration",
    );
    mutation.mutate(formData);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/license-verify/check" });
    }

    if (mutation.isError) {
      licenseImage && sentryAddAttachments(licenseImage, "business-license");
    }
  }, [mutation.isSuccess, mutation.isError]);

  trackPageViewMixpanel("사업자등록증 인증 | 이미지 첨부");

  return (
    <>
      <Meta title={`얼리페이 | 서비스 신청`} />
      <Header
        previous={
          application.user.isCertified
            ? "/service-apply/sales-type"
            : "/service-apply/auth"
        }
      ></Header>
      <PageContainer spacing={30}>
        <Text typo={"subtitle-1"} bold>
          {`${application.user.name} 사장님의\n사업자등록증을 첨부해주세요`}
        </Text>

        <VStack center>
          <Image
            src={"/service/business-registration.png"}
            width={240}
            heigh={240}
          />
        </VStack>

        <ImageUploader
          visible={visibleImageUploader}
          onClose={() => setVisibleImageUploader(false)}
          guide={"business-license"}
          handleSaveImage={handleUploadImage}
        />

        <ActionButton
          primaryButtonLabel={"첨부하기"}
          onClickPrimaryButton={() => setVisibleImageUploader(true)}
          loading={mutation.isPending}
          primaryButtonProperty={{
            description: "사업자등록증 첨부 버튼",
          }}
        />
      </PageContainer>
    </>
  );
};

export default UploadLicenseImage;
