import { useMutation } from "@tanstack/react-query";
import { commonsQueryKeys } from "./queryKeys";
import {
  postFeedbackStatus,
  FeedbackStatusParams,
} from "@apis/endpoints/commons";
import { useNavigate } from "react-router-dom";
import { successToast } from "@components/atoms/Toast";
import { sentryHook } from "@earlypay/shared/configs";
import { useConfirmModal } from "@earlybird/ui";
import { modalCode, ServerError } from "@earlypay/shared/typings";

export const usePostFeedbackStatus = (redirectUri: string) => {
  const navigate = useNavigate();
  const { openConfirmModal } = useConfirmModal();

  return useMutation({
    mutationKey: commonsQueryKeys.postFeedbackStatus().queryKey,
    mutationFn: async (payload: FeedbackStatusParams) =>
      postFeedbackStatus(payload),
    onError: (error: ServerError) => {
      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
      sentryHook({
        title: "얼리페이 VOC 의견 전달에 실패했습니다.",
        error: error,
      });

      return error;
    },
    onSuccess: () => {
      successToast("소중한 의견이 잘 전달되었어요.");
      return navigate(redirectUri);
    },
    retry: 0,
  });
};
