import React, { useState } from "react";
import Text from "@components/atoms/Text";
import { FontTypes } from "@/@types/ThemesType";
import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import DatePickerModal from "@components/organisms/DatePickerModal";
import styled from "styled-components";
import { getMonthDifference, getSelectedDate } from "./utils";
import Interaction from "@components/atoms/Interaction";
import { useFetchApplication } from "@apis/hooks";
import useSettlement from "@hooks/useSettlement";

export const SettlementDatePicker: React.FC = () => {
  const { startDateParams, endDateParams } = useSettlement();
  // TODO: startDate, endDate 를 하나의 배열 안에 넣어서 관리
  const [startDate, setStartDate] = useState(startDateParams);
  const [endDate, setEndDate] = useState(endDateParams);
  const [isDateModal, setIsDateModal] = useState<boolean>(false);

  const { data: application } = useFetchApplication();

  const minShowMonths = application.approvedAt
    ? getMonthDifference(application.approvedAt)
    : 3;
  const activeStartDate = new Date(application.approvedAt);
  activeStartDate.setDate(activeStartDate.getDate() - 1);

  const handleClickDate = () => {
    setIsDateModal(true);
  };

  const handleCloseModal = () => {
    setIsDateModal(false);
  };

  return (
    <>
      <Interaction type={"DARK"} rounded={"SM"}>
        <SelectedDateButton onClick={handleClickDate}>
          <Text tag={FontTypes.BODY_2} bold>
            {getSelectedDate(startDateParams, endDateParams)}
          </Text>
          <Icon tag={IconTags.CHEVRON_DOWN_SOLID} size={IconSizes.MD} />
        </SelectedDateButton>
      </Interaction>
      {isDateModal && (
        <DatePickerModal
          onClose={handleCloseModal}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          minShowMonths={minShowMonths}
          activeStartDate={activeStartDate}
        />
      )}
    </>
  );
};

export default SettlementDatePicker;

const SelectedDateButton = styled.button`
  width: fit-content;
  display: flex;
  background: transparent;
`;
