/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { errorToast } from "@components/atoms/Toast";
import { sentryHook } from "@earlypay/shared/configs";
import { useUpdateUserAuth } from "@apis/hooks/user/mutations";

export const useAuth = (redirectUri: string) => {
  const [loading, setLoading] = useState(false);
  const mutation = useUpdateUserAuth(redirectUri);

  const handleCertificate = () => {
    setLoading(true);

    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init(import.meta.env.VITE_DANAL_ID);

    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`,
    };

    /* 4. 본인인증 창 호출하기 */
    IMP.certification(data, callbackIMP);
  };

  /* 3. 콜백 함수 정의하기 */
  const callbackIMP = (response: any) => {
    const { success, error_msg, error_code, imp_uid } = response;

    if (success) {
      handleAuthorization(imp_uid);
    } else {
      error_code !== "F0000" &&
        errorToast("본인인증에 실패했습니다. 다시 시도해주세요.");
      setLoading(false);
      const error = new Error();
      error_code !== "F0000" &&
        sentryHook({
          error: error,
          message: error_msg,
          title: "[다날] 본인인증에 실패했습니다.",
        });
    }
  };

  const handleAuthorization = async (authUid: string) => {
    mutation.mutate({ authUid: authUid });
    setLoading(false);
  };

  return {
    loading,
    handleCertificate,
  };
};
