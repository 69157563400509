import { FileFormatError, FileSizeError } from "@utils/customError";
import { isMobile } from "react-device-detect";
import { useRecoilState } from "recoil";
import { imageUploadModalState } from "@/recoil/serviceApply/atoms";
import { ImageUploadMethodCode } from "@earlypay/shared/typings";

const useUploadImage = () => {
  const [imageUploadModal, setImageUploadModal] = useRecoilState(
    imageUploadModalState,
  );
  const isOpenImageMethodSelector = imageUploadModal.isOpenImageMethodSelector;
  const isOpenImageUploadForm = imageUploadModal.isOpenImageUploadForm;

  const handleOpenImageMethodSelector = () => {
    if (isMobile) {
      return setImageUploadModal({
        ...imageUploadModal,
        isOpenImageMethodSelector: true,
      });
    }

    setImageUploadModal({
      ...imageUploadModal,
      isOpenImageUploadForm: true,
      method: ImageUploadMethodCode.GALLERY,
    });
  };

  const handleCloseImageMethodSelector = () => {
    setImageUploadModal({
      ...imageUploadModal,
      isOpenImageMethodSelector: false,
    });
  };

  const handleOpenImageUploadForm = () => {
    setImageUploadModal({ ...imageUploadModal, isOpenImageUploadForm: true });
  };

  const handleCloseImageUploadForm = () => {
    setImageUploadModal({ ...imageUploadModal, isOpenImageUploadForm: false });
  };

  // 이미지 파일 사이즈를 체크합니다
  const handleCheckFileSize = (image: Blob | File, size: number) => {
    const uploadsize = image.size;

    const limitSize = 1024 ** 2 * size;
    if (uploadsize > limitSize) {
      throw new FileSizeError(`File size exceeds the ${size}MB limit.`);
    }
  };

  // 이미지 파일 확장자를 체크합니다
  const handleCheckFileExtension = (image: Blob | File) => {
    const uploadExtension = image.type;

    const extensionList = ["image/jpg", "image/jpeg", "image/png"];
    if (!extensionList.includes(uploadExtension)) {
      throw new FileFormatError("File format invalid the jpg, jpeg, png.");
    }
  };

  return {
    isOpenImageUploadForm,
    isOpenImageMethodSelector,
    handleOpenImageMethodSelector,
    handleCloseImageMethodSelector,
    handleOpenImageUploadForm,
    handleCloseImageUploadForm,
    handleCheckFileExtension,
    handleCheckFileSize,
  };
};

export default useUploadImage;
