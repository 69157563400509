/** 신청서 작성 단계에서 이미지 업로드 방식 선택 상태 */
export const ImageUploadMethodCode = {
  CAMERA: "CAMERA",
  GALLERY: "GALLERY",
};
export type ImageUploadMethodCodeTypes =
  (typeof ImageUploadMethodCode)[keyof typeof ImageUploadMethodCode];

/** 신청서 작성 단계 */
export const ServiceApplyCode = {
  // ------- 페이 -------
  INTRO: "INTRO",
  SALES_TYPE: "SALES_TYPE",
  USER_AUTH: "USER_AUTH",
  BUSINESS_AUTH: "BUSINESS_AUTH",
  AGREE_SERVICE: "AGREE_SERVICE",
  // ------- 대부 -------
  GATEWAY: "GATEWAY",
  AGREE_CREDIT: "AGREE_CREDIT",
  SCREENING: "SCREENING",
  DOCUMENT_GUIDE: "DOCUMENT_GUIDE",
  ID_CARD: "ID_CARD",
  DEPOSIT_ACCOUNT: "DEPOSIT_ACCOUNT",
  WITHDRAWAL_ACCOUNT: "WITHDRAWAL_ACCOUNT",
  DELIVERY_ACCOUNT: "DELIVERY_ACCOUNT",
  DELIVERY_AGENT: "DELIVERY_AGENT",
  VAN_IMAGE: "VAN_IMAGE",
  AGREE_LOAN: "AGREE_LOAN",
  COMPLETE: "COMPLETE",
};
export type ServiceApplyCodeTypes =
  (typeof ServiceApplyCode)[keyof typeof ServiceApplyCode];

export const ServiceApplyNames = {
  // ------- 페이 -------
  [ServiceApplyCode.INTRO]: "인트로",
  [ServiceApplyCode.SALES_TYPE]: "매출 유형 선택",
  [ServiceApplyCode.USER_AUTH]: "본인 인증",
  [ServiceApplyCode.BUSINESS_AUTH]: "사업자번호 등록",
  [ServiceApplyCode.AGREE_SERVICE]: "얼리페이 서비스 약정서 동의",
  // ------- 대부 -------
  [ServiceApplyCode.GATEWAY]: "얼리페이 대부 진입",
  [ServiceApplyCode.AGREE_CREDIT]: "신용 정보 조회 약관 동의",
  [ServiceApplyCode.AGREE_CREDIT]: "신용 정보 조회 약관 동의",
  [ServiceApplyCode.SCREENING]: "신용 정보 검사",
  [ServiceApplyCode.DOCUMENT_GUIDE]: "등록 필요한 서류 안내",
  [ServiceApplyCode.ID_CARD]: "신분증 등록",
  [ServiceApplyCode.DEPOSIT_ACCOUNT]: "회수 계좌 등록",
  [ServiceApplyCode.WITHDRAWAL_ACCOUNT]: "입금 계좌 등록",
  [ServiceApplyCode.DELIVERY_ACCOUNT]: "배달앱 계정 등록",
  [ServiceApplyCode.DELIVERY_AGENT]: "배달 대행사 등록",
  [ServiceApplyCode.VAN_IMAGE]: "카드 단말기 등록",
  [ServiceApplyCode.AGREE_LOAN]: "대부/하나캐피탈 약관 동의",
  [ServiceApplyCode.COMPLETE]: "신청서 제출 완료",
};
export type ServiceApplyNamesTypes =
  (typeof ServiceApplyNames)[keyof typeof ServiceApplyNames];

export const ServiceApplyIndex = {
  // ------- 페이 -------
  [ServiceApplyCode.INTRO]: 0,
  [ServiceApplyCode.SALES_TYPE]: 1,
  [ServiceApplyCode.USER_AUTH]: 2,
  [ServiceApplyCode.BUSINESS_AUTH]: 3,
  [ServiceApplyCode.AGREE_SERVICE]: 4,
  // ------- 대부 -------
  [ServiceApplyCode.GATEWAY]: 5,
  [ServiceApplyCode.AGREE_CREDIT]: 6,
  [ServiceApplyCode.SCREENING]: 7,
  [ServiceApplyCode.DOCUMENT_GUIDE]: 8,
  [ServiceApplyCode.ID_CARD]: 9,
  [ServiceApplyCode.DEPOSIT_ACCOUNT]: 10,
  [ServiceApplyCode.WITHDRAWAL_ACCOUNT]: 11,
  [ServiceApplyCode.DELIVERY_ACCOUNT]: 12,
  [ServiceApplyCode.DELIVERY_AGENT]: 13,
  [ServiceApplyCode.VAN_IMAGE]: 14,
  [ServiceApplyCode.AGREE_LOAN]: 15,
  [ServiceApplyCode.COMPLETE]: 16,
};
export type ServiceApplyIndexTypes =
  (typeof ServiceApplyIndex)[keyof typeof ServiceApplyIndex];

export const ServiceApplyPath = {
  // ------- 페이 -------
  [ServiceApplyCode.INTRO]: "intro",
  [ServiceApplyCode.SALES_TYPE]: "sales-type",
  [ServiceApplyCode.USER_AUTH]: "auth",
  [ServiceApplyCode.BUSINESS_AUTH]: "license-verify",
  [ServiceApplyCode.AGREE_SERVICE]: "service-agreement",
  // ------- 대부 -------
  [ServiceApplyCode.GATEWAY]: "gateway",
  [ServiceApplyCode.AGREE_CREDIT]: "credit-agreement",
  [ServiceApplyCode.SCREENING]: "screening",
  [ServiceApplyCode.DOCUMENT_GUIDE]: "document-guide",
  [ServiceApplyCode.ID_CARD]: "identification",
  [ServiceApplyCode.DEPOSIT_ACCOUNT]: "deposit-account",
  [ServiceApplyCode.WITHDRAWAL_ACCOUNT]: "withdrawal-account",
  [ServiceApplyCode.DELIVERY_ACCOUNT]: "delivery-account",
  [ServiceApplyCode.DELIVERY_AGENT]: "delivery-agency",
  [ServiceApplyCode.VAN_IMAGE]: "card-terminal",
  [ServiceApplyCode.AGREE_LOAN]: "terms-agreement",
  [ServiceApplyCode.COMPLETE]: "submit",
};
export type ServiceApplyPathTypes =
  (typeof ServiceApplyPath)[keyof typeof ServiceApplyPath];

/** 사업자등록증 검출 및 인증 단계 */
export const LicenseVerifyProgressCode = {
  SAVE_LICENSE_IMAGE: "SAVE_LICENSE_IMAGE",
  CHECK_LICENSE_INFO: "CHECK_LICENSE_INFO",
  VERIFY_LICENSE_INFO: "VERIFY_LICENSE_INFO",
};
export type LicenseVerifyProgressCodeTypes =
  (typeof LicenseVerifyProgressCode)[keyof typeof LicenseVerifyProgressCode];

/** 서비스 매출 유형 */
export const SalesTypeCode = {
  AL: "AL",
  CA: "CA",
  DE: "DE",
};
export type SalesTypeCodeTypes =
  (typeof SalesTypeCode)[keyof typeof SalesTypeCode];

/** 배달 대행사 유형 */
export const DeliveryAgencyCode = {
  MANNA: "MANNA",
  SAENGGAK: "BAROGO",
  BAROGO: "BAROGO",
  BUREUNG: "BUREUNG",
  MOA: "MOA",
  SPIDER: "SPIDER",
  ETC: "ETC",
};
export type DeliveryAgencyCodeTypes =
  (typeof DeliveryAgencyCode)[keyof typeof DeliveryAgencyCode];

export const DeliveryAgencyNames = {
  [DeliveryAgencyCode.MANNA]: "만나플러스",
  [DeliveryAgencyCode.SAENGGAK]: "생각대로",
  [DeliveryAgencyCode.BAROGO]: "바로고",
  [DeliveryAgencyCode.BUREUNG]: "부릉",
  [DeliveryAgencyCode.MOA]: "모아콜",
  [DeliveryAgencyCode.SPIDER]: "스파이더",
  [DeliveryAgencyCode.ETC]: "기타",
};
export type DeliveryAgencyNamesTypes =
  (typeof DeliveryAgencyNames)[keyof typeof DeliveryAgencyNames];
