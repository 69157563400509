/**
 *
 * @param {number} time 초/ms
 * @returns 프로미스 반환합니다.
 */
const delayTime = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export { delayTime };
