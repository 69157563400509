/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
// component(base)
import { Text, Accordion } from "@components/commons/index";
import { MyFooter } from "../../myPage";
// component(styled)
import {
  FaqStyle,
  FaqTitle,
  FaqButton,
  FaqTextBox,
  FaqNavButton,
  ContentTitle,
} from "./index.styled";
// apis
import { IMG_URL } from "@/@types/commonType";
import { useFetchFaqList } from "@apis/hooks/faq/queries";

interface FaqType {
  _landing: boolean;
  _tab?: boolean;
}

const Faq = ({ _landing, _tab = false }: FaqType) => {
  const { data, isLoading } = useFetchFaqList();
  const [navButton, setNavButton] = useState([
    { title: "FAQ", active: true },
    { title: "고객센터", active: false },
  ]);

  // 탭 버튼
  const selectTab = (idx: number) => {
    navButton.map(x => (x.active = false));
    navButton[idx].active = true;
    setNavButton([...navButton]);
  };

  const [service, setService] = useState([]);
  const [join, setJoin] = useState([]);
  const [earlypay, setEarlypay] = useState([]);

  // FAQ를 가져옵니다.
  const getFaq = async () => {
    const serviceList = data.results.filter((x: any) => {
      if (x.title.includes("[서비스]")) return x;
    });
    const earlypayList = data.results.filter((x: any) => {
      if (x.title.includes("[정산]")) return x;
    });
    const joinList = data.results.filter((x: any) => {
      if (x.title.includes("[회원가입]")) return x;
    });
    setService(serviceList);
    setJoin(joinList);
    setEarlypay(earlypayList);
  };

  useEffect(() => {
    !isLoading && getFaq();
  }, [isLoading]);

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <FaqStyle _landing={_landing}>
      <FaqTitle _landing={_landing}>
        <img src={IMG_URL.icon.star} alt="자주묻는질문" />
        <Text _bold>자주 묻는 질문</Text>
      </FaqTitle>
      <div style={{ margin: "0 -20px" }}>
        {_tab && (
          <div>
            {navButton.map((x, idx) => {
              return (
                <FaqNavButton
                  _on={x.active}
                  key={idx}
                  onClick={() => selectTab(idx)}
                >
                  {x?.title}
                </FaqNavButton>
              );
            })}
          </div>
        )}
        {navButton[0].active && (
          <div>
            <ContentTitle>정산</ContentTitle>
            <FaqBox _faq={earlypay} />
            <ContentTitle>서비스</ContentTitle>
            <FaqBox _faq={service} />
            <ContentTitle>회원가입</ContentTitle>
            <FaqBox _faq={join} />
          </div>
        )}
        {navButton[1].active && <MyFooter />}
      </div>
    </FaqStyle>
  );
};

export default Faq;

// ---------------------------------- FAQ 컴포넌트 ---------------------------------
const FaqBox = ({ _faq }: any) => {
  return (
    <div>
      {_faq?.map((x: any, idx: number) => (
        <Accordion
          _style={{
            border: "none",
            margin: "0",
            borderRadius: "0",
          }}
          key={idx}
        >
          <FaqButton>
            {x?.title.replace(/\[서비스\]|\[정산\]|\[회원가입\]/g, "")}
          </FaqButton>
          <FaqTextBox
            dangerouslySetInnerHTML={{ __html: x?.content }}
            style={{
              paddingBottom: "20px",
              fontFamily: "Noto Sans KR!important",
            }}
          ></FaqTextBox>
        </Accordion>
      ))}
    </div>
  );
};
