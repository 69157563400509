import { instance } from "@apis/instance";

/**
 * FAQ 목록을 가져오는 api 함수입니다.
 */
export const getFaqList = async () => {
  try {
    const { data } = await instance({
      method: "get",
      url: `/v1/faq/`,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
