import React from "react";
import styled from "styled-components";
import {
  AdditionalDepositAmountDetailTypes,
  AdditionalDepositCodeTypes,
  AdditionalDepositDetailNames,
} from "@earlypay/shared/typings";
import { getFormattedDateWithDots } from "@utils/date";
import Text from "@components/atoms/Text";
import { ColorsTypes, FontTypes } from "@/@types/ThemesType";
import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import { changeNumberToMoney } from "@utils/types";
import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import { Link } from "react-router-dom";
import EmptyAmountBox from "@components/organisms/EmptyAmountBox";
import Skeleton from "react-loading-skeleton";
import Interaction from "@components/atoms/Interaction";
import useSettlement from "@hooks/useSettlement";

interface AdditionalDepositAmountListProps {
  list: AdditionalDepositAmountDetailTypes[];
  isLoading: boolean;
}

export const AdditionalDepositAmountList: React.FC<
  AdditionalDepositAmountListProps
> = ({ list, isLoading }) => {
  if (isLoading) {
    return (
      <Wrapper>
        {[1, 2].map(index => (
          <InnerWrapper key={index}>
            <Skeleton width={90} height={18} />
            {[1, 2, 3].map(number => (
              <ItemsWrapper key={number}>
                <LeftWrapper>
                  <Skeleton width={44} height={44} />
                  <Skeleton width={140} height={18} />
                </LeftWrapper>
                <Skeleton width={80} height={18} />
              </ItemsWrapper>
            ))}
          </InnerWrapper>
        ))}
      </Wrapper>
    );
  }

  if (!list?.length) {
    return <EmptyAmountBox label={"추가입금 상세내역이 없습니다."} />;
  }

  return (
    <Wrapper>
      {list.map((items, index) => (
        <InnerWrapper key={index}>
          <Text bold color={ColorsTypes.CONTENT_SECONDARY}>
            {getFormattedDateWithDots(items.date)}
          </Text>

          {items.items.map((item, index) => {
            const type =
              item.type === "ADDITIONAL_EARLYPAY"
                ? "additional-earlypay"
                : "additional-deposit";
            const { currentUrl, startDateParams, endDateParams } =
              useSettlement();

            return (
              <Interaction key={index} width={"100%"}>
                <ItemsWrapper
                  to={`/settlement/billing/detail/elist?startDate=${startDateParams}&endDate=${endDateParams}&type=${type}&target=${items.date}`}
                  state={{ from: currentUrl }}
                >
                  <LeftWrapper>
                    <CustomImage
                      tag={ImageTypes.SETTLEMENT_MONEYBAG_GREEN}
                      width={44}
                      height={44}
                    />
                    <Text
                      bold
                      tag={FontTypes.BODY_2}
                      color={ColorsTypes.CONTENT_SECONDARY}
                    >
                      {
                        AdditionalDepositDetailNames[
                          item.type as AdditionalDepositCodeTypes
                        ]
                      }
                    </Text>
                  </LeftWrapper>

                  <RightWrapper>
                    <Text
                      bold
                      tag={FontTypes.BODY_2}
                      color={ColorsTypes.CONTENT_SECONDARY}
                    >
                      {changeNumberToMoney(item.totalAmount)}
                    </Text>
                    <Icon
                      tag={IconTags.CHEVRON_RIGHT}
                      size={IconSizes.SM}
                      color={ColorsTypes.CONTENT_TERTIARY}
                    />
                  </RightWrapper>
                </ItemsWrapper>
              </Interaction>
            );
          })}
        </InnerWrapper>
      ))}
    </Wrapper>
  );
};

export default AdditionalDepositAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemsWrapper = styled(Link)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  text-decoration: none;
  cursor: pointer;
`;

const CustomImage = styled(Image)`
  width: 44px;
  height: 44px;
  border-radius: 16px;
`;

const LeftWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  align-items: center;
  display: flex;
`;
