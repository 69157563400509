import React, { useEffect, useState } from "react";

import theme from "@styles/theme";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { format, lastDayOfMonth } from "date-fns";
import { Doughnut } from "react-chartjs-2";

import { FlexBox } from "@components/commons";
import {
  ChartCard,
  NoneCount,
} from "@components/modules/sales/CountChart/index.styled";
import { ChartInterface } from "@components/modules/sales/SalesTitle";

import { getSalesCount } from "@apis/endpoints";
import { useFetchApplication } from "@apis/hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const CountChart = ({ _currentMonth, _storeId }: ChartInterface) => {
  const { data: application } = useFetchApplication();

  const [chartData, setChartData] = useState({
    totalSaleAmount: 0,
    totalFeeAmount: 0,
    baeminSaleAmount: 0,
    yogiyoSaleAmount: 0,
    cardSaleAmount: 0,
    baeminMeetAmount: 0,
    yogiyoMeetAmount: 0,
    baeminFeeAmount: 0,
    yogiyoFeeAmount: 0,
    cardFeeAmount: 0,
  });

  useEffect(() => {
    if (!_storeId) return;
    (async function getCountChart() {
      const startDate = format(_currentMonth, "yyyy-MM-01");
      const endDate = format(lastDayOfMonth(_currentMonth), "yyyy-MM-dd");
      const { data } = await getSalesCount({
        start_date: startDate,
        end_date: endDate,
        store_id: _storeId,
      });
      setChartData({ ...data });
    })();
  }, [_currentMonth, _storeId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    responsive: false,
    maintainAspectRatio: false,
    borderWidth: 0,
    cutout: 50,
    layout: {
      padding: 5,
    },
    plugins: {
      tooltip: {
        intersect: false,
      },
      legend: {
        position: "right",
        display: true,
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "총 매출 데이터",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  });

  useEffect(() => {
    const label = [
      "카드매출",
      "카드수수료",
      "배달의민족",
      "배달의민족 수수료",
      "요기요",
      "요기요 수수료",
    ];

    const baemin = chartData.baeminSaleAmount + chartData.baeminMeetAmount;
    const yogiyo = chartData.yogiyoSaleAmount + chartData.yogiyoMeetAmount;

    const montData = {
      labels: [...label],
      datasets: [
        {
          label: "총 매출 데이터",
          data: [
            chartData.cardSaleAmount >= 0 ? chartData.cardSaleAmount : 0,
            chartData.cardFeeAmount,
            baemin,
            chartData.baeminFeeAmount,
            yogiyo,
            chartData.yogiyoFeeAmount,
          ],
          backgroundColor: [
            "#7baeed",
            "#003372",
            "#75d9e0",
            "#005960",
            "#f48092",
            "#6c0022",
          ],
        },
      ],
    };
    setData(montData);
    return;
  }, [chartData]);

  const { totalSaleAmount, totalFeeAmount } = chartData;
  const percent = ((totalFeeAmount / totalSaleAmount) * 100).toFixed(0);

  return (
    <ChartCard>
      <h3>{_currentMonth.getMonth() + 1}월 플랫폼별 매출 및 수수료</h3>
      {application.serviceType !== "CA" &&
        ((totalSaleAmount ?? 0) === 0 ? (
          <NoneCount>
            <p>집계된 매출이 없습니다.</p>
          </NoneCount>
        ) : (
          <div>
            <p>
              전체 매출{" "}
              <span style={{ color: theme.color.blue_00 }}>
                {totalSaleAmount?.toLocaleString()} 원
              </span>{" "}
              대비 수수료는{" "}
              <span style={{ color: theme.color.blue_00 }}>{percent}%</span>{" "}
              입니다.
            </p>

            <FlexBox _align>
              <Doughnut options={options} data={data} height={"180%"} />
            </FlexBox>
          </div>
        ))}
    </ChartCard>
  );
};

export default CountChart;
