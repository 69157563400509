import { useQuery } from "@tanstack/react-query";
import { faqQueryKeys } from "./queryKeys";
import { getFaqList } from "@apis/endpoints";

export const useFetchFaqList = () => {
  return useQuery({
    queryKey: faqQueryKeys.fetchFaqList().queryKey,
    queryFn: () => getFaqList(),
    retry: 0,
  });
};
