import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { GetPlatformSalesParams } from "@apis/endpoints/sales/types";
import { getPlatformSales } from "@apis/endpoints/sales";
import { getPlatformSalesSummary } from "@apis/endpoints";
import { salesQueryKeys } from "./queryKeys";

export const useFetchPlatformSales = (params: GetPlatformSalesParams) => {
  const {
    data: fetchingData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: salesQueryKeys.fetchPlatformSales(params).queryKey,
    queryFn: ({ pageParam = 1 }) => {
      return getPlatformSales(params, pageParam);
    },
    select: data => ({
      pages: [
        {
          result: data?.pages?.flatMap(page => page.data),
          count: data?.pages[0]?.count || 0,
        },
      ],
      pageParams: data?.pageParams || [],
    }),
    getNextPageParam: lastPage => {
      if (lastPage?.next) {
        const match = lastPage.next.match(/[?&]page=(\d+)/);
        return match ? Number(match[1]) : null;
      }
      // 마지막 페이지일 경우 페이지 업데이트를 진행하지 않습니다.
      return null;
    },
    retry: 0,
    initialPageParam: 1,
  });

  const data = fetchingData?.pages[0].result;
  const totalCount = fetchingData?.pages[0].count || 0;

  return { data, totalCount, isLoading, fetchNextPage, hasNextPage };
};

export const useFetchPlatformSalesSummary = (
  params: GetPlatformSalesParams,
) => {
  return useQuery({
    queryKey: salesQueryKeys.fetchPlatformSalesSummary(params).queryKey,
    queryFn: () => getPlatformSalesSummary(params),
    retry: 0,
  });
};
