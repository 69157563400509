/**
 * earlypay/summary apis 의 경우 dashboard에 apis 추적을 위해
 * page를 구분할수 있는 string값을 넣습니다.
 * "home" | "calendar" | "deposit"
 */
export interface DateRangeParams {
  startDate: string;
  endDate: string;
  storeId: number;
  page: "home" | "calendar" | "detail";
}

export const SALE_KEY = {
  card: "card",
  yogiyo: "yogiyo",
  baemin: "baemin",
  coupang: "coupang",
} as const;

export type SaleKeyType = keyof typeof SALE_KEY;

export const DEPOSIT_KEY = {
  ...SALE_KEY,
  earlypayServiceFee: "earlypayServiceFee",
} as const;

export type DepositKeyType = keyof typeof DEPOSIT_KEY;

export const EXTRA_KEY = {
  extraPayments: "extraPayments",
  overDeposits: "overDeposits",
} as const;

export type ExtraKeyType = keyof typeof EXTRA_KEY;

const baseUrl = import.meta.env.VITE_S3_URI;
export const IMG_URL: { [key: string]: { [key: string]: string } } = {
  signup: {
    submit: `${baseUrl}/image/serviceApply/submit.png`,
    knBank1: `${baseUrl}/image/serviceApply/knbank1.png`,
    knBank2: `${baseUrl}/image/serviceApply/knbank2.png`,
    newGuid: `${baseUrl}/image/serviceApply/newEarlypay.png`,
    knBank: `${baseUrl}/image/serviceApply/bnk.png`,
  },
  home: {
    inviteBanner1: `${baseUrl}/image/home/invite-1.png`,
    inviteBanner2: `${baseUrl}/image/home/invite-2.png`,
    homeEventBanner: `${baseUrl}/image/home/eventPopup.png`,
  },
  deposit: {
    guideModal_1: `${baseUrl}/image/deposit/guide-1.png`,
    guideModal_2: `${baseUrl}/image/deposit/guide-2.png`,
    guideModal_3: `${baseUrl}/image/deposit/guide-3.png`,
    guideModal_4: `${baseUrl}/image/deposit/guide-4.png`,
    guideModal_5: `${baseUrl}/image/deposit/guide-5.png`,
  },
  event: {
    glodEvent: `${baseUrl}/image/my/event/gold-event.png`,
    inviteEvent: `${baseUrl}/image/my/event/event-invite.png`,
    inviteEvent2: `${baseUrl}/image/my/event/event-invite-promotion.svg`,
    landingEvent: `${baseUrl}/image/landing/eventPopup.png`,
  },
  icon: {
    appIcon: `${baseUrl}/image/landing/appIcon.png`,
    bigToggle: `${baseUrl}/image/icon/sale_arrow_button.png`,
    channelTok: `${baseUrl}/image/landing/channelTok.svg`,
    toggle: `${baseUrl}/image/icon/bottom-arrow-sales.svg`,
    logo: `${baseUrl}/image/icon/newSymbol.png`,
    contact: `${baseUrl}/image/home/contact_support.svg`,
    PWA: `${baseUrl}/image/seo/icon-128x128.png`,
    star: `${baseUrl}/image/landing/star.svg`,
    kakao: `${baseUrl}/image/icon/kakao-button.svg`,
    kakao2: `${baseUrl}/image/my/kakao.svg`,
    phone: `${baseUrl}/image/my/phone.svg`,
    mouse: `${baseUrl}/image/serviceApply/mouse.png`,
  },
  etc: {
    kimearly: `${baseUrl}/image/landing/mobile.png`,
    invite: `${baseUrl}/image/my/friendInvite.svg`,
  },
} as const;
