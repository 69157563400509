import styled from "styled-components";
import Container from "@components/layouts/Container";
import Text from "@components/atoms/Text";
import Stack from "@components/atoms/Stack";

export const StyledContainer = styled(Container)`
  padding-top: ${(props: { [key: string]: boolean }) =>
    props.hasHeader ? "20px" : "72px"};
  overflow: hidden;
  max-height: ${(props: { [key: string]: boolean }) =>
    props.hasHeader ? "calc(100% - 52px)" : "100%"};
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: auto;
  border-width: 0;
`;

export const Title = styled(Text)``;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px 20px;
`;

export const ContentWrapper = styled(Stack)`
  padding: 0 20px;
`;
