import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { BillingNames, DepositorCode } from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import Text from "@components/atoms/Text";
import { Header, Meta } from "@components/layouts";
import { BillingAmountList } from "@components/templates/settlement/SettlementAmountList";

import { useSettlement } from "@hooks/useSettlement";

import { useFetchEarlypays, useFetchEarlypaysEtc } from "@apis/hooks";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

import { changeDepositorToCode, changeDepositorToTitle } from "../utils";
import DepositorsBottomSheets from "./DepositorsBottomSheets";
import EtcBottomSheets from "./EtcBottomSheets";

export const Billing: React.FC = () => {
  const {
    getFormattedDate,
    depositorParams,
    startDateParams,
    endDateParams,
    filterParams,
    prevPath,
  } = useSettlement();
  const title = changeDepositorToTitle(depositorParams);
  const depositor = changeDepositorToCode(depositorParams);

  /** 각 입금사 별 선정산 내역 API 를 호출합니다. */
  const { data: earlypaysData, isLoading: isEarlypaysLoading } =
    useFetchEarlypays({
      startDate: startDateParams,
      endDate: endDateParams,
      depositor: depositor,
    });
  /** 그 외 선정산 정산 내역 API 를 호출합니다. */
  const { data: earlypaysEtcData, isLoading: isEtcLoading } =
    useFetchEarlypaysEtc({
      startDate: startDateParams,
      endDate: endDateParams,
    });
  const isLoading = isEarlypaysLoading || isEtcLoading;
  /** 각 입금사 별 선정산 총 합계 */
  const totalAmount = !isLoading
    ? depositor === "ETC"
      ? earlypaysEtcData.totalAmount
      : earlypaysData.totalAmount
    : 0;

  const getBackUrl = () => {
    if (!prevPath) {
      return `/settlement?startDate=${startDateParams}&endDate=${endDateParams}`;
    }

    return prevPath;
  };

  trackPageViewMixpanel(
    filterParams ? `${BillingNames[filterParams]}` : `${title} 선정산`,
  );

  return (
    <Container>
      <Meta title={"얼리페이 | 정산내역"} />
      <Header previous={getBackUrl()}>{`${title} 선정산`}</Header>
      <Wrapper>
        {/** 날짜 및 선정산 총 합계를 표시합니다. */}
        <TitleWrapper>
          <Text
            tag={FontTypes.BODY_2}
            color={ColorsTypes.CONTENT_TERTIARY}
            bold
          >
            {getFormattedDate()}
          </Text>
          {isLoading ? (
            <Skeleton width={120} height={28} />
          ) : (
            <Text tag={FontTypes.TITLE_3} bold>
              {changeNumberToMoney(totalAmount)}
            </Text>
          )}
        </TitleWrapper>

        {/** 각 입금사 별 선정산 내역을 보여줍니다. */}
        <InnerWrapper>
          {depositor === DepositorCode.ETC ? (
            <BillingAmountList
              list={earlypaysEtcData?.items}
              isLoading={isLoading}
            />
          ) : (
            <BillingAmountList
              list={earlypaysData?.items}
              isLoading={isLoading}
            />
          )}
        </InnerWrapper>

        {/** 각 입금사 별 선정산 세부 항목이 담긴 바텀 시트를 보여줍니다. */}
        {filterParams && depositor !== DepositorCode.ETC ? (
          <DepositorsBottomSheets />
        ) : (
          <EtcBottomSheets />
        )}
      </Wrapper>
    </Container>
  );
};

export default Billing;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: scroll;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;
