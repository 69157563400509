import React from "react";

import store, { persistor } from "@redux/store";
import themes from "@styles/themes";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import * as serviceWorkerRegistration from "@utils/serviceWorkerRegistration";

import App from "./App";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider
        theme={{
          ...themes,
          colors: themes.colors,
        }}
      >
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

serviceWorkerRegistration.register();
