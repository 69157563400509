/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import theme from "@styles/theme";

import { FlexBox, Text } from "@components/commons/index";
import {
  AvgChart,
  CountChart,
  TimeChart,
} from "@components/modules/sales/index";

import { useFetchApplication } from "@apis/hooks";

import { IMG_URL } from "@/@types/commonType";
import { useGetMonthEarlypay } from "@/hooks/useEarlypay";

import {
  AmountText,
  ArrowButtonBox,
  ScrollBox,
  TitleBox,
  TotalAmountBox,
} from "./index.styled";

interface SalesTitleType {
  _currentMonth: Date;
}

export interface ChartInterface {
  _currentMonth: Date;
  _storeId: number;
}

const SalesTitle = ({ _currentMonth }: SalesTitleType) => {
  // users info
  const { data: application } = useFetchApplication();
  const {
    monthSales,
    monthTotalEarlypayFee,
    monthTotalInterestFee,
    monthTotalPlatformFee,
  } = useGetMonthEarlypay();

  const serviteTypeText: { [key: string]: string } = {
    CA: "카드사 수수료",
    DE: "배달앱 수수료",
    AL: "배달앱 + 카드사 수수료",
  };

  const monthTotalSale = monthSales
    .map((sale: any) => {
      return sale.price;
    })
    .reduce((prev: any, cur: any) => {
      return prev + cur;
    }, 0);

  const fees = [
    {
      title: serviteTypeText[application.serviceType ?? "AL"],
      price: monthTotalPlatformFee.toLocaleString(),
    },
    {
      title: "얼리페이 선정산금융 수수료",
      price: monthTotalInterestFee.toLocaleString(),
    },
    {
      title: "얼리페이 선정산 이용료",
      price: monthTotalEarlypayFee.toLocaleString(),
    },
  ];

  const [toggle, setToggle] = useState(false);
  const toggleDetailPage = () => {
    if (toggle === true) {
      const scroll = document.getElementById("sale_scroll_box");
      scroll.scrollTo(0, 0);
    }
    setToggle(!toggle);
  };

  return (
    <TitleBox _toggle={toggle}>
      <ArrowButtonBox onClick={toggleDetailPage}>
        <img
          src={IMG_URL.icon.bigToggle}
          alt="화살표 버튼"
          style={{ transform: `rotate(${toggle ? 180 : 0}deg)` }}
        />
      </ArrowButtonBox>

      <ScrollBox id="sale_scroll_box" _toggle={toggle}>
        <FlexBox _row _justify>
          <Text _style={{ fontSize: "17px", marginLeft: "5px" }}>
            {_currentMonth.getMonth() + 1}월 총 집계 매출 금액{" "}
          </Text>
        </FlexBox>
        <Text
          _bold
          _center
          _style={{
            fontSize: "32px",
            marginBottom: "20px",
            marginTop: "4px",
          }}
        >
          {monthTotalSale.toLocaleString()}
          <span style={{ fontSize: "20px" }}> 원</span>
        </Text>
        <TotalFees _fees={fees} />
        <AvgChart
          _currentMonth={_currentMonth}
          _storeId={application.store.id}
        />
        {application.serviceType !== "CA" && (
          <CountChart
            _currentMonth={_currentMonth}
            _storeId={application.store.id}
          />
        )}
        <TimeChart
          _currentMonth={_currentMonth}
          _storeId={application.store.id}
        />
      </ScrollBox>
    </TitleBox>
  );
};

export default SalesTitle;

// ---------------------------------- 추가 컴포넌트 ---------------------------------
interface TotalFees {
  _fees: { title: string; price: string }[];
}

const TotalFees = ({ _fees }: TotalFees) => (
  <TotalAmountBox>
    {_fees.map((x, idx) => (
      <AmountText _row _align key={idx}>
        <Text _style={{ fontSize: "14px", color: "#3f3f3f" }}>{x.title}</Text>
        <Text _bold _style={{ fontSize: "17px", color: theme.color.blue_00 }}>
          {x.price}원
        </Text>
      </AmountText>
    ))}
  </TotalAmountBox>
);
