import { atom } from "recoil";
import { v1 } from "uuid";

export const daumPostCodeState = atom({
  key: `DaumPostCodeState/${v1()}`,
  default: {
    fullAddress: "",
    postCode: "",
  },
});
