import {
  CardConnect,
  Event,
  Info,
  InviteEvent,
  InviteRecent,
  MyFaq,
  MyIndex,
  Notice,
  Feedback,
} from "@pages/my";

export const myRouters = [
  {
    index: true,
    Component: MyIndex,
  },
  {
    path: "invite-event",
    Component: InviteEvent,
  },
  {
    path: "info",
    Component: Info,
  },
  {
    path: "invite",
    Component: InviteRecent,
  },
  {
    path: "event",
    Component: Event,
  },
  {
    path: "faq",
    Component: MyFaq,
  },
  {
    path: "notice",
    Component: Notice,
  },
  {
    path: "connect",
    Component: CardConnect,
  },
  {
    path: "feedback",
    Component: Feedback,
  },
];
