import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@redux/store";
import { addMonths, subMonths, format } from "date-fns";

import { getMonthEarlypayThunk } from "@redux/modules/deposit";

import { Span } from "@components/commons/index";
import CalendarDatePicker from "../CalendarDatePicker";
import {
  PrevButton,
  HeaderDate,
  DateText,
  IndexGrid,
  NextButton,
  CalendarHeaderBox,
  CalendarIndexIcon,
  CalendarIndexText,
} from "./index.styled";

import type { DateRangeParams } from "@/@types/commonType";
import { useFetchApplication } from "@apis/hooks";
import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";

interface CalendarHeaderType {
  _currentMonth: Date;
  _setCurrentMonth: (date: Date) => void;
  _setIsLoading: (state: boolean) => void;
  _isLoading: boolean;
  _firstDate: Date;
}

const CalendarHeader = ({
  _currentMonth = new Date(),
  _setCurrentMonth,
  _setIsLoading,
  _isLoading,
}: CalendarHeaderType) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data: application } = useFetchApplication();
  const [modalState, setModalState] = useState(false);

  const dateFormat = format(_currentMonth, "yyyy MM").split(" ");
  const year = Number(dateFormat[0]);
  const month = Number(dateFormat[1]);
  const todayDate = Number(format(new Date(), "yyyy MM").replace(" ", ""));
  const currentDate = Number(dateFormat.join().replace(",", ""));

  const dateChange = (num: number) => {
    const date = String(num).split("");
    if (date.length === 1) date.unshift("0");
    return date.join("");
  };

  const getSelectSalesSummary = async (
    selectYear: number,
    selectMonth: number,
  ) => {
    if (!application.store.id) return;
    const endOfDay = new Date(
      Number(selectYear),
      Number(selectMonth),
      0,
    ).getDate();
    const startDate = selectYear + "-" + dateChange(selectMonth) + "-" + "01";
    const endDate =
      selectYear + "-" + dateChange(selectMonth) + "-" + dateChange(endOfDay);

    const params: DateRangeParams = {
      startDate: startDate,
      endDate: endDate,
      storeId: application.store.id,
      page: "calendar",
    };

    try {
      await dispatch(getMonthEarlypayThunk(params)).unwrap();
    } catch (err) {
      throw new Error("캘린더 불러오기 실패");
    } finally {
      _setIsLoading(false);
    }
  };

  const selectMonth = async (select: string) => {
    let selectYear;
    let selectMonth;
    _setIsLoading(true);

    if (select === "next") {
      if (todayDate === currentDate) return;
      selectYear = addMonths(_currentMonth, 1).getFullYear();
      selectMonth = addMonths(_currentMonth, 1).getMonth() + 1;
      await getSelectSalesSummary(selectYear, selectMonth);
      _setCurrentMonth(addMonths(_currentMonth, 1));
    } else if (select === "prev") {
      selectYear = subMonths(_currentMonth, 1).getFullYear();
      selectMonth = subMonths(_currentMonth, 1).getMonth() + 1;
      await getSelectSalesSummary(selectYear, selectMonth);
      _setCurrentMonth(subMonths(_currentMonth, 1));
    }

    _setIsLoading(false);
  };

  const pickMonthSales = async (selectYear: number, selectMonth: number) => {
    _setIsLoading(true);
    await getSelectSalesSummary(selectYear, selectMonth);
    _setCurrentMonth(new Date(Number(selectYear), Number(selectMonth) - 1, 1));
    _setIsLoading(false);
  };

  return (
    <CalendarHeaderBox _row _justify _align>
      <PrevButton onClick={() => selectMonth("prev")} disabled={_isLoading}>
        <Icon tag={IconTags.CHEVRON_LEFT} size={"XS"} />
      </PrevButton>

      <HeaderDate onClick={() => setModalState(true)}>
        <DateText>
          {year}년 {month}월
        </DateText>
      </HeaderDate>

      <NextButton
        onClick={() => selectMonth("next")}
        disabled={_isLoading || todayDate === currentDate}
        _current={todayDate === currentDate}
      >
        <Icon tag={IconTags.CHEVRON_RIGHT} size={"XS"} />
      </NextButton>
      <IndexGrid _align _row>
        <CalendarIndexIcon _type="매출" />
        <CalendarIndexText _type="매출">매출</CalendarIndexText>
        <CalendarIndexIcon _type="정산" />
        <CalendarIndexText _type="정산">정산</CalendarIndexText>
      </IndexGrid>
      {modalState && (
        <CalendarDatePicker
          _todayDate={todayDate}
          _setModalState={setModalState}
          _month={_currentMonth.getMonth() + 1}
          _year={_currentMonth.getFullYear()}
          _pickMonthSales={pickMonthSales}
        />
      )}
    </CalendarHeaderBox>
  );
};

export default CalendarHeader;
