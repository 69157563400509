import React from "react";
import styled from "styled-components";

import { FlexBox, Text } from "@components/commons";

interface EventPopupPresenter {
  _closeBanner: (cookieName: string, value: string, days: number) => void;
  _navigate: (url: string) => void;
  _setPopup: (state: boolean) => void;
}

// TODO : 추석 이후 배너 변경 필요
const Presenter = ({
  // _navigate,
  _closeBanner,
  _setPopup,
}: EventPopupPresenter) => {
  return (
    <PopupBox>
      <div>
        <Popup>
          <img
            src={`${import.meta.env.VITE_S3_URI}/image/home/baeminHotfix.jpeg`}
            alt="카카오 버튼 아이콘"
            style={{ width: "100%", height: "100%" }}
          />
          {/* <FlexBox
            _style={{
              position: "absolute",
              bottom: "7%",
              left: "0",
              right: "0",
            }}
          >
            <Button
              _onClick={() => _navigate("/my/invite-event")}
              _style={{
                padding: "8px",
                width: "80%",
                margin: "0 auto",
                background: "#FB2471",
                borderRadius: "30px",
                boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Span _medium _style={{ color: "#fff" }}>
                쿠폰 받기 &gt;
              </Span>
            </Button>
          </FlexBox> */}
        </Popup>
        <FlexBox _row _style={{ marginTop: "5px" }}>
          <Text
            _onClick={() => {
              _closeBanner("thanksgiving", "true", 7);
              _setPopup(false);
            }}
            _center
            _style={{ color: "#fff", width: "50%", fontSize: "13px" }}
          >
            일주일간 보지않기
          </Text>
          <Text
            _onClick={() => {
              _closeBanner("thanksgiving", "true", 1);
              _setPopup(false);
            }}
            _center
            _style={{ color: "#fff", width: "50%", fontSize: "13px" }}
          >
            닫기
          </Text>
        </FlexBox>
      </div>
    </PopupBox>
  );
};

export default Presenter;

const PopupBox = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 40px;
`;

const Popup = styled.div`
  max-width: 500px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  /* background: #0075ff; */
`;
