import React from "react";

import { pdfWorkerPolyfill } from "@earlypay/shared";
import { routers } from "@routes/routers";
import "@styles/global.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

import Toast from "@components/atoms/Toast";

import { queryClient } from "@apis/hooks/queryClient";

function App() {
  pdfWorkerPolyfill();

  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <SkeletonTheme baseColor="#F4F4F4" highlightColor="#fafafa">
              <RouterProvider router={routers} />
              <Toast />
            </SkeletonTheme>
            <ReactQueryDevtools initialIsOpen={false} />
          </RecoilRoot>
        </QueryClientProvider>
      </ConfirmModalProvider>
    </ToastProvider>
  );
}

export default App;
