import React, { ForwardedRef, forwardRef, RefAttributes } from "react";
import classNames from "classnames";
import styles from "./Stack.module.scss";
import { HStackProps, StackProps, VStackProps } from "@ui/components/atoms";
import { changeSpacingToClassName } from "@ui/utils/className";
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import { getPixelValue } from "@ui/utils/types";

/**
 * `Stack` 은 자식 요소들을 한 방향으로 쌓아서 보여주기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <Stack
 *   direction="vertical"
 * >
 *   Hello, Earlypay!
 * </Stack>
 * ```
 */
export const Stack = forwardRef<HTMLElement>(function Stack(
  {
    className,
    children,
    as,
    display = "flex",
    direction = "vertical",
    justify = "start",
    align = "start",
    center,
    spacing = "none",
    width,
    height,
    padding,
    margin,
    overflow,
    reverse = false,
    wrap = false,
    style,
    ...rest
  }: StackProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";
  const p = getPixelValue(padding);
  const m = getPixelValue(margin);
  const w = getPixelValue(width);
  const h = getPixelValue(height);

  const defaultStyle = {
    width: w,
    height: h,
    margin: m,
    padding: p,
    overflow: overflow,
  };

  return (
    <BaseComponent
      ref={forwardedRef}
      className={classNames(
        styles.Stack,
        display && styles[`display-${display}`],
        direction && styles[`direction-${direction}`],
        justify && styles[`justify-${justify}`],
        align && styles[`align-${align}`],
        center && styles[`center`],
        padding && styles.padding,
        reverse && styles.reverse,
        wrap && styles.wrap,
        changeSpacingToClassName(spacing),
        "earlybird-stack",
        className,
      )}
      style={{ ...style, ...defaultStyle }}
      role={"listbox"}
      {...rest}
    >
      {children}
    </BaseComponent>
  );
});

export const HStack = forwardRef<
  HTMLElement,
  HStackProps & IntrinsicAttributes & RefAttributes<HTMLElement>
>(function HStack({ ...rest }: StackProps, forwardedRef) {
  return <Stack direction={"horizontal"} {...rest} ref={forwardedRef} />;
});

export const VStack = forwardRef<HTMLElement, VStackProps>(function VStack(
  { ...rest }: StackProps,
  forwardedRef,
) {
  return <Stack direction={"vertical"} {...rest} ref={forwardedRef} />;
});

export default Stack;
