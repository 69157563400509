// style
import styled from "styled-components";
import theme from "@styles/theme";

export const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: 0 -20px;
  padding: 10px 20px;
  background: ${(props: { [key: string]: string }) =>
    props._disable ? "#eee" : "#fff"};

  &:first-child {
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
  }

  h3 {
    font-size: 15px;
    font-weight: 400;
    color: ${theme.color.black_00};
    display: block;
    text-align: center;
    line-height: 1;

    &:first-child {
      width: 120px;
    }
  }

  p {
    font-size: 13px;
    color: #1f1f1f;
    line-height: 2;
  }
`;

export const ConnectBadge = styled.div`
  padding: 4px 0px;
  color: ${(props: { [key: string]: string }) => props._active};
  border-radius: 10px;
  width: 50px;
  margin: 0 auto;
  font-size: 13px;
`;
