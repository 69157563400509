export const modalCode = {
  // ------------------- error -------------------
  ERROR_UNKNOWN: "ERROR_UNKNOWImage",
  ERROR_MAX_RETRY_EXCEEDED: "ERROR_MAX_RETRY_EXCEEDED",
  ERROR_ONLINE_BUSINESS_LICENSE: "ERROR_ONLINE_BUSINESS_LICENSE",
  ERROR_RETRY_SERVICE_APPLY: "ERROR_RETRY_SERVICE_APPLY",
  ERROR_LOGOUT: "ERROR_LOGOUT",
  // ------------------- warning -------------------
  WARNING_UNDER_AGE_AUTH: "WARNING_UNDER_AGE_AUTH",
  WARNING_INVALID_OCR_BUSINESS_LICENSE: "WARNING_INVALID_OCR_BUSINESS_LICENSE",
  WARNING_INVALID_IMAGE_FILE: "WARNING_INVALID_IMAGE_FILE",
  WARNING_INVALID_USER_ACCOUNT: "WARNING_INVALID_USER_ACCOUNT",
  WARNING_INVALID_WITHDRAWAL_ACCOUNT: "WARNING_INVALID_WITHDRAWAL_ACCOUNT",
  WARNING_FAILED_WITHDRAWAL_ACCOUNT_ARS:
    "WARNING_FAILED_WITHDRAWAL_ACCOUNT_ARS",
  WARNING_INVALID_OCR: "WARNING_INVALID_OCR",
  WARNING_DUPLICATE_ACCOUNT_ARS: "WARNING_DUPLICATE_ACCOUNT_ARS",
  WARNING_FAILED_IDENTIFICATION: "WARNING_FAILED_IDENTIFICATION",
  WARNING_DIFFERENT_IDENTIFICATION: "WARNING_DIFFERENT_IDENTIFICATION",
  WARNING_INVALID_DOC_TYPE_BUSINESS_LICENSE:
    "WARNING_INVALID_DOC_TYPE_BUSINESS_LICENSE",
  WARNING_DIFFERENT_BUSINESS_LICENSE: "WARNING_DIFFERENT_BUSINESS_LICENSE",
  WARNING_ONLINE_BUSINESS_LICENSE: "WARNING_ONLINE_BUSINESS_LICENSE",
  WARNING_DUPLICATE_BUSINESS_LICENSE: "WARNING_DUPLICATE_BUSINESS_LICENSE",
  // ------------------- confirm -------------------
  CONFIRM_DELETE_BAEMIN_ACCOUNT: "CONFIRM_DELETE_BAEMIN_ACCOUNT",
  CONFIRM_DELETE_YOGIYO_ACCOUNT: "CONFIRM_DELETE_YOGIYO_ACCOUNT",
  CONFIRM_DELETE_COUPANG_ACCOUNT: "CONFIRM_DELETE_COUPANG_ACCOUNT",
  // ------------------- text -------------------
  TEXT_SKIP_AGENCY: "TEXT_SKIP_AGENCY",
  TEXT_SKIP_CARD_TERMINAL: "TEXT_SKIP_CARD_TERMINAL",
  INFO_INVALID_IMAGE_FILE: "INFO_INVALID_IMAGE_FILE",
};
export type modalCodeTypes = (typeof modalCode)[keyof typeof modalCode];
