import { errorToast } from "@components/atoms/Toast";
import React from "react";

/**
 * event 객체를 이미지 파일로 변환하는 함수
 */
export const changeEventToFile = (e: React.ChangeEvent<HTMLInputElement>) => {
  const files: FileList = e.target.files;
  if (files.length === 0) {
    errorToast("이미지 변환에 실패했습니다. 고객센터로 문의해주세요.");
    return;
  }

  return files[0];
};

/**
 * 이미지 파일을 FormData 객체에 추가하는 함수
 *
 * @param image - 추가할 이미지 파일
 * @param name - 추가할 이미지 파일 이름
 * @returns 이미지가 추가된 FormData 객체
 */
export const createFormDataWithImage = (
  image: File | Blob,
  name?: string,
): FormData => {
  const formData = new FormData();
  formData.append(name, image, `${name}.jpg`);

  return formData;
};

/**
 * base64를 Blob 객체로 변환하는 함수
 */
export const changeBase64ToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString ?? "image/jpeg" });
};

/**
 * 파일을 base64 문자열로 변환하는 함수
 * @returns Promise<string>
 */
export const changeFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to convert file to base64."));
      }
    };
    reader.onerror = error => reject(error);
  });
};

export const changeFileToUint8Array = (file: File): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      } else {
        reject(new Error("Failed to convert file to ArrayBuffer."));
      }
    };
    reader.onerror = error => reject(error);
  });
};
